import Base from './base'

export default class GeneralLedger extends Base {
  processGeneralLedger(id: string, data: object) {
    return this.apiClient.put(`general-ledger/${id}`, data)
  }

  listTypes() {
    return this.apiClient.get('general-ledger/types')
  }

  listTypeMapping(id: string, params: object) {
    return this.apiClient.get(`general-ledger/type-mapping/${id}`, params)
  }

  updateTypeMapping(id: string, data: object) {
    return this.apiClient.put(`general-ledger/type-mapping/${id}`, data)
  }

  listDilutionByCustomer(id: string, params: object) {
    return this.apiClient.get(`general-ledger/${id}/dilution-by-customer`, params)
  }

  listDilutionOverTime(id: string, params: object) {
    return this.apiClient.get(`general-ledger/${id}/dilution-over-time`, params)
  }
  listDilutionDropdowns(id: string, params: object = {}) {
    return this.apiClient.get(`general-ledger/${id}/dilution-dropdowns`, params)
  }
}
