import { IClientInfo } from './client'

export interface IClientGeneralLedger {
  id: string
  recordDate: string
  debtor: string | null
  documentNumber: string | null
  type: string
  memo: string
  amount: number
  balance: number | null
  createdAt: Date
  updatedAt: Date
  fileId: string
  clientInfo: IClientInfo
}

export interface IClientGeneralLedgerMapping {
  id?: string
  type: string
  glMap?: string
  totalUnmapped?: number
}

export interface IClientGeneralLedgerDilutionByCustomer {
  clientName: string
  debtor: string
  recordDate: string
  invoices: number
  payments: number
  dilutiveCredits: number
  other: number
  dilution: number
}

export interface IClientGeneralLedgerDilutionByCustomerData {
  data: IClientGeneralLedgerDilutionByCustomer[]
  totals: {
    invoices: number
    payments: number
    dilutiveCredits: number
    other: number
    dilution: number
    totalItems: number
  }
}

export interface IClientGeneralLedgerDilutionOverTime {
  clientName: string
  debtor: string
  invoices: number[]
  payments: number[]
  dilutiveCredits: number[]
  other: number[]
  dilution: number[]
}

export interface IDilutionDropwdowns {
  minDate: string
  maxDate: string
  debtors: string[]
}

export interface IClientGeneralLedgerDilutionOverTimeData {
  data: IClientGeneralLedgerDilutionOverTime[]
  headers: string[]
}

export const CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS = [
  'invoices',
  'payments',
  'dilutiveCredits',
  'other',
  'dilution',
]

export const CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_FIELDS_COLORS = {
  invoices: '#395580',
  payments: '#CBD5E0',
  dilutiveCredits: '#718096',
  other: '#2F425F',
  dilution: '#0066F5',
}

export const CLIENT_GENERAL_LEDGER_DILUTION_GRAPH_LINE_FIELDS = ['dilution']

export const CLIENT_GENERAL_LEDGER_DILUTION_FIELDS_LABELS = {
  invoices: 'Invoices',
  payments: 'Payments',
  dilutiveCredits: 'Dilutive Credits',
  other: 'Other',
  dilution: 'Dilution',
}

export enum GeneralLedgerType {
  Invoice = 'Invoice',
  Payment = 'Payment',
  Discount = 'Discount',
  CreditMemo = 'Credit Memo',
  DilutiveMiscellaneousCredit = 'Dilutive Misc. Credit',
  NonDilutiveMiscellaneousCredit = 'Non-Dilutive Misc. Credit',
  Other = 'Other',
}
