import { IApi } from './../../api/index'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'notes'

export const TOGGLE_NOTES = `${prefix}/TOGGLE_NOTES`

export const toggleNotes = () => ({
  type: TOGGLE_NOTES,
})

export const TOGGLE_NOTES_OFF = `${prefix}/TOGGLE_NOTES_OFF`

export const toggleNotesOff = () => ({
  type: TOGGLE_NOTES_OFF,
})

export const TOGGLE_NOTES_ON = `${prefix}/TOGGLE_NOTES_ON`

export const toggleNotesOn = () => ({
  type: TOGGLE_NOTES_ON,
})

export const ADD_NOTE_REQUEST = `${prefix}/ADD_NOTE_REQUEST`
export const ADD_NOTE_SUCCESS = `${prefix}/ADD_NOTE_SUCCESS`
export const ADD_NOTE_FAILURE = `${prefix}/ADD_NOTE_FAILURE`

export const addNote: CommonPromiseAction = (params: object) => ({
  type: [ADD_NOTE_REQUEST, ADD_NOTE_SUCCESS, ADD_NOTE_FAILURE],
  promise: (api: IApi) => api.notes.add(params),
})

const ADD_NOTE_TAG = createRequestTypes(prefix, 'ADD_NOTE_TAG')
export const [ADD_NOTE_TAG_REQUEST, ADD_NOTE_TAG_SUCCESS, ADD_NOTE_TAG_FAILURE] = ADD_NOTE_TAG

export const addNoteTag: CommonPromiseAction = (params: object) => ({
  type: [ADD_NOTE_TAG_REQUEST, ADD_NOTE_TAG_SUCCESS, ADD_NOTE_TAG_FAILURE],
  promise: (api: IApi) => api.notes.addTags(params),
})

export const LIST_NOTES_REQUEST = `${prefix}/LIST_NOTES_REQUEST`
export const LIST_NOTES_SUCCESS = `${prefix}/LIST_NOTES_SUCCESS`
export const LIST_NOTES_FAILURE = `${prefix}/LIST_NOTES_FAILURE`

export const listNotes: CommonPromiseAction = (id: string, params: object) => ({
  type: [LIST_NOTES_REQUEST, LIST_NOTES_SUCCESS, LIST_NOTES_FAILURE],
  promise: (api: IApi) => api.notes.list(id, params),
  // @ts-ignore
  loadMore: params.loadMore,
  params,
})

export const DELETE_NOTE_REQUEST = `${prefix}/DELETE_NOTE_REQUEST`
export const DELETE_NOTE_SUCCESS = `${prefix}/DELETE_NOTE_SUCCESS`
export const DELETE_NOTE_FAILURE = `${prefix}/DELETE_NOTE_FAILURE`

export const deleteNote: CommonPromiseAction = (id: string) => ({
  type: [DELETE_NOTE_REQUEST, DELETE_NOTE_SUCCESS, DELETE_NOTE_FAILURE],
  promise: (api: IApi) => api.notes.delete(id),
})

const DELETE_NOTE_TAG = createRequestTypes(prefix, 'DELETE_NOTE_TAG')
export const [DELETE_NOTE_TAG_REQUEST, DELETE_NOTE_TAG_SUCCESS, DELETE_NOTE_TAG_FAILURE] =
  DELETE_NOTE_TAG

export const deleteNoteTag: CommonPromiseAction = (id: string, data: object) => ({
  type: [DELETE_NOTE_TAG_REQUEST, DELETE_NOTE_TAG_SUCCESS, DELETE_NOTE_TAG_FAILURE],
  promise: (api: IApi) => api.notes.deleteTags(id, data),
})

export const UPDATE_NOTE_REQUEST = `${prefix}/UPDATE_NOTE_REQUEST`
export const UPDATE_NOTE_SUCCESS = `${prefix}/UPDATE_NOTE_SUCCESS`
export const UPDATE_NOTE_FAILURE = `${prefix}/UPDATE_NOTE_FAILURE`

export const updateNote: CommonPromiseAction = (id: string, params: object) => ({
  type: [UPDATE_NOTE_REQUEST, UPDATE_NOTE_SUCCESS, UPDATE_NOTE_FAILURE],
  promise: (api: IApi) => api.notes.update(id, params),
})

export const HIDE_NOTES = `${prefix}/HIDE_NOTES`

export const hideNotes = () => ({
  type: HIDE_NOTES,
})

export const LIST_SUGGESTIONS_REQUEST = `${prefix}/LIST_SUGGESTIONS_REQUEST`
export const LIST_SUGGESTIONS_SUCCESS = `${prefix}/LIST_SUGGESTIONS_SUCCESS`
export const LIST_SUGGESTIONS_FAILURE = `${prefix}/LIST_SUGGESTIONS_FAILURE`

export const listSuggestions: CommonPromiseAction = (data: object = {}) => ({
  type: [LIST_SUGGESTIONS_REQUEST, LIST_SUGGESTIONS_SUCCESS, LIST_SUGGESTIONS_FAILURE],
  promise: (api: IApi) => api.notes.listSuggestions(data),
})

const EXPORT_NOTES = createRequestTypes(prefix, 'EXPORT_NOTES')
export const [EXPORT_NOTES_REQUEST, EXPORT_NOTES_SUCCESS, EXPORT_NOTES_FAILURE] = EXPORT_NOTES

export const exportNotes: CommonPromiseAction = (id: string, params: object) => ({
  type: [EXPORT_NOTES_REQUEST, EXPORT_NOTES_SUCCESS, EXPORT_NOTES_FAILURE],
  promise: (api: IApi) =>
    api.notes.list(id, {
      ...params,
      isFile: true,
    }),
  params,
})

export const SUMMARIZE_NOTES_REQUEST = `${prefix}/SUMMARIZE_NOTES_REQUEST`
export const SUMMARIZE_NOTES_SUCCESS = `${prefix}/SUMMARIZE_NOTES_SUCCESS`
export const SUMMARIZE_NOTES_FAILURE = `${prefix}/SUMMARIZE_NOTES_FAILURE`

export const summarizeNotes: CommonPromiseAction = (id: string, params: object) => ({
  type: [SUMMARIZE_NOTES_REQUEST, SUMMARIZE_NOTES_SUCCESS, SUMMARIZE_NOTES_FAILURE],
  promise: (api: IApi) => api.notes.summarize(id, params),
})
