import { combineReducers } from 'redux'

import * as actions from './actions'

import { IClientInfo } from '@common/interfaces/client'
import {
  IDueDiligenceStats,
  IDueDiligenceAggregation,
  IDueDiligence,
  IDueDiligenceReporting,
  IDueDiligenceReportingFlow,
  IDueDiligenceDocumentRequest,
  IDueDiligenceCompanyInfo,
  IDueDiligenceContact,
  IDueDiligenceReference,
  IDueDiligenceBoardMember,
  IDueDiligenceExecutive,
  IDueDiligenceInventoryLocation,
  IDueDiligenceBankAccount,
  IDueDiligenceDocumentRequestType,
} from '@common/interfaces/dueDiligence'
import { loadingArrayReducer } from '../../helpers/helpers'
import { IAlias } from '@common/interfaces/bbc'
import { ILoadingData } from '../types'
import { createLoadingDataReducer } from '../../helpers/redux'
import { UPDATE_DUE_DILIGENCE } from '@common/constants/webSockets'

export interface IDueDiligenceState {
  loadingArray: string[]
  dueDiligenceStats: IDueDiligenceStats
  prospects: IClientInfo[]
  dueDiligenceAggregation: ILoadingData<IDueDiligenceAggregation>
  dueDiligenceInfo: IDueDiligence
  dueDiligenceReporting: ILoadingData<{ data: IDueDiligenceReporting[] }>
  dueDiligenceReportingFlows: ILoadingData<{ data: IDueDiligenceReportingFlow[] }>
  aliasMapping: ILoadingData<{ data: IAlias[] }>
  dueDiligenceDocumentRequestsTypes: IDueDiligenceDocumentRequestType[]
  dueDiligenceDocumentRequests: ILoadingData<{ data: IDueDiligenceDocumentRequest[] }>
  dueDiligenceCompanyInfo: IDueDiligenceCompanyInfo
  dueDiligenceTeamContacts: ILoadingData<{ data: IDueDiligenceContact[] }>
  dueDiligenceTeamExecutives: ILoadingData<{ data: IDueDiligenceExecutive[] }>
  dueDiligenceTeamBoardMembers: ILoadingData<{ data: IDueDiligenceBoardMember[] }>
  dueDiligenceTeamReferences: ILoadingData<{ data: IDueDiligenceReference[] }>
  dueDiligenceFinancialsInventoryLocations: ILoadingData<{ data: IDueDiligenceInventoryLocation[] }>
  dueDiligenceFinancialsBankAccounts: ILoadingData<{ data: IDueDiligenceBankAccount[] }>
  refreshCount: number
}

export default combineReducers<IDueDiligenceState>({
  loadingArray: loadingArrayReducer(
    [actions.prefix],
    [
      actions.SHOW_REPORTING_REQUEST,
      actions.UPDATE_REPORTING_REQUEST,
      actions.SHOW_DOCUMENTS_REQUEST,
      actions.UPDATE_DOCUMENT_REQUEST,
      actions.UNPROCESS_DOCUMENT_REQUEST,
      actions.PROCESS_DOCUMENTS_REQUEST,
      actions.UPLOAD_DOCUMENTS_REQUEST,
      actions.SHOW_DOCUMENT_REQUESTS_TYPES_REQUEST,
      actions.HIDE_TEAM_CONTACTS,
      actions.SHOW_DOCUMENT_REQUESTS_REQUEST,
      actions.HIDE_DOCUMENT_REQUESTS,
      actions.GET_DOCUMENT_REQUESTS_SHARED_LINK_REQUEST,
      actions.UPLOAD_DOCUMENT_REQUESTS_REQUEST,
      actions.CREATE_DOCUMENT_REQUESTS_REQUEST,
      actions.UPDATE_DOCUMENT_REQUESTS_REQUEST,
      actions.UPDATE_DOCUMENTS_REQUESTS_REQUEST,
      actions.DELETE_DOCUMENT_REQUESTS_REQUEST,
      actions.DELETE_DOCUMENTS_REQUESTS_REQUEST,
      actions.CREATE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
      actions.UPDATE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
      actions.DELETE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
      actions.COMPANY_INFO_SUCCESS,
      actions.UPDATE_COMPANY_INFO_SUCCESS,
      actions.LIST_TEAM_CONTACTS_REQUEST,
      actions.HIDE_TEAM_CONTACTS,
      actions.ADD_TEAM_CONTACT_REQUEST,
      actions.UPDATE_TEAM_CONTACT_REQUEST,
      actions.DELETE_TEAM_CONTACT_REQUEST,
      actions.LIST_TEAM_EXECUTIVES_REQUEST,
      actions.HIDE_TEAM_EXECUTIVES,
      actions.ADD_TEAM_EXECUTIVE_REQUEST,
      actions.UPDATE_TEAM_EXECUTIVE_REQUEST,
      actions.DELETE_TEAM_EXECUTIVE_REQUEST,
      actions.LIST_TEAM_BOARD_MEMBERS_REQUEST,
      actions.HIDE_TEAM_BOARD_MEMBERS,
      actions.ADD_TEAM_BOARD_MEMBER_REQUEST,
      actions.UPDATE_TEAM_BOARD_MEMBER_REQUEST,
      actions.DELETE_TEAM_BOARD_MEMBER_REQUEST,
      actions.LIST_TEAM_REFERENCES_REQUEST,
      actions.HIDE_TEAM_REFERENCES,
      actions.ADD_TEAM_REFERENCE_REQUEST,
      actions.UPDATE_TEAM_REFERENCE_REQUEST,
      actions.DELETE_TEAM_REFERENCE_REQUEST,
      actions.LIST_FINANCIALS_INVENTORY_LOCATIONS_REQUEST,
      actions.HIDE_FINANCIALS_INVENTORY_LOCATIONS,
      actions.ADD_FINANCIALS_INVENTORY_LOCATION_REQUEST,
      actions.UPDATE_FINANCIALS_INVENTORY_LOCATION_REQUEST,
      actions.DELETE_FINANCIALS_INVENTORY_LOCATION_REQUEST,
      actions.LIST_FINANCIALS_BANK_ACCOUNTS_REQUEST,
      actions.HIDE_FINANCIALS_BANK_ACCOUNTS,
      actions.ADD_FINANCIALS_BANK_ACCOUNT_REQUEST,
      actions.UPDATE_FINANCIALS_BANK_ACCOUNT_REQUEST,
      actions.DELETE_FINANCIALS_BANK_ACCOUNT_REQUEST,
      actions.LOAD_VENDORS_REQUEST,
      actions.UPDATE_TEAM_ADVISORS_REQUEST,
      actions.UPDATE_FINANCIALS_VENDORS_REQUEST,
      actions.GET_FINANCIALS_CODAT_LINK_REQUEST,
      actions.CLIENT_INFO_RELOAD_REQUEST,
      actions.REFRESH_AUTO_REPORTING_REQUEST,
    ],
  ),
  dueDiligenceStats(state: IDueDiligenceStats = null, action) {
    switch (action.type) {
      case actions.GET_STATS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  prospects(state: IClientInfo[] = [], action) {
    switch (action.type) {
      case actions.LIST_PROSPECTS_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  dueDiligenceAggregation: createLoadingDataReducer<IDueDiligenceAggregation>([
    actions.LIST_SUCCESS,
  ]),
  dueDiligenceInfo(state: IDueDiligence = null, action) {
    switch (action.type) {
      case actions.SHOW_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.CLIENT_INFO_SUCCESS:
      case actions.CLIENT_INFO_RELOAD_SUCCESS:
        return action.data
      case actions.GET_FINANCIALS_CODAT_LINK_SUCCESS:
        return {
          ...state,
          ...action.data,
        }
      case actions.REFRESH_AUTO_REPORTING_SUCCESS:
      case UPDATE_DUE_DILIGENCE:
        if (!state || state?.id !== action.data.id) {
          return state
        }

        return {
          ...state,
          ...action.data,
        }
      case actions.HIDE:
        return null
      default:
        return state
    }
  },
  dueDiligenceReporting: createLoadingDataReducer<{ data: IDueDiligenceReporting[] }>(
    [actions.SHOW_REPORTING_REQUEST],
    [actions.UPDATE_REPORTING_REQUEST],
    true,
  ),
  dueDiligenceReportingFlows: createLoadingDataReducer<{ data: IDueDiligenceReportingFlow[] }>(
    [actions.SHOW_DOCUMENTS_REQUEST],
    [
      actions.UPDATE_DOCUMENT_REQUEST,
      actions.UNPROCESS_DOCUMENT_REQUEST,
      actions.DELETE_DOCUMENTS_REQUEST,
      actions.CREATE_DOCUMENTS_REQUEST,
    ],
    true,
    [actions.HIDE_DOCUMENTS],
  ),
  aliasMapping: createLoadingDataReducer<{ data: IAlias[] }>(
    [actions.LIST_ALIAS_MAPPING_REQUEST],
    [],
    false,
    [actions.HIDE_ALIAS_MAPPING],
  ),
  dueDiligenceDocumentRequestsTypes(state: IDueDiligenceDocumentRequestType[] = [], action) {
    switch (action.type) {
      case actions.SHOW_DOCUMENT_REQUESTS_TYPES_SUCCESS:
        return action.data
      case actions.HIDE_DOCUMENT_REQUESTS_TYPES:
        return []
      default:
        return state
    }
  },
  dueDiligenceDocumentRequests: createLoadingDataReducer<{ data: IDueDiligenceDocumentRequest[] }>(
    [actions.SHOW_DOCUMENT_REQUESTS_REQUEST],
    [
      actions.CREATE_DOCUMENT_REQUESTS_REQUEST,
      actions.UPLOAD_DOCUMENT_REQUESTS_REQUEST,
      actions.UPDATE_DOCUMENT_REQUESTS_REQUEST,
      actions.UPDATE_DOCUMENTS_REQUESTS_REQUEST,
      actions.DELETE_DOCUMENT_REQUESTS_REQUEST,
      actions.DELETE_DOCUMENTS_REQUESTS_REQUEST,
      actions.CREATE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
      actions.UPDATE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
      actions.DELETE_DOCUMENT_REQUESTS_COMMENT_REQUEST,
    ],
    true,
    [actions.HIDE_DOCUMENT_REQUESTS],
  ),
  dueDiligenceCompanyInfo(state: IDueDiligenceCompanyInfo = null, action) {
    switch (action.type) {
      case actions.COMPANY_INFO_SUCCESS:
      case actions.UPDATE_COMPANY_INFO_SUCCESS:
        return action.data
      default:
        return state
    }
  },
  dueDiligenceTeamContacts: createLoadingDataReducer<{ data: IDueDiligenceContact[] }>(
    [actions.LIST_TEAM_CONTACTS_REQUEST],
    [
      actions.ADD_TEAM_CONTACT_REQUEST,
      actions.UPDATE_TEAM_CONTACT_REQUEST,
      actions.DELETE_TEAM_CONTACT_REQUEST,
    ],
    true,
    [actions.HIDE_TEAM_CONTACTS],
  ),
  dueDiligenceTeamExecutives: createLoadingDataReducer<{ data: IDueDiligenceExecutive[] }>(
    [actions.LIST_TEAM_EXECUTIVES_REQUEST],
    [
      actions.ADD_TEAM_EXECUTIVE_REQUEST,
      actions.UPDATE_TEAM_EXECUTIVE_REQUEST,
      actions.DELETE_TEAM_EXECUTIVE_REQUEST,
    ],
    true,
    [actions.HIDE_TEAM_EXECUTIVES],
  ),
  dueDiligenceTeamBoardMembers: createLoadingDataReducer<{ data: IDueDiligenceBoardMember[] }>(
    [actions.LIST_TEAM_BOARD_MEMBERS_REQUEST],
    [
      actions.ADD_TEAM_BOARD_MEMBER_REQUEST,
      actions.UPDATE_TEAM_BOARD_MEMBER_REQUEST,
      actions.DELETE_TEAM_BOARD_MEMBER_REQUEST,
    ],
    true,
    [actions.HIDE_TEAM_BOARD_MEMBERS],
  ),
  dueDiligenceTeamReferences: createLoadingDataReducer<{ data: IDueDiligenceReference[] }>(
    [actions.LIST_TEAM_REFERENCES_REQUEST],
    [
      actions.ADD_TEAM_REFERENCE_REQUEST,
      actions.UPDATE_TEAM_REFERENCE_REQUEST,
      actions.DELETE_TEAM_REFERENCE_REQUEST,
    ],
    true,
    [actions.HIDE_TEAM_REFERENCES],
  ),
  dueDiligenceFinancialsInventoryLocations: createLoadingDataReducer<{
    data: IDueDiligenceInventoryLocation[]
  }>(
    [actions.LIST_FINANCIALS_INVENTORY_LOCATIONS_REQUEST],
    [
      actions.ADD_FINANCIALS_INVENTORY_LOCATION_REQUEST,
      actions.UPDATE_FINANCIALS_INVENTORY_LOCATION_REQUEST,
      actions.DELETE_FINANCIALS_INVENTORY_LOCATION_REQUEST,
    ],
    true,
    [actions.HIDE_FINANCIALS_INVENTORY_LOCATIONS],
  ),
  dueDiligenceFinancialsBankAccounts: createLoadingDataReducer<{
    data: IDueDiligenceBankAccount[]
  }>(
    [actions.LIST_FINANCIALS_BANK_ACCOUNTS_REQUEST],
    [
      actions.ADD_FINANCIALS_BANK_ACCOUNT_REQUEST,
      actions.UPDATE_FINANCIALS_BANK_ACCOUNT_REQUEST,
      actions.DELETE_FINANCIALS_BANK_ACCOUNT_REQUEST,
    ],
    true,
    [actions.HIDE_FINANCIALS_BANK_ACCOUNTS],
  ),
  refreshCount: (state = 0, action) => {
    switch (action.type) {
      case actions.REFRESH_COUNT:
        return state + 1
    }

    return state
  },
})
