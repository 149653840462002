import React, { useCallback, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import styles from './BankAccountInformation.module.scss'
import Button from '../../Common/Button'
import { BankAccountType, IBankAccount } from '@common/interfaces/bankAccount'
import Modal from '../../Common/Modal'
import Box from '@mui/material/Box'
import { makeValidate } from 'mui-rff'
import Card from '../../Common/Card'
import { IBank } from '@common/interfaces/bank'
import * as Yup from 'yup'
import FormField from '../../Common/FormField'
import { voidHandler } from '../../../helpers/helpers'

const typeExternalWhen = Yup.string().when('isExternal', (isExternal: boolean, schema: any) =>
  String(isExternal) === 'true' ? schema.required('Required') : schema.nullable(),
)

const schema = Yup.object().shape({
  bankName: Yup.object().nullable().required('Required'),
  accountHolderName: typeExternalWhen,
  bankAccountNumber: Yup.string().required('Required'),
  abaRoutingNumber: typeExternalWhen,
  purpose: Yup.string().nullable().max(500),
})
const validate = makeValidate(schema)

const TYPE_OPTIONS = [
  {
    value: 'false',
    label: BankAccountType.Internal,
  },
  {
    value: 'true',
    label: BankAccountType.External,
  },
]
export enum InternalBanks {
  IDB = 'IDB Bank',
  Webster = 'Webster Bank',
}

const INTERNAL_BANKS = [InternalBanks.IDB, InternalBanks.Webster].map((name) => ({
  value: name,
  label: name,
}))

interface IProps {
  isEditModalShown: boolean
  setIsEditModalShown: (val: boolean) => void
  handleAddEditBankAccount:
    | ((data: Partial<IBankAccount> & { clientId: string }) => void)
    | ((id: string, data: Partial<IBankAccount>) => void)
  handleCloseMenu: () => void
  handleAddBank: (name: string) => void
  isAddModal: boolean
  selectedRow: IBankAccount
  banks: IBank[]
  clientId: string
  inClientSettings?: boolean
}

const AddEditBankAccount = ({
  isEditModalShown,
  setIsEditModalShown,
  handleAddEditBankAccount,
  selectedRow,
  handleCloseMenu,
  handleAddBank,
  isAddModal,
  banks,
  clientId,
  inClientSettings = false,
}: IProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const normalizeBankName = ({ value }: { label: string; value: string }) => value
  const handleAddBankAccount = useCallback(
    async ({ bankName, ...data }: any) => {
      setIsSubmitting(true)
      ;(handleAddEditBankAccount as (data: Partial<IBankAccount> & { clientId: string }) => void)({
        clientId,
        bankName: normalizeBankName(bankName),
        ...data,
      })
    },
    [handleAddEditBankAccount, clientId],
  )

  const handleUpdateBankAccount = useCallback(
    async ({ id, ...data }: any) => {
      setIsSubmitting(true)
      data.bankName = data.bankName.value
      data.purpose = data.purpose || ''
      data.accountHolderName = data.accountHolderName || ''
      data.isExternal = String(data.isExternal === 'true')
      ;(handleAddEditBankAccount as (id: string, data: Partial<IBankAccount>) => void)(id, data)
    },
    [handleAddEditBankAccount],
  )

  const bankOptions = useMemo(
    () => banks.map(({ name }) => ({ value: name, label: name })),
    [banks],
  )

  return (
    <Card withBorder={false} noPadding noHeaderMargin>
      <Form
        onSubmit={isAddModal ? handleAddBankAccount : handleUpdateBankAccount}
        validate={validate}
        initialValues={{
          id: selectedRow?.id,
          isExternal: !selectedRow || selectedRow?.isExternal,
          bankName: { value: selectedRow?.bankName, label: selectedRow?.bankName },
          accountHolderName: selectedRow?.accountHolderName,
          bankAccountNumber: selectedRow?.bankAccountNumber,
          abaRoutingNumber: selectedRow?.abaRoutingNumber,
          isFunding: selectedRow?.isFunding,
          purpose: selectedRow?.purpose,
        }}
        render={({ dirty, submitting, invalid, form, handleSubmit, values }) => {
          const isExternal = values.isExternal && String(values.isExternal) === 'true'
          return (
            <form>
              <Modal
                open={isEditModalShown}
                onCancel={handleCloseMenu}
                title={isAddModal ? 'Add Account' : 'Edit Account'}
                classes={{
                  root: styles.editModal,
                  footer: styles.editModalFooter,
                }}
                footer={[
                  <Button
                    key="submit"
                    color="primary"
                    variant="contained"
                    disabled={!dirty || invalid || submitting}
                    className={styles.submitModalButton}
                    isLoading={isSubmitting}
                    onClick={(data) => {
                      handleSubmit(data).then(() => {
                        form.restart()
                        handleCloseMenu()
                        setIsSubmitting(false)
                        setIsEditModalShown(false)
                      })
                    }}
                  >
                    {isAddModal ? 'Add Account' : 'Edit Account'}
                  </Button>,
                ]}
              >
                <Box className={styles.fieldsContainer}>
                  {inClientSettings && (
                    <FormField
                      size="medium"
                      name="isExternal"
                      label="Type"
                      options={TYPE_OPTIONS}
                      type="select"
                    />
                  )}
                  {isExternal ? (
                    <FormField
                      size="small"
                      name="bankName"
                      label="Bank"
                      options={bankOptions}
                      type="creatable"
                      onAddValue={handleAddBank}
                    />
                  ) : (
                    <FormField
                      size="small"
                      name="bankName"
                      label="Bank"
                      options={INTERNAL_BANKS}
                      type="creatable"
                      onAddValue={voidHandler}
                    />
                  )}
                  {isExternal && (
                    <FormField
                      size="small"
                      name="accountHolderName"
                      label="Account Holder"
                      type="text"
                    />
                  )}
                  <FormField
                    size="small"
                    name="bankAccountNumber"
                    label="Account Number"
                    type="text"
                  />
                  {isExternal && (
                    <>
                      <FormField
                        size="small"
                        name="abaRoutingNumber"
                        label="ABA Routing"
                        type="text"
                      />
                      <FormField
                        size="small"
                        name="isFunding"
                        label="Funding Account"
                        type="boolean"
                        labelSize="small"
                      />
                    </>
                  )}
                  <FormField size="small" name="purpose" label="Purpose" type="text" rows={2} />
                </Box>
              </Modal>
            </form>
          )
        }}
      />
    </Card>
  )
}

export default AddEditBankAccount
