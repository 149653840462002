import { generatePath } from 'react-router-dom'
import { History } from 'history'
import { Action } from 'redux'
import { takeEvery, put, select } from 'redux-saga/effects'

import {
  LIST_SUCCESS,
  PROCESS_DOCUMENTS_SUCCESS,
  UPLOAD_DOCUMENT_REQUESTS_SUCCESS,
  ADD_TEAM_CONTACT_SUCCESS,
  UPDATE_TEAM_CONTACT_SUCCESS,
  DELETE_TEAM_CONTACT_SUCCESS,
  ADD_TEAM_EXECUTIVE_SUCCESS,
  UPDATE_TEAM_EXECUTIVE_SUCCESS,
  DELETE_TEAM_EXECUTIVE_SUCCESS,
  ADD_TEAM_BOARD_MEMBER_SUCCESS,
  UPDATE_TEAM_BOARD_MEMBER_SUCCESS,
  DELETE_TEAM_BOARD_MEMBER_SUCCESS,
  ADD_TEAM_REFERENCE_SUCCESS,
  UPDATE_TEAM_REFERENCE_SUCCESS,
  DELETE_TEAM_REFERENCE_SUCCESS,
  ADD_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
  UPDATE_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
  DELETE_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
  ADD_FINANCIALS_BANK_ACCOUNT_SUCCESS,
  DELETE_FINANCIALS_BANK_ACCOUNT_SUCCESS,
  UPDATE_TEAM_ADVISORS_SUCCESS,
  UPDATE_FINANCIALS_VENDORS_SUCCESS,
  GET_DOCUMENT_REQUESTS_SHARED_LINK_SUCCESS,
  getStats,
  listProspects,
  showDocuments,
  hideDocuments,
  loadClientInfo,
  showDocumentRequests,
  getCompanyInfo,
  RUN_BBC_SUCCESS,
  refreshCount,
} from './actions'
import { dueDiligenceInfo } from './selectors'
import {
  DUE_DILIGENCE_ROUTES_MAP,
  IDueDiligence,
  IDueDiligenceReportingFlow,
} from '@common/interfaces/dueDiligence'
import { IResponse } from '@common/interfaces/request'
import { PROFILE_SUCCESS } from '../profile/actions'
import { isProspectUser, user } from '../profile/selectors'
import { IUser } from '@common/interfaces/user'
import { ROUTES } from '../../constants/routes'
import { IBorrowingBase, OngoingReportingType } from '@common/interfaces/bbc'
import { UPDATE_DUE_DILIGENCE } from '@common/constants/webSockets'
import { putNotification } from '../ui/actions'

export function* refreshData() {
  yield takeEvery(LIST_SUCCESS, function* (action: Action & { params: { isRefresh: boolean } }) {
    if (action.params.isRefresh) {
      yield put(getStats())
      yield put(listProspects())
    }
  })
}

export function* refreshDocuments() {
  yield takeEvery(
    [PROCESS_DOCUMENTS_SUCCESS],
    function* (action: Action & { params: { id: string; type: string } }) {
      yield put(showDocuments(action.params.id, action.params.type, { skipLoader: true }))
    },
  )
}

export function* processDocumentsSuccess(history: History) {
  yield takeEvery(
    [PROCESS_DOCUMENTS_SUCCESS],
    function* (
      action: Action & {
        data: {
          isAllCompleted: boolean
          isSubmit: boolean
          dueDiligenceReportingFlows: IDueDiligenceReportingFlow[]
          totalDuplicateSkus: number
        }
        params: { id: string; type: string }
      },
    ) {
      if (action.data.isAllCompleted) {
        let route: string
        if (
          [OngoingReportingType.AR, OngoingReportingType.AP].includes(
            action.params.type as OngoingReportingType,
          )
        ) {
          route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_MAPPING, {
            id: action.params.id,
            type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
          })
        } else if (
          [
            OngoingReportingType.IncomeStatement,
            OngoingReportingType.BalanceSheet,
            OngoingReportingType.IncomeStatementProjections,
            OngoingReportingType.BalanceSheetProjections,
          ].includes(action.params.type as OngoingReportingType)
        ) {
          if (action.data.isSubmit) {
            yield put(hideDocuments())
            route = generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id: action.params.id })
          } else {
            route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_FINANCIALS, {
              clientId: action.params.id,
              type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
              id: action.data.dueDiligenceReportingFlows[0]?.id,
            })
          }
        } else if (action.params.type === OngoingReportingType.BankTransactions) {
          if (action.data.isSubmit) {
            yield put(hideDocuments())
            route = generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id: action.params.id })
          } else {
            route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_BANK_TRANSACTIONS, {
              id: action.params.id,
              type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
            })
          }
        } else if (action.params.type === OngoingReportingType.ARGeneralLedger) {
          route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_GENERAL_LEDGER, {
            id: action.params.id,
            type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
          })
        } else if (action.params.type === OngoingReportingType.SalesBySKU) {
          if (action.data.isSubmit) {
            yield put(hideDocuments())
            route = generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id: action.params.id })
          } else if (action.data.totalDuplicateSkus === 0) {
            route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_SALES_BY_SKU, {
              id: action.params.id,
              type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
            })
          }
        } else if (action.params.type === OngoingReportingType.Inventory) {
          if (action.data.isSubmit) {
            yield put(hideDocuments())
            route = generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id: action.params.id })
          } else {
            route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_INVENTORY, {
              id: action.params.id,
              type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
            })
          }
        } else if (action.params.type === OngoingReportingType.CapTable) {
          if (action.data.isSubmit) {
            yield put(hideDocuments())
            route = generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id: action.params.id })
          } else {
            route = generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_CAP_TABLE, {
              id: action.params.id,
              type: DUE_DILIGENCE_ROUTES_MAP[action.params.type],
            })
          }
        }
        if (route) {
          history.push(route)
        }
      }
    },
  )
}

export function* profileSuccess() {
  yield takeEvery(PROFILE_SUCCESS, function* () {
    const { isProspectUser }: IUser = yield select(user)
    if (isProspectUser) {
      yield put(loadClientInfo())
      // @ts-ignore
      yield yield put(getCompanyInfo())
      yield put(showDocumentRequests(null, { skipLoader: true }))
    }
  })
}

export function* documentRequestUploadSuccess() {
  yield takeEvery(UPLOAD_DOCUMENT_REQUESTS_SUCCESS, function* () {
    const isProspect: string = yield select(isProspectUser)

    if (isProspect) {
      yield put(getCompanyInfo())
      yield put(showDocumentRequests(null, { skipLoader: true }))
    }
  })
}

export function* updateTeamMemberSuccess() {
  yield takeEvery(
    [
      ADD_TEAM_CONTACT_SUCCESS,
      UPDATE_TEAM_CONTACT_SUCCESS,
      DELETE_TEAM_CONTACT_SUCCESS,
      ADD_TEAM_EXECUTIVE_SUCCESS,
      UPDATE_TEAM_EXECUTIVE_SUCCESS,
      DELETE_TEAM_EXECUTIVE_SUCCESS,
      ADD_TEAM_BOARD_MEMBER_SUCCESS,
      UPDATE_TEAM_BOARD_MEMBER_SUCCESS,
      DELETE_TEAM_BOARD_MEMBER_SUCCESS,
      ADD_TEAM_REFERENCE_SUCCESS,
      UPDATE_TEAM_REFERENCE_SUCCESS,
      DELETE_TEAM_REFERENCE_SUCCESS,
      UPDATE_TEAM_ADVISORS_SUCCESS,
      UPDATE_FINANCIALS_VENDORS_SUCCESS,
      ADD_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
      UPDATE_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
      DELETE_FINANCIALS_INVENTORY_LOCATION_SUCCESS,
      ADD_FINANCIALS_BANK_ACCOUNT_SUCCESS,
      DELETE_FINANCIALS_BANK_ACCOUNT_SUCCESS,
    ],
    function* () {
      yield put(getCompanyInfo())
    },
  )
}

export function* getDocumentRequestsSharedLink() {
  yield takeEvery(
    [GET_DOCUMENT_REQUESTS_SHARED_LINK_SUCCESS],
    function* (action: IResponse & Action) {
      yield window.open(action.data.url, '_blank')
    },
  )
}

export function* runBBCSuccess(history: History) {
  yield takeEvery(
    [RUN_BBC_SUCCESS],
    function* (
      action: Action & {
        data: IBorrowingBase | null
      },
    ) {
      if (action.data?.id) {
        yield history.push(
          action.data.preProcessed
            ? generatePath(ROUTES.BBC_SUMMARY, { id: action.data.id })
            : ROUTES.ACTIVITY_QUEUE,
        )
      }
    },
  )
}

export function* updateDueDiligenceInfo() {
  yield takeEvery(UPDATE_DUE_DILIGENCE, function* (data: IResponse & Action) {
    const ddInfo: IDueDiligence = yield select(dueDiligenceInfo)
    if (ddInfo?.id === data.data?.id) {
      yield put(refreshCount())
      yield put(
        putNotification({
          code: '',
          message: `${ddInfo.clientName} Codat reporting ${
            data.data.result.status
              ? 'has been successfully updated.'
              : 'failed to update. Please try again.'
          }`,
          type: data.data.result.status ? 'success' : 'error',
        }),
      )
    }
  })
}
