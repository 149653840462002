import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import Box from '@mui/material/Box'
import { generatePath } from 'react-router-dom'

import styles from './DueDiligenceSalesBySKUPage.module.scss'

import { ROUTES } from '../../constants/routes'
import Card from '../../components/Common/Card'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import InvetoryMappingTable from '../../components/InvetoryMappingTable'
import SalesBySKUVisualizationTable from '../../components/SalesBySKUVisualizationTable'
import { EditMapping } from '../../components/Common/Icons'
import FullscreenModal from '../../components/Common/FullscreenModal'
import { WorkflowPage } from '@common/interfaces/bbc'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
}

const DueDiligenceSalesBySKUPage = ({ dueDiligenceInfo, show }: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()

  const [isModalOpen, setIsModalOpen] = useState(false)
  useLoadInfo(id, dueDiligenceInfo, show)
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, {
          id,
          type: routeType,
        }),
        title: 'Sales by SKU',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_SALES_BY_SKU, {
          id,
          type: routeType,
        }),
        title: 'Sales by SKU Mapping',
      },
    ]
  }, [dueDiligenceInfo, id, routeType])

  return (
    <Box py={1} pr={2}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />
      <Card
        withBorder={false}
        title={
          <Box display="flex" justifyContent="flex-end" alignItems="center">
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={2}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <EditMapping action={() => setIsModalOpen(true)} title="Mapping" />
              </Box>
            </Box>
          </Box>
        }
      ></Card>
      <SalesBySKUVisualizationTable
        currentId={dueDiligenceInfo?.id}
        type={WorkflowPage.dueDilligence}
      />
      {isModalOpen && (
        <FullscreenModal isOpen setIsOpen={handleCloseModal} disableEnforceFocus showCloseIcon>
          <Card noHeaderMargin className={styles.mappingContainer}>
            <InvetoryMappingTable />
          </Card>
        </FullscreenModal>
      )}
    </Box>
  )
}

export default DueDiligenceSalesBySKUPage
