import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { ClientEntityRelationshipType } from '@common/interfaces/integration'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import DueDiligenceApplicationTableContacts from '../../components/DueDiligenceApplicationTableContacts'
import DueDiligenceApplicationTableExecutives from '../../components/DueDiligenceApplicationTableExecutives'
import DueDiligenceApplicationTableBoardMembers from '../../components/DueDiligenceApplicationTableBoardMembers'
import DueDiligenceApplicationTableReferences from '../../components/DueDiligenceApplicationTableReferences'
import Card from '../../components/Common/Card'
import DueDiligencePageHeader from '../../components/DueDiligencePageHeader'
import { DueDiligenceInfoApplicationData } from '../DueDiligenceCompanyBackgroundPage/DueDiligenceCompanyBackgroundPage'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
  isClientPage?: boolean
}

const DueDiligenceTeamPage = ({ dueDiligenceInfo, show, isClientPage }: IProps) => {
  const { id } = useParams<{ id: string }>()
  useLoadInfo(id, dueDiligenceInfo, show)
  const data = useMemo(() => {
    if (!dueDiligenceInfo) {
      return []
    }

    const lawFirm = dueDiligenceInfo.clientEntityRelationship?.find(
      ({ type }) => type === ClientEntityRelationshipType.LawFirm,
    )
    const outsourcedCFO = dueDiligenceInfo.clientEntityRelationship?.find(
      ({ type }) => type === ClientEntityRelationshipType.OutsourcedCFO,
    )
    const accountingFirm = dueDiligenceInfo.clientEntityRelationship?.find(
      ({ type }) => type === ClientEntityRelationshipType.AccountingFirm,
    )

    return [
      {
        header: 'Legal Counsel',
        data: [
          {
            label: 'Law firm?',
            value: lawFirm ? lawFirm.salesforceAccount?.name || lawFirm.newSalesforceAccount : '',
          },
          {
            label: 'Lawyer full name?',
            value: lawFirm ? lawFirm.fullName : '',
          },
          {
            label: 'Phone number',
            value: lawFirm ? lawFirm.phone : '',
          },
          {
            label: 'Email address',
            value: lawFirm ? lawFirm.email : '',
          },
        ],
      },
      {
        header: 'Outsourced CFO Firm',
        data: [
          {
            label: 'Does your company use an outsourced CFO or bookkeeper?',
            value: dueDiligenceInfo.dueDiligenceClientInfo?.isOutsourcedCFO || false,
          },
          {
            label: 'Outsourced CFO firm',
            value:
              dueDiligenceInfo.dueDiligenceClientInfo?.isOutsourcedCFO && outsourcedCFO
                ? outsourcedCFO.salesforceAccount?.name || outsourcedCFO.newSalesforceAccount
                : '',
          },
          {
            label: 'Outsourced CFO full name',
            value:
              dueDiligenceInfo.dueDiligenceClientInfo?.isOutsourcedCFO && outsourcedCFO
                ? outsourcedCFO.fullName
                : '',
          },
          {
            label: 'Phone number',
            value:
              dueDiligenceInfo.dueDiligenceClientInfo?.isOutsourcedCFO && outsourcedCFO
                ? outsourcedCFO.phone
                : '',
          },
          {
            label: 'Email address',
            value:
              dueDiligenceInfo.dueDiligenceClientInfo?.isOutsourcedCFO && outsourcedCFO
                ? outsourcedCFO.email
                : '',
          },
        ],
      },
      {
        header: 'Accounting Firm',
        data: [
          {
            label: 'Accounting firm',
            value: accountingFirm
              ? accountingFirm.salesforceAccount?.name || accountingFirm.newSalesforceAccount
              : '',
          },
          {
            label: 'Accountant full name',
            value: accountingFirm ? accountingFirm.fullName : '',
          },
          {
            label: 'Phone number',
            value: accountingFirm ? accountingFirm.phone : '',
          },
          {
            label: 'Email address',
            value: accountingFirm ? accountingFirm.email : '',
          },
        ],
      },
      {
        header: 'Executives & Board Members',
        data: [
          {
            label: 'Executives with more than 5% ownership',
            value: dueDiligenceInfo.dueDiligenceClientInfo?.executivesCount,
          },
          {
            label: '# of board members',
            value: dueDiligenceInfo.dueDiligenceClientInfo?.boardMembersCount,
          },
        ],
      },
    ]
  }, [dueDiligenceInfo])

  if (!dueDiligenceInfo) {
    return null
  }

  return (
    <div>
      {!isClientPage && <DueDiligencePageHeader />}
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2} px={2}>
          <Card title="Contacts">
            <DueDiligenceApplicationTableContacts />
          </Card>
        </Grid>
        {data.map(({ header, data }) => (
          <Grid item xs={12} mb={2} px={2} key={header}>
            <Card title={header}>
              <DueDiligenceInfoApplicationData data={data} />
            </Card>
          </Grid>
        ))}
        <Grid item xs={12} mb={2} px={2}>
          <Card title="Executives">
            <DueDiligenceApplicationTableExecutives />
          </Card>
        </Grid>
        <Grid item xs={12} mb={2} px={2}>
          <Card title="Board Members">
            <DueDiligenceApplicationTableBoardMembers />
          </Card>
        </Grid>
        <Grid item xs={12} px={2}>
          <Card title="References">
            <DueDiligenceApplicationTableReferences />
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default DueDiligenceTeamPage
