import { useCallback, useEffect } from 'react'

export function useLoadInfo(
  id: string,
  info: { id: string },
  show: (id: string) => void,
  hide?: () => void,
  setIsLoading?: (state: boolean) => void,
): void {
  const handleLoad = useCallback(
    async (id: string) => {
      if (id) {
        setIsLoading && setIsLoading(true)
        hide && hide()
        await show(id)
        setIsLoading && setIsLoading(false)
      }
    },
    [show, hide, setIsLoading],
  )

  useEffect(() => {
    if (id && hide && info?.id && info.id !== id) {
      hide()
    }
  }, [id, info, hide])

  useEffect(() => {
    if (id && (!info?.id || info?.id !== id)) {
      handleLoad(id)
    }
  }, [id, info, handleLoad])
}
