import { ILoadingData } from '../types'
import { combineReducers } from 'redux'
import * as actions from './actions'
import { IBBCFlagDetailsData, IFlagData } from '@common/interfaces/bbc'
import { addNoteSuccess, deleteNoteSuccess, updateNoteSuccess } from '../common/notes'
import { ADD_NOTE_SUCCESS, DELETE_NOTE_SUCCESS, UPDATE_NOTE_SUCCESS } from '../notes/actions'
import { createLoadingDataReducer } from '../../helpers/redux'
import { orderBy } from 'lodash'
import moment from 'moment'

export interface IFlagState {
  flags: IFlagData
  flagDetails: ILoadingData<IBBCFlagDetailsData>
  newFlagsCount: number
  isLoading: boolean
}

export default combineReducers<IFlagState>({
  flags(state: IFlagData = null, action) {
    switch (action.type) {
      case actions.LIST_SUCCESS:
        if (!action.params.loadMore) {
          return action.data
        } else {
          return {
            ...state,
            data: [...state.data, ...action.data.data],
          }
        }
      case actions.UPDATE_FLAG_SUCCESS:
        const isResolvedFirst = state?.data?.[0]?.resolved
        const resolvedOrder = isResolvedFirst ? 'desc' : 'asc'
        const firstRecord = state?.data?.[0]
        const lastRecord = state?.data?.[state.data.length - 1]
        const recordDateOrder = moment(firstRecord?.recordDate).isBefore(lastRecord?.recordDate)
          ? 'asc'
          : 'desc'

        return {
          ...state,
          data: orderBy(
            state.data.map((item) => {
              if (item.id === action.data.id) {
                return action.data
              }
              return item
            }),
            ['resolved', 'recordDate'],
            [resolvedOrder, recordDateOrder],
          ),
        }
      case ADD_NOTE_SUCCESS:
        return addNoteSuccess(state, action)
      case DELETE_NOTE_SUCCESS:
        return deleteNoteSuccess(state, action)
      case UPDATE_NOTE_SUCCESS:
        return updateNoteSuccess(state, action)
      default:
        return state
    }
  },
  isLoading(state = false, action) {
    switch (action.type) {
      case actions.LIST_REQUEST:
        if (!action.params.loadMore) {
          return true
        }
        return false
      case actions.LIST_SUCCESS:
      case actions.LIST_FAILURE:
        return false
      default:
        return state
    }
  },
  flagDetails: createLoadingDataReducer<IBBCFlagDetailsData>([
    actions.GET_FLAG_DETAILS_PAID_PRIORITY_INVOICES_REQUEST,
    actions.GET_FLAG_DETAILS_DUPLICATE_INVOICES_REQUEST,
    actions.GET_FLAG_DETAILS_REQUEST,
  ]),
  newFlagsCount(state = 0, action) {
    switch (action.type) {
      case actions.GET_NEW_FLAGS_COUNT_SUCCESS:
        return action.data.count
      default:
        return state
    }
  },
})
