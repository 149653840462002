import React, { useCallback, useMemo, useEffect, useState } from 'react'
import { IProspectTerms, IOPSReporting, OPSReportingStatus } from '@common/interfaces/prospects'
import { generateSchemaValidator } from './helpers'
import {
  LINE_UTILIZATION_FIELDS_CONFIG,
  INTEREST_RATE_FIELDS_CONFIG,
  COLLATERAL_FIELDS_CONFIG,
  FEE_FIELDS_CONFIG,
  OTHER_FIELDS_CONFIG,
  All_MANAGE_TERMS_FIELDS_CONFIG,
  COVENANTS_FIELDS_CONFIG,
} from '@common/constants/prospects'
import Card from '../../components/Common/Card'
import Grid from '@mui/material/Grid'
import FormField from '../../components/Common/FormField'
import styles from './ProspectAddTermsPage.module.scss'
import { Form, FormSpy } from 'react-final-form'
import Button from '../../components/Common/Button/Button'
import { ROUTES } from '../../constants/routes'
import { generatePath } from 'react-router-dom'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useParams } from 'react-router'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { ILoadingData } from '../../redux/types'
import SaveState from '../../components/Common/SaveState'
import RouteLeavingGuard from '../../components/Common/RouteLeavingGuard'
import { useHistory } from 'react-router'
import { LOAN_TYPES } from '@common/constants/client'
import { AR_FIELDS, INVENTORY_FIELDS } from './helpers'
import { ClientInfoStatus } from '@common/interfaces/client'
import { usePermissions } from '../../helpers/permissionContext'

const FIELD_SECTION_CONFIG = [
  {
    title: 'Line utilization',
    fields: LINE_UTILIZATION_FIELDS_CONFIG,
  },
  {
    title: 'Interest rate',
    fields: INTEREST_RATE_FIELDS_CONFIG,
  },
  {
    title: 'Collateral',
    fields: COLLATERAL_FIELDS_CONFIG,
  },
  {
    title: 'Fee',
    fields: FEE_FIELDS_CONFIG,
  },
  {
    title: 'Covenants',
    fields: COVENANTS_FIELDS_CONFIG,
  },
  {
    title: 'Other',
    fields: OTHER_FIELDS_CONFIG,
  },
]

const validate = generateSchemaValidator(All_MANAGE_TERMS_FIELDS_CONFIG)

interface IProps {
  prospectTerms: ILoadingData<{ data: IProspectTerms[] }>
  reporting: IOPSReporting
  createProspectTerms: (id: string, term: IProspectTerms) => void
  updateProspectTerms: (id: string, prospectTermId: string, term: IProspectTerms) => void
  show: (id: string) => void
  listProspectTerms: (id: string) => void
}

const ProspectTerms = ({
  prospectTerms,
  reporting,
  createProspectTerms,
  updateProspectTerms,
  show,
  listProspectTerms,
}: IProps) => {
  const { id, termId } = useParams<{ id: string; termId: string }>()
  const [isDirty, setIsDirty] = useState(false)

  useLoadInfo(id, reporting, show)
  const title = useMemo(() => (termId ? 'Edit terms' : 'Add terms'), [termId])
  const { isUW } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) ||
      reporting?.status === OPSReportingStatus.Archived ||
      isUW,
    [reporting, isUW],
  )

  useSetPageTitle(title)

  const history = useHistory()

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  useEffect(() => {
    if (id || termId) {
      listProspectTerms(id)
    }
  }, [id, listProspectTerms, termId])

  const {
    data: prospectTermsData,
    isSaving,
    isSaved,
    isLoading,
  } = useMemo(
    () => ({
      data: prospectTerms?.data?.data,
      isLoading: prospectTerms.isLoading,
      isSaving: prospectTerms.isSaving,
      isSaved: prospectTerms.isSaved,
    }),
    [prospectTerms],
  )

  const prospectTerm = useMemo(() => {
    if (prospectTermsData && termId) {
      return prospectTermsData.find((term) => term.id === termId)
    } else if (prospectTermsData && prospectTermsData.length >= 1) {
      const newTerm = prospectTermsData[prospectTermsData.length - 1]
      delete newTerm.id
      return newTerm
    }
  }, [prospectTermsData, termId])

  const isEdit = useMemo(() => !!prospectTerm?.id, [prospectTerm])

  const handleSubmit = useCallback(
    async (values: any) => {
      if (isEdit) {
        await updateProspectTerms(reporting?.id, prospectTerm.id, values)
        setIsDirty(false)
      } else {
        setIsDirty(false)
        await createProspectTerms(reporting?.id, values)
      }
      history.push(generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id }))
    },
    [prospectTerm, reporting, createProspectTerms, updateProspectTerms, isEdit, history, id],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        link: ROUTES.PROSPECTS,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.PROSPECT_PAGE, { id }),
        title: reporting?.clientName,
      },
      {
        link: generatePath(ROUTES.PROSPECT_MANAGE_TERMS_PAGE, { id }),
        title: 'Manage terms',
      },
      {
        title: title,
        link: '#',
      },
    ],
    [reporting, id, title],
  )

  useEffect(() => {
    if (!isEdit) {
      setIsDirty(true)
    }
  }, [isEdit])

  return (
    <Grid container py={3} pr={2} alignItems={'flex-start'} justifyContent={'start'} rowSpacing={2}>
      <Grid item container xs={12} justifyContent={'space-between'}>
        <Grid item container xs={6} justifyContent={'flex-start'} alignItems={'center'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Form<any, any>
          initialValues={prospectTerm}
          onSubmit={handleSubmit}
          validate={validate}
          render={({ form, values, handleSubmit, dirtyFields }) => {
            const loanType = form.getFieldState('loanType')?.value
            const covenantType = form.getFieldState('covenantType')?.value
            return (
              <Card
                title={
                  <Grid container xs={12} justifyContent={'space-between'} alignItems={'center'}>
                    <Grid item xs={6}>
                      <span>Asset based loan</span>
                    </Grid>
                    <Grid item container xs={6} justifyContent={'flex-end'}>
                      <SaveState isSaving={isSaving} isSaved={isSaved} />
                      {!readOnly && (
                        <Button
                          type="submit"
                          small={false}
                          className={styles.editButtonIcon}
                          disabled={!dirtyFields}
                          onClick={handleSubmit}
                          isLoading={isSaving || isLoading}
                        >
                          Save & Exit
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
                withBorder={false}
              >
                <Grid container spacing={2} justifyContent={'start'}>
                  {FIELD_SECTION_CONFIG.map(({ title, fields }) => {
                    if (title === 'Collateral') {
                      if (loanType === LOAN_TYPES.ar) {
                        fields = fields.filter(({ name }) => AR_FIELDS.includes(name))
                      } else if (loanType === LOAN_TYPES.inventory) {
                        fields = fields.filter(({ name }) => INVENTORY_FIELDS.includes(name))
                      } else if (!loanType) {
                        fields = fields.filter(({ name }) => name === 'loanType')
                      }
                    }
                    if (title === 'Covenants') {
                      // onluy show qualified equity round if covenant type is not empty
                      fields = fields.filter(({ name }) => {
                        if (name === 'qualifiedEquityRound') {
                          return !!covenantType
                        }
                        return true
                      })
                    }
                    return (
                      <Grid item xs={12} key={title} container>
                        <Card
                          title={title}
                          classes={{
                            root: styles.card,
                          }}
                          isLoading={isLoading}
                        >
                          <Grid xs={12} container spacing={2} justifyContent={'start'}>
                            {fields.map(({ label, name, type, options, disabled }) => {
                              if (name === 'commentsForCommittee') {
                                return (
                                  <Grid item xs={12} xl={6} key={name}>
                                    <FormField
                                      className={styles.longText}
                                      label={label}
                                      name={name}
                                      type={type}
                                      options={options}
                                      disabled={readOnly || disabled}
                                      rows={4}
                                    />
                                  </Grid>
                                )
                              }
                              return (
                                <Grid item xs={3} xl={2} key={label}>
                                  <FormField
                                    label={label}
                                    name={name}
                                    type={type}
                                    options={options}
                                    disabled={readOnly || disabled}
                                    withClear
                                  />
                                </Grid>
                              )
                            })}
                          </Grid>
                        </Card>
                      </Grid>
                    )
                  })}
                </Grid>
                <RouteLeavingGuard
                  when={isDirty}
                  navigate={handleNavigate}
                  shouldBlockNavigation={() => isDirty}
                  helperText="You have unsaved changes. Are you sure you want to leave?"
                  buttonText="Save changes"
                  alternateSubmit={() => handleSubmit(values)}
                  isAlternateSubmitInvalid={form.getState().invalid}
                />
                <FormSpy
                  subscription={{ dirty: true, valid: true, touched: true, dirtyFields: true }}
                  onChange={(props) => {
                    if (props.dirty && Object.keys(dirtyFields).length > 0) {
                      setIsDirty(true)
                    }
                  }}
                />
              </Card>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}

export default ProspectTerms
