import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import DueDiligencePageHeader from './DueDiligencePageHeader'

import { dueDiligenceInfo, isLoading } from '../../redux/dueDiligence/selectors'
import { show, hide, update, runBBC, refreshAutoReporting } from '../../redux/dueDiligence/actions'
import { isAdminRightsRole } from '../../redux/profile/selectors'
import { loginAsClient } from '../../redux/profile/actions'
import { updateClient } from '../../redux/clientInfo/actions'

const selector = createStructuredSelector({
  dueDiligenceInfo,
  isLoading,
  isAdminRightsRole,
})
const actions = {
  show,
  hide,
  update,
  runBBC,
  loginAsClient,
  refreshAutoReporting,
  updateClient,
}

export { DueDiligencePageHeader }

export default connect(selector, actions)(DueDiligencePageHeader)
