import React, { useEffect, useCallback, useMemo } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import { Form } from 'react-final-form'
import Link from '@mui/material/Link'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './AnalysisQueueTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import Grid from '@mui/material/Grid'
import cn from 'classnames'
import {
  debounceEventHandler,
  formatDate,
  formatDateCalendarNoTime,
  formatDateShort,
  formatDateTime,
} from '../../helpers/helpers'
import Tooltip from '@mui/material/Tooltip'
import OngoingReportingActions from './AnalysisQueueActions'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import TableFiltersRow from '../Common/TableFiltersRow'
import { IClientInfo } from '@common/interfaces/client'
import {
  IOngoingReporting,
  IOngoingReportingData,
  OngoingReportingStatus,
  REPORTING_STATUS_LABEL,
} from '@common/interfaces/bbc'
import { ROUTES } from '../../constants/routes'
import { ANALYSIS_QUEUE_TYPES, REPORTING_DOCUMENT_TYPES } from '@common/constants/client'
import {
  buildFiltersDefaults,
  buildFiltersValidateSchema,
  updateDateFilters,
} from '../../helpers/filters'
import { ONGOING_REPORTING_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import SaveState from '../Common/SaveState'
import DashboardHeader from '../DashboardHeader'
import { IHeaderItem } from '../DashboardHeader/DashboardHeader'
import useTable from '../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(ONGOING_REPORTING_LIST_FILTERS_CONFIG)
export const filtersDefaults = buildFiltersDefaults(ONGOING_REPORTING_LIST_FILTERS_CONFIG, {
  statuses: [
    OngoingReportingStatus.NotStarted,
    OngoingReportingStatus.Uploaded,
    OngoingReportingStatus.Process,
    OngoingReportingStatus.InReview,
    OngoingReportingStatus.Verified,
  ],
})

const STATUSES_OPTIONS = [
  ...Object.values(OngoingReportingStatus).map((value) => ({
    value,
    label: REPORTING_STATUS_LABEL[value],
  })),
]

const TYPE_OPTIONS = ANALYSIS_QUEUE_TYPES.map((value) => ({
  value,
  label: value === REPORTING_DOCUMENT_TYPES.arGeneralLedger ? 'Dilution' : value,
}))

interface IProps {
  clients: ILoadingData<{ data: IClientInfo[] }>
  fullOngoingReportingData: ILoadingData<IOngoingReportingData>
  listOngoingReporting: (params?: {
    page?: number
    perPage?: number
    filters?: object
    orderBy?: string
    orderDirection?: string
  }) => void
  deleteOngoingReporting: (id: string) => Promise<void>
  updateOngoingReportingStatus: (data: object) => Promise<void>
  hideOngoingReporting: () => void
  hideAliases: () => void
  hideTypeMapping: () => void
}

const AnalysisQueueTable = ({
  clients,
  fullOngoingReportingData,
  listOngoingReporting,
  deleteOngoingReporting,
  updateOngoingReportingStatus,
  hideOngoingReporting,
  hideAliases,
  hideTypeMapping,
}: IProps) => {
  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'analysisQueue',
    filtersDefaults,
    sortDefault: {
      field: 'created_at',
      direction: 'DESC',
    },
    quickFilterDefault: 'Action Required',
  })

  const { clientsData } = useMemo(
    () => ({
      clientsData: clients?.data?.data,
    }),
    [clients],
  )

  const {
    data: ongoingReportingData,
    isLoading,
    isSaved,
    isSaving,
  } = useMemo(() => fullOngoingReportingData, [fullOngoingReportingData])

  const itemsCount = useMemo(() => ongoingReportingData?.totals?.totalItems, [ongoingReportingData])

  useEffect(() => {
    hideOngoingReporting()
    hideTypeMapping()
    hideAliases()
  }, [hideOngoingReporting, hideAliases, hideTypeMapping])

  const fetchOngoingReportingList = useCallback(
    (data: any) => {
      const filters = updateDateFilters(data.filters, ONGOING_REPORTING_LIST_FILTERS_CONFIG)
      const params = {
        ...data,
        filters,
      }

      listOngoingReporting(params)
    },
    [listOngoingReporting],
  )
  const debounceListOngoingReporting = useMemo(
    () => debounceEventHandler(fetchOngoingReportingList, 500),
    [fetchOngoingReportingList],
  )

  useEffect(() => {
    debounceListOngoingReporting({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListOngoingReporting])

  const refetchOngoingReportingList = useCallback(() => {
    debounceListOngoingReporting({
      page: 0,
      perPage: itemsCount,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [itemsCount, filters, orderBy, debounceListOngoingReporting])

  const getLink = useCallback((item: IOngoingReporting) => {
    let link
    if (
      [REPORTING_DOCUMENT_TYPES.financials, REPORTING_DOCUMENT_TYPES.financialProjections].includes(
        item.type,
      )
    ) {
      link = generatePath(ROUTES.ONGOING_REPORTING, { id: item.id })
    } else if (REPORTING_DOCUMENT_TYPES.salesBySKU === item.type) {
      link = generatePath(ROUTES.ONGOING_REPORTING_SALES_BY_SKU, { id: item.id })
    } else if (REPORTING_DOCUMENT_TYPES.arGeneralLedger === item.type) {
      link = generatePath(ROUTES.ONGOING_REPORTING_AR_GENERAL_LEDGER, { id: item.id })
    } else if (REPORTING_DOCUMENT_TYPES.bankTransactions === item.type) {
      link = generatePath(ROUTES.BANK_TRANSACTIONS_UPLOAD, { id: item.id })
    } else if (REPORTING_DOCUMENT_TYPES.capTable === item.type) {
      link = generatePath(ROUTES.CAP_TABLE_UPLOAD, { id: item.id })
    } else {
      link = item.boxLink
    }

    return link
  }, [])

  const handleClick = useCallback(
    (item: IOngoingReporting) => {
      if (item.status === OngoingReportingStatus.NotStarted) {
        updateOngoingReportingStatus({ id: item.id, status: OngoingReportingStatus.Process })
      }
    },
    [updateOngoingReportingStatus],
  )

  const filtersConfig = useMemo(
    () =>
      ONGOING_REPORTING_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options:
          item.field === 'clients'
            ? clientsData?.map(({ clientName }) => ({
                value: clientName,
                label: clientName,
              }))
            : item.field === 'type'
            ? TYPE_OPTIONS
            : item.field === 'statuses'
            ? STATUSES_OPTIONS
            : item.options,
      })),
    [clientsData],
  )

  const loadMore = useCallback(() => {
    fetchOngoingReportingList({
      loadMore: true,
      page: Math.ceil(ongoingReportingData?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [ongoingReportingData, orderBy, filters, fetchOngoingReportingList])

  const getRowStyle = useCallback((status: OngoingReportingStatus) => {
    switch (status) {
      case OngoingReportingStatus.NotStarted:
        return genericSs.notStarted
      case OngoingReportingStatus.Process:
        return genericSs.inProgress
      case OngoingReportingStatus.InReview:
        return genericSs.inProgress
      case OngoingReportingStatus.Verified:
        return genericSs.complete
      case OngoingReportingStatus.Uploaded:
        return genericSs.notStarted
      case OngoingReportingStatus.Archived:
        return genericSs.notStarted
      default:
        return ''
    }
  }, [])

  const totals = useMemo(() => ongoingReportingData?.totals, [ongoingReportingData])

  const dashboardHeader: IHeaderItem[] = useMemo(
    () => [
      {
        title: 'Pending Reports',
        type: 'number',
        value: totals?.reportsPending || 0,
      },
      {
        title: 'Reports Completed Today',
        type: 'number',
        value: totals?.reportsCompletedToday || 0,
      },
      {
        title: 'Reports Submitted Today',
        type: 'number',
        value: totals?.reportsSubmittedToday || 0,
      },
    ],
    [totals],
  )

  return (
    <Card noHeaderMargin withBorder={false} noPadding>
      <DashboardHeader headerItems={dashboardHeader} />
      <TableContainer className={styles.table}>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
            />
          )}
        />
        <Table>
          <TableHead>
            <TableFiltersRow
              filters={filtersConfig}
              orderBy={orderBy}
              handleOrderChange={handleOrderChange}
            />
          </TableHead>
          <TableBody id="scrollableTableReporting">
            {isLoading ? (
              <TableLoader columnsCount={7} height={32} />
            ) : (
              ongoingReportingData?.data &&
              ongoingReportingData.data.length > 0 && (
                <InfiniteScroll
                  dataLength={ongoingReportingData?.data.length}
                  next={loadMore}
                  hasMore={
                    ongoingReportingData?.data.length < ongoingReportingData?.totals.totalItems
                  }
                  loader={<TableLoader columnsCount={7} height={36} rowsCount={1} />}
                  scrollableTarget="scrollableTableReporting"
                >
                  {ongoingReportingData.data.map((item, index) => (
                    <TableRow key={item.id}>
                      <TableCell className={genericSs.tableTextLeft}>
                        <Link
                          component={RouterLink}
                          to={generatePath(ROUTES.CLIENT_PAGE, { id: item.clientInfo.id })}
                        >
                          {item.clientName}
                        </Link>
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>
                        {[
                          REPORTING_DOCUMENT_TYPES.financials,
                          REPORTING_DOCUMENT_TYPES.financialProjections,
                          REPORTING_DOCUMENT_TYPES.salesBySKU,
                          REPORTING_DOCUMENT_TYPES.arGeneralLedger,
                          REPORTING_DOCUMENT_TYPES.bankTransactions,
                          REPORTING_DOCUMENT_TYPES.capTable,
                        ].includes(item.type) ? (
                          <Link
                            className={styles.link}
                            component={RouterLink}
                            to={getLink(item)}
                            onClick={() => handleClick(item)}
                          >
                            {item.type === REPORTING_DOCUMENT_TYPES.arGeneralLedger
                              ? 'Dilution'
                              : item.type}
                          </Link>
                        ) : (
                          <Link target="_blank" href={item.boxLink}>
                            {item.type}
                          </Link>
                        )}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item.daysSinceDue}
                      </TableCell>

                      <TableCell className={genericSs.tableTextRight}>
                        {item.asOfDate ? (
                          [
                            REPORTING_DOCUMENT_TYPES.financials,
                            REPORTING_DOCUMENT_TYPES.salesBySKU,
                          ].includes(item.type) ? (
                            <span> {formatDateShort(item.asOfDate)}</span>
                          ) : (
                            <span> {formatDate(item.asOfDate)}</span>
                          )
                        ) : (
                          '-'
                        )}
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <Tooltip title={formatDateTime(item.submittedDate)}>
                          <span> {formatDateCalendarNoTime(item.submittedDate)}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell className={genericSs.tableTextLeft}>
                        <span className={cn(styles.reportingStatus, getRowStyle(item.status))}>
                          {item.preProcessed
                            ? REPORTING_STATUS_LABEL[item.status]
                            : 'Pre-Processing'}
                        </span>
                      </TableCell>
                      <TableCell className={genericSs.tableTextRight}>
                        <OngoingReportingActions
                          item={item}
                          itemLink={getLink(item)}
                          handleClick={handleClick}
                          refetchOngoingReportingList={refetchOngoingReportingList}
                          deleteOngoingReporting={deleteOngoingReporting}
                          isLoading={isLoading}
                          updateOngoingReportingStatus={updateOngoingReportingStatus}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </InfiniteScroll>
              )
            )}
          </TableBody>
        </Table>
        <Grid container justifyContent={'flex-end'}>
          <SaveState isSaving={isSaving} isSaved={isSaved} />
        </Grid>
      </TableContainer>
    </Card>
  )
}

export default AnalysisQueueTable
