import React, { useCallback, useMemo, useState } from 'react'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import queryString from 'query-string'
import cn from 'classnames'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import { Form, FormSpy } from 'react-final-form'

import styles from './ActivityQueuePage.module.scss'
import genericSs from '@styles/generic.module.scss'

import {
  ACTIVITY_TYPE_LABEL,
  ActivityStatus,
  ActivityType,
  IActivity,
} from '@common/interfaces/activity'
import { formatPrice, formatTodayTimeOrDate, handleStopPropagation } from '../../helpers/helpers'
import { ReactComponent as WarningIconOutline } from '@assets/images/warning-icon-outline.svg'
import { ROUTES } from '../../constants/routes'
import { FundingRequestAmountType, FundingRequestStatus } from '@common/interfaces/bbc'
import SelectField, { IOption } from '../../components/Common/SelectField'
import TableRow from '../../components/Common/TableRow'
import TableCell from '../../components/Common/TableCell'
import { BoxSignStatus } from '@common/interfaces/box'
import { MenuIcon } from '../../components/Common/Icons'
import { ClientInfoStatus } from '@common/interfaces/client'

const signatureStatus = {
  [BoxSignStatus.converting]: 'Creating Document',
  [BoxSignStatus.created]: 'Send for Signature',
  [BoxSignStatus.sent]: 'Awaiting Signature',
  [BoxSignStatus.viewed]: 'Awaiting Signature (Viewed)',
  [BoxSignStatus.signed]: 'Ready to Fund',
  [BoxSignStatus.cancelled]: 'Cancelled',
  [BoxSignStatus.declined]: 'Declined',
  [BoxSignStatus.expired]: 'Expired',
  [BoxSignStatus.error_converting]: 'Error',
  [BoxSignStatus.error_sending]: 'Error',
}

const ActivityQueueRowAction = ({
  isAdmin,
  activity,
  onMoveBackActivity,
  onDelete,
  onSendBBCSignReminder,
}: {
  isAdmin: boolean
  activity: IActivity
  onMoveBackActivity: () => void
  onDelete: () => void
  onSendBBCSignReminder: () => void
}) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleMoveBack = useCallback(
    (event) => {
      event.stopPropagation()

      setAnchorEl(null)

      if (
        [ActivityStatus.Review, ActivityStatus.Complete].includes(activity.status) &&
        activity.type !== ActivityType.MidDayWire
      ) {
        onMoveBackActivity()
      }
    },
    [activity, onMoveBackActivity],
  )

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation()

      setAnchorEl(null)

      if (isAdmin) {
        onDelete()
      }
    },
    [onDelete, isAdmin],
  )

  const isBBCSignReminderAvailable = useMemo(
    () =>
      activity.type === ActivityType.Funding &&
      activity.status === ActivityStatus.Complete &&
      [BoxSignStatus.sent, BoxSignStatus.viewed].includes(
        activity?.borrowingBase?.files?.find((file) => file.type === 'Summary_Signed')
          ?.boxSignStatus as BoxSignStatus,
      ),
    [activity],
  )

  const handleSendBBCSignReminder = useCallback(
    (event) => {
      event.stopPropagation()

      setAnchorEl(null)

      onSendBBCSignReminder()
    },
    [onSendBBCSignReminder],
  )

  if (
    !isAdmin &&
    !(
      [ActivityStatus.Review, ActivityStatus.Complete].includes(activity.status) &&
      activity.type !== ActivityType.MidDayWire
    )
  ) {
    return null
  }

  return (
    <>
      <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} size="small" />
      <Menu
        classes={{ paper: styles.iconMenu }}
        open={isActionsMenuOpen}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
      >
        {isAdmin && (
          <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleDelete}>
            Delete
          </MenuItem>
        )}
        {[ActivityStatus.Review, ActivityStatus.Complete].includes(activity.status) &&
          ![ActivityType.MidDayWire, ActivityType.TestBBC].includes(activity.type) && (
            <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleMoveBack}>
              Move Back
            </MenuItem>
          )}
        {isBBCSignReminderAvailable && (
          <MenuItem classes={{ root: styles.iconMenuItem }} onClick={handleSendBBCSignReminder}>
            Send Reminder
          </MenuItem>
        )}
      </Menu>
    </>
  )
}

const ActivityAmountLabel = ({ activity }: { activity: IActivity }) => {
  if (!activity.borrowingBase) {
    return activity.totalCollections || activity.totalCollections === 0 ? (
      <span>${formatPrice(activity.totalCollections)}</span>
    ) : (
      <span>-</span>
    )
  }

  if (activity.borrowingBase.status === FundingRequestStatus.Sent) {
    return (
      <span>
        $
        {formatPrice(activity.borrowingBase.fundings?.reduce((sum, { amount }) => sum + amount, 0))}
      </span>
    )
  }
  if (activity.borrowingBase.requestedAmountType === FundingRequestAmountType.Specific) {
    return <span>${formatPrice(activity.borrowingBase.requestedAmount)}</span>
  }

  return <span>{activity.borrowingBase.requestedAmountType}</span>
}

interface IProps {
  activity: IActivity
  isAdmin: boolean
  isPortfolioAdmin: boolean
  currentUserId: string
  portfolioUsers: IOption[]
  portfolioAdminUsers: IOption[]
  allUsers: IOption[]
  onSaveActivity: (id: string, data: object) => void
  onDeleteActivity: (id: string) => void
  onSendBBCSignReminder: (id: string) => void
}

const ActivityQueueRow = ({
  activity,
  isAdmin,
  isPortfolioAdmin,
  currentUserId,
  portfolioUsers,
  portfolioAdminUsers,
  allUsers,
  onSaveActivity,
  onDeleteActivity,
  onSendBBCSignReminder,
}: IProps) => {
  const isBBCNotProcessed = useMemo(
    () =>
      [ActivityType.Funding, ActivityType.TestBBC].includes(activity.type) &&
      (!activity.borrowingBase.preProcessed ||
        activity.borrowingBase.postProcessing ||
        activity.borrowingBase.isBlocked),
    [activity],
  )
  const isAllowedToProcess = useMemo(() => {
    if (isBBCNotProcessed) {
      return false
    }
    if (isAdmin) {
      return true
    }
    if (activity.status === ActivityStatus.Review) {
      if (activity.reviewerId !== currentUserId) {
        return false
      }
    } else {
      if (
        ActivityStatus.Complete !== activity.status &&
        !isPortfolioAdmin &&
        activity.processorId !== currentUserId
      ) {
        return false
      }
    }

    return true
  }, [activity, isAdmin, isPortfolioAdmin, currentUserId, isBBCNotProcessed])

  const itemLink = useMemo(() => {
    if (!isAllowedToProcess) {
      return
    }
    let path
    if ([ActivityType.Check, ActivityType.Wire, ActivityType.MidDayWire].includes(activity.type)) {
      if ([ActivityStatus.Complete, ActivityStatus.Archived].includes(activity.status)) {
        path = `${generatePath(ROUTES.COLLECTIONS_DASHBOARD)}?${queryString.stringify({
          type: activity.type === ActivityType.Check ? 'check' : 'wire',
          activityId: activity.id,
        })}`
      } else {
        path = `${generatePath(
          activity.type === ActivityType.Check
            ? ROUTES.COLLECTIONS_CHECKS
            : activity.type === ActivityType.Wire
            ? ROUTES.COLLECTIONS_WIRES
            : ROUTES.COLLECTIONS_MID_DAY_WIRES,
        )}?${queryString.stringify({
          activityId: activity.id,
          recordDate: activity.recordDate,
        })}`
      }
    } else if ([ActivityType.Funding, ActivityType.TestBBC].includes(activity.type)) {
      if ([ActivityStatus.NotStarted, ActivityStatus.InProgress].includes(activity.status)) {
        path = generatePath(ROUTES.BBC_SUMMARY, { id: activity.borrowingBase?.id })
      } else if (
        isAdmin ||
        [ActivityStatus.Complete, ActivityStatus.Archived].includes(activity.status) ||
        (activity.status === ActivityStatus.Review && isPortfolioAdmin)
      ) {
        path = generatePath(ROUTES.BBC_SUMMARY, { id: activity.borrowingBase?.id })
      }
    }

    return path
  }, [activity, isAllowedToProcess, isAdmin, isPortfolioAdmin])

  const clientLink = useMemo(
    () =>
      activity.clientInfo ? generatePath(ROUTES.CLIENT_PAGE, { id: activity.clientInfo.id }) : null,
    [activity],
  )

  const onSave = useCallback(
    (values) => {
      const { id, ...updates } = values
      onSaveActivity(id, updates)
    },
    [onSaveActivity],
  )

  const onMoveBackActivity = useCallback(() => {
    onSaveActivity(activity.id, {
      status:
        activity.status === ActivityStatus.Complete
          ? ActivityStatus.Review
          : ActivityStatus.InProgress,
    })
  }, [activity, onSaveActivity])

  const onDelete = useCallback(() => {
    onDeleteActivity(activity.id)
  }, [activity, onDeleteActivity])

  const data = useMemo(
    () =>
      activity
        ? {
            id: activity.id,
            processor: activity.processor?.id,
            reviewer: activity.reviewer?.id,
          }
        : null,
    [activity],
  )

  const showOpsUser = useMemo(
    () => [ActivityStatus.NotStarted, ActivityStatus.InProgress].includes(activity.status),
    [activity],
  )

  const status = useMemo(() => {
    if (
      activity.type !== ActivityType.Funding ||
      activity.status !== ActivityStatus.Complete ||
      activity.borrowingBase?.status === FundingRequestStatus.Sent
    ) {
      return activity.status
    }

    const boxSignStatus = activity?.borrowingBase?.files?.find(
      (file) => file.type === 'Summary_Signed',
    )?.boxSignStatus as BoxSignStatus

    return signatureStatus[boxSignStatus] || 'Manually Send'
  }, [activity])

  const isComplete = useMemo(() => {
    if (activity.type === ActivityType.Funding) {
      return (
        activity.status === ActivityStatus.Complete &&
        activity.borrowingBase?.status === FundingRequestStatus.Sent
      )
    }

    return activity.status === ActivityStatus.Complete
  }, [activity])

  const statusStyle = useMemo(() => {
    if (isComplete) {
      return genericSs.complete
    }
    switch (activity.status) {
      case ActivityStatus.NotStarted:
        return genericSs.notStarted
      case ActivityStatus.InProgress:
      case ActivityStatus.Review:
        return genericSs.inProgress
      default:
        return genericSs.blue
    }
  }, [activity, isComplete])

  const handleClickOnStatus = useCallback(() => {
    if (activity.type === ActivityType.Funding && activity?.borrowingBase?.boxLink) {
      window.open(activity?.borrowingBase?.boxLink, '_blank')
    }
  }, [activity])

  const handleSendBBCSignReminder = useCallback(() => {
    if (activity.type !== ActivityType.Funding) {
      return
    }

    onSendBBCSignReminder(activity.borrowingBase?.id)
  }, [activity, onSendBBCSignReminder])

  const title = useMemo(() => {
    if (activity.type === ActivityType.Funding) {
      if (activity.clientInfo.clientStatus === ClientInfoStatus.DueDiligence) {
        return 'First Funding'
      }
      return 'Funding'
    }
    if (activity.type === ActivityType.TestBBC) {
      return 'Test BBC'
    }
    return 'Collections'
  }, [activity])

  return (
    <Form
      initialValues={data}
      onSubmit={onSave}
      render={({ handleSubmit }) => (
        <TableRow
          className={cn({
            [styles.activityQueueRowDisabled]: !isAllowedToProcess,
            [styles.activityQueueRowDisabledBBC]: isBBCNotProcessed,
          })}
        >
          <TableCell className={genericSs.tableTextLeft}>
            <Link component={RouterLink} to={itemLink} className={styles.activityQueueRowLink}>
              {title}
            </Link>
            {activity.type === ActivityType.Funding && activity.borrowingBase.isBlocked && (
              <Tooltip
                placement="top"
                title="BBC pre-processing was skipped. Please complete the existing test BBC first"
              >
                <WarningIconOutline className={styles.activityBBCBlockedIcon} />
              </Tooltip>
            )}
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>
            {[ActivityType.Funding, ActivityType.TestBBC].includes(activity.type) ? (
              <Link component={RouterLink} to={clientLink} className={styles.activityQueueRowLink}>
                {activity.clientName}
              </Link>
            ) : (
              ACTIVITY_TYPE_LABEL[activity.type]
            )}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            <ActivityAmountLabel activity={activity} />
          </TableCell>
          <TableCell className={genericSs.tableTextLeft}>
            <div onClick={handleStopPropagation}>
              {isComplete ? (
                <SelectField
                  name="processor"
                  options={allUsers}
                  style={{ width: '100%' }}
                  disabled={isComplete}
                  placeholder="---"
                />
              ) : showOpsUser ? (
                <SelectField
                  name="processor"
                  options={portfolioUsers}
                  style={{ width: '100%' }}
                  placeholder="---"
                />
              ) : (
                <SelectField
                  name="reviewer"
                  options={portfolioAdminUsers}
                  style={{ width: '100%' }}
                  placeholder="---"
                />
              )}
            </div>
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            {formatTodayTimeOrDate(activity.createdAt)}
          </TableCell>
          <TableCell className={genericSs.tableTextRight}>
            {isComplete ? formatTodayTimeOrDate(activity.completedAt) : '-'}
          </TableCell>
          <TableCell className={genericSs.tableTextLeft} onClick={handleClickOnStatus}>
            <div
              className={cn(styles.status, statusStyle, {
                [genericSs.cursorPointer]:
                  activity.type === ActivityType.Funding && activity?.borrowingBase?.boxLink,
              })}
            >
              {status}
            </div>
          </TableCell>

          <TableCell className={genericSs.tableTextRight}>
            <ActivityQueueRowAction
              activity={activity}
              isAdmin={isAdmin || isPortfolioAdmin}
              onMoveBackActivity={onMoveBackActivity}
              onDelete={onDelete}
              onSendBBCSignReminder={handleSendBBCSignReminder}
            />
          </TableCell>

          <FormSpy
            subscription={{ dirty: true, values: true }}
            onChange={(props) => {
              props.dirty && handleSubmit(props.values)
            }}
          />
        </TableRow>
      )}
    />
  )
}

export default ActivityQueueRow
