import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import arrayMutators from 'final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import Box from '@mui/material/Box'
import moment from 'moment'
import styles from './ClientOveradvances.module.scss'
import { IOveradvance } from '@common/interfaces/bbc'
import Button from '../Common/Button'
import { ReactComponent as DeleteIcon } from '../../assets/images/delete-icon.svg'
import Card from '../Common/Card'
import { dateToString, debounceEventHandler, formatPrice } from '../../helpers/helpers'
import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import { ClientOveradvanceField, IClientOveradvanceField } from '../Client/ClientHelpers'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import IconButton from '@mui/material/IconButton'
import { isEqual, omit } from 'lodash'
import { Grid } from '@mui/material'
import RouteLeavingGuard from '../Common/RouteLeavingGuard'
import { useHistory } from 'react-router'
import WarningModal from '../WarningModal'
import { ReactComponent as EmptyIcon } from '../../assets/images/empty-page-icon.svg'
import Modal from '../Common/Modal/Modal'
import TextField from '../Common/TextField/TextField'
import { ReactComponent as EditIcon } from '../../assets/images/edit-icon.svg'
import cn from 'classnames'
const schema = Yup.object().shape({
  overadvances: Yup.array().of(
    Yup.object().shape({
      overadvance: Yup.number()
        .typeError('Invalid number')
        .min(0, 'Invalid number')
        .required('Required'),
      startDate: Yup.date().typeError('Please type date in MM/DD/YY format').required('Required'),
      endDate: Yup.date()
        .typeError('Please type date in MM/DD/YY format')
        .when('startDate', (startDate: any, validation: any) =>
          startDate && startDate instanceof Date && !isNaN(+startDate)
            ? validation.min(startDate, 'End date should be prior to the from date')
            : validation,
        ),
      fee: Yup.number().typeError('Invalid number').nullable().min(0, 'Must be positive number'),
      floor: Yup.number()
        .typeError('Invalid number')
        .min(0, 'Must be positive number')
        .required('Required'),
      marginAbovePrime: Yup.number()
        .typeError('Invalid number')
        .min(0, 'Must be positive number')
        .required('Required'),
    }),
  ),
})
const validate = makeValidate(schema)

export const overadvanceFieldConfig: IClientOveradvanceField[] = [
  {
    label: 'Overadvance',
    name: 'overadvance',
    type: 'currency',
  },
  {
    label: 'Current balance',
    name: 'currentBalance',
    type: 'currency',
    disabled: true,
  },
  {
    label: 'Start date',
    name: 'startDate',
    type: 'date',
  },
  {
    label: 'End date',
    name: 'endDate',
    type: 'date',
  },
  {
    label: 'Fee',
    name: 'fee',
    type: 'currency',
  },
  {
    label: 'Rate',
    name: 'rate',
    type: 'percent',
    disabled: true,
  },
  {
    label: 'Floor',
    name: 'floor',
    type: 'percent',
  },
  {
    label: 'Margin above prime',
    name: 'marginAbovePrime',
    type: 'percent',
  },
  {
    label: 'Purpose',
    name: 'purpose',
    type: 'text',
  },
]

const mutators = {
  ...arrayMutators,
}

const getOveradvanceTitle = (overadvance: IOveradvance) => {
  const { overadvance: overadvanceAmount, startDate } = overadvance
  if (overadvanceAmount && startDate) {
    return `$${formatPrice(overadvanceAmount)} (${startDate})`
  }
  return 'Default'
}

const checkInvalid = (item: any) => {
  const { overadvance, startDate, endDate, floor, marginAbovePrime } = item
  if (
    !overadvance ||
    !startDate ||
    (!!endDate &&
      moment(startDate)
        .startOf('day')
        .isAfter(moment(endDate || null).startOf('day'))) ||
    !floor ||
    !marginAbovePrime
  ) {
    return true
  }
  return false
}
interface IOveradvancesProps {
  clientId: string
  clientInfo: IClientInfo
  isAdminRightsRole: boolean
  overadvances: IOveradvance[]
  listOveradvances: (id: string, params: any) => void
  createOveradvance: (id: string, data: object) => void
  updateOveradvance: (clientId: string, overadvanceId: string, data: object) => void
  deleteOveradvance: (clientId: string, overadvanceId: string) => void
  isLoading: boolean
}

const ClientOveradvances = ({
  isLoading,
  clientId,
  clientInfo,
  isAdminRightsRole,
  overadvances,
  listOveradvances,
  createOveradvance,
  updateOveradvance,
  deleteOveradvance,
}: IOveradvancesProps) => {
  const [overadvancesList, setOveradvancesList] = useState<IOveradvance[]>([])

  useEffect(() => {
    setOveradvancesList(overadvances)
  }, [overadvances])

  const debounceListOveradvances = useMemo(
    () =>
      debounceEventHandler(() => {
        listOveradvances(clientId, {})
      }, 500),
    [clientId, listOveradvances],
  )

  useEffect(() => {
    debounceListOveradvances()
  }, [debounceListOveradvances])

  const isActiveClient = useMemo(
    () =>
      [ClientInfoStatus.Current, ClientInfoStatus.DueDiligence].includes(clientInfo?.clientStatus),
    [clientInfo],
  )

  const templatePriority = useMemo(
    () => Math.max(...overadvancesList.map((overadvance) => overadvance.priority), 0) + 1,
    [overadvancesList],
  )

  const overadvanceTemplate = useMemo(
    () => ({
      overadvance: null,
      startDate: null,
      endDate: null,
      fee: null,
      rate: null,
      floor: clientInfo?.interestRateBase,
      marginAbovePrime: clientInfo?.interestRateAbovePrime,
      purpose: null,
      clientName: clientInfo?.clientName,
      id: null,
      priority: templatePriority,
    }),
    [clientInfo, templatePriority],
  )
  const handleAddOveradvance = useCallback(() => {
    setOveradvancesList((prev) => [overadvanceTemplate, ...prev])
  }, [overadvanceTemplate])

  const handleRemoveOveradvance = useCallback(
    (index: number) => {
      setOveradvancesList((prev) => {
        const newOveradvances = [...prev]
        newOveradvances.splice(index, 1)
        return newOveradvances
      })
    },
    [setOveradvancesList],
  )

  if (!isLoading && overadvancesList.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
        <Grid container spacing={2} justifyContent={'center'}>
          <Grid container item xs={12} justifyContent={'center'}>
            <EmptyIcon />
          </Grid>
          <Grid container item xs={12} justifyContent={'center'}>
            <h3>No overadvances exist for {clientInfo?.clientName}</h3>
          </Grid>
          <Grid container item xs={12} justifyContent={'center'}>
            <Button
              className={styles.addButton}
              type="button"
              color="primary"
              variant="contained"
              small={false}
              disabled={!isActiveClient}
              onClick={handleAddOveradvance}
            >
              Setup overadvance
            </Button>
          </Grid>
        </Grid>
      </Box>
    )
  }

  return (
    <Card
      withBorder={false}
      noHeaderMargin
      title={
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <span>Overadvances</span>
          <Box display="flex" alignItems="center" justifyContent={'space-between'}>
            <EditPriority
              overadvances={overadvances}
              clientId={clientId}
              updateOveradvance={updateOveradvance}
              isActiveClient={isActiveClient}
              overadvancesList={overadvancesList}
            />

            <Button
              className={styles.button}
              type="button"
              color="primary"
              variant="outlined"
              small={false}
              disabled={!isActiveClient}
              onClick={handleAddOveradvance}
            >
              + Overadvance
            </Button>
          </Box>
        </Box>
      }
    >
      <Grid container spacing={2} mt={2}>
        {overadvancesList.map((overadvance: IOveradvance, index: number) => (
          <Grid item xs={12}>
            <ClientOveradvance
              key={overadvance.id || index}
              clientId={clientId}
              isAdminRightsRole={isAdminRightsRole}
              overadvance={overadvance}
              createOveradvance={createOveradvance}
              updateOveradvance={updateOveradvance}
              deleteOveradvance={deleteOveradvance}
              removeOveradvance={() => handleRemoveOveradvance(index)}
              index={index}
            />
          </Grid>
        ))}
      </Grid>
    </Card>
  )
}

interface IOveradvanceProps {
  clientId: string
  isAdminRightsRole: boolean
  overadvance: IOveradvance
  createOveradvance: (id: string, data: object) => void
  updateOveradvance: (clientId: string, overadvanceId: string, data: object) => void
  deleteOveradvance: (clientId: string, overadvanceId: string) => void
  removeOveradvance: () => void
  index: number
}

const ClientOveradvance = ({
  clientId,
  isAdminRightsRole,
  overadvance,
  createOveradvance,
  updateOveradvance,
  deleteOveradvance,
  removeOveradvance,
  index,
}: IOveradvanceProps) => {
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [deleteCallback, setDeleteCallback] = useState(null)

  const history = useHistory()

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const isNew = useMemo(() => !overadvance?.id, [overadvance])

  const isEdit = useMemo(
    () =>
      !!overadvance?.id &&
      (!overadvance.endDate || moment().isBefore(moment(overadvance.endDate).endOf('month'))),
    [overadvance],
  )

  const isDisabled = useMemo(
    () => !isAdminRightsRole || (!isNew && !isEdit && false && true),
    [isAdminRightsRole, isNew, isEdit],
  )

  const handleSaveOveradvance = useCallback(
    async (value: IOveradvance) => {
      const { id, startDate, endDate, fee } = value
      const data = {
        ...value,
        startDate: typeof startDate === 'string' ? startDate : dateToString(startDate),
        endDate: !endDate || typeof endDate === 'string' ? endDate : dateToString(endDate),
        fee: fee || null,
      }
      //remove id from data
      delete data.id

      if (isNew) {
        await createOveradvance(clientId, {
          ...data,
          id: clientId,
        })
      } else {
        await updateOveradvance(clientId, id, data)
      }
    },
    [createOveradvance, updateOveradvance, clientId, isNew],
  )

  const handleDeleteOveradvance = useCallback(async () => {
    if (isNew) {
      removeOveradvance()
    } else {
      setIsDeleteModalShown(true)
      setDeleteCallback(() => async () => {
        await deleteOveradvance(clientId, overadvance.id)
        removeOveradvance()
      })
    }
  }, [clientId, deleteOveradvance, isNew, overadvance, removeOveradvance])

  const handleDeleteOveradvanceConfirm = useCallback(async () => {
    deleteCallback && (await deleteCallback())
    setDeleteCallback(null)
    setIsDeleteModalShown(false)
  }, [deleteCallback])

  const initialValues = useMemo(() => overadvance, [overadvance])

  const overadvanceTitle = useMemo(() => getOveradvanceTitle(overadvance), [overadvance])

  const [isExpanded, setIsExpanded] = useState(false)

  useEffect(() => {
    setIsExpanded(index === 0)
  }, [index])

  const handleExpandClick = useCallback(() => {
    setIsExpanded(!isExpanded)
  }, [isExpanded])

  const isPaidOff = useMemo(() => overadvance.currentBalance === 0, [overadvance])

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleSaveOveradvance}
        validate={validate}
        mutators={mutators}
        render={({ values, form }: { form: any; values: any; handleSubmit: any }) => {
          const invalid = checkInvalid(values)
          const isChanged = !isEqual(
            omit(initialValues, ['currentBalance', 'rate']),
            omit(values, ['currentBalance', 'rate']),
          )

          return (
            <form id={overadvance.id || 'newOveradvance'}>
              <Card
                expandable
                setIsExpanded={setIsExpanded}
                isExpanded={isExpanded}
                className={styles.card}
                title={
                  <Box display="flex" justifyContent="space-between" alignItems="center">
                    <span>
                      Overadvance - {overadvanceTitle}
                      <IconButton onClick={handleExpandClick} disableFocusRipple disableRipple>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                      {isPaidOff && <span className={styles.paidOff}>Paid</span>}
                    </span>
                    <Box display="flex" alignItems="center">
                      {isAdminRightsRole && !isDisabled && (
                        <IconButton disableFocusRipple disableRipple>
                          <DeleteIcon onClick={() => handleDeleteOveradvance()} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                }
              >
                <Grid container spacing={2} justifyContent={'start'}>
                  {overadvanceFieldConfig.map((field) => (
                    <Grid item xs={3} xl={2} key={field.label}>
                      <ClientOveradvanceField
                        key={field.name}
                        name={field.name}
                        label={field.label}
                        type={field.type}
                        options={field.options}
                        disabled={isDisabled || field.disabled}
                      />
                    </Grid>
                  ))}
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      {!isDisabled && (
                        <Button
                          className={styles.deleteButton}
                          color="secondary"
                          variant="contained"
                          small={false}
                          disabled={invalid || !isChanged}
                          onClick={() => handleSaveOveradvance(values)}
                        >
                          Save changes
                        </Button>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Card>
              <RouteLeavingGuard
                when={isChanged}
                navigate={handleNavigate}
                shouldBlockNavigation={() => isChanged}
                helperText={`You have unsaved changes for ${overadvanceTitle}. If you leave before saving, your changes will be lost.`}
                buttonText="Save changes"
                alternateSubmit={() => handleSaveOveradvance(values)}
                isAlternateSubmitInvalid={form.getState().invalid}
              />
            </form>
          )
        }}
      />

      {isDeleteModalShown && (
        <WarningModal
          warningMessage="Overadvance will be deleted."
          onConfirm={handleDeleteOveradvanceConfirm}
          onCancel={() => setIsDeleteModalShown(false)}
          confirmText="Delete"
          cancelText="Cancel"
        />
      )}
    </>
  )
}

interface IEditPriority {
  overadvances: IOveradvance[]
  updateOveradvance: (clientId: string, overadvanceId: string, data: object) => void
  overadvancesList: IOveradvance[]
  clientId: string
  isActiveClient: boolean
}

const EditPriority = ({
  overadvances,
  updateOveradvance,
  overadvancesList,
  clientId,
  isActiveClient,
}: IEditPriority) => {
  const [isEditPriorityOpen, setIsEditPriorityOpen] = useState(false)

  const [updatedPriorities, setUpdatedPriorities] = useState<
    {
      id: string
      priority: number
      title: string
    }[]
  >([])

  const initialPriorities = useMemo(
    () =>
      overadvances
        ?.filter(
          (overadvance) =>
            !(moment(overadvance.endDate) < moment() || overadvance.currentBalance === 0),
        )
        .map((overadvance) => ({
          id: overadvance.id,
          priority: overadvance.priority,
          title: getOveradvanceTitle(overadvance),
        })),
    [overadvances],
  )

  useEffect(() => {
    initialPriorities && setUpdatedPriorities(initialPriorities)
  }, [initialPriorities])

  const handleUpdatePriority = useCallback(
    (id: string, priority: string) => {
      setUpdatedPriorities((prev) => {
        const newPriorities = [...prev]
        const index = newPriorities.findIndex((item) => item.id === id)
        if (index !== -1) {
          newPriorities[index].priority = Number(priority)
        }
        return newPriorities
      })
    },
    [setUpdatedPriorities],
  )

  const handleCloseEditPriority = useCallback(() => {
    setIsEditPriorityOpen(false)
    setUpdatedPriorities(initialPriorities)
  }, [setIsEditPriorityOpen, setUpdatedPriorities, initialPriorities])

  const handleOpenEditPriority = useCallback(() => {
    setIsEditPriorityOpen(true)
  }, [setIsEditPriorityOpen])

  const ablPriority = useMemo(() => {
    const priorities = updatedPriorities.map(({ priority }) => priority)
    if (priorities.length === 0) {
      return 1
    }
    const maxPriority = Math.max(...priorities)
    const minPriority = Math.min(...priorities)

    if (minPriority > 1) {
      return 1
    }
    if (maxPriority - minPriority + 1 === priorities.length) {
      return maxPriority + 1
    }
    for (let i = minPriority; i <= maxPriority; i++) {
      if (!priorities.includes(i)) {
        return i
      }
    }
    return 1
  }, [updatedPriorities])

  const handleSavePriorities = useCallback(() => {
    updatedPriorities
      .filter(
        ({ id, priority }) =>
          overadvances.find((overadvance) => overadvance.id === id)?.priority !== priority,
      )
      .forEach(({ id, priority }, index) => {
        const overadvance = overadvancesList.find((item) => item.id === id)
        if (overadvance) {
          delete overadvance.id
          updateOveradvance(clientId, id, { ...overadvance, priority, isSilent: index !== 0 })
        }
      })
    setUpdatedPriorities([])
    setIsEditPriorityOpen(false)
  }, [
    clientId,
    overadvancesList,
    setIsEditPriorityOpen,
    setUpdatedPriorities,
    updateOveradvance,
    updatedPriorities,
    overadvances,
  ])

  const priorityIdsWithTheSameValue = useMemo(() => {
    const priorities = updatedPriorities.map(({ priority }) => priority)
    const duplicatePriorities = priorities.filter(
      (item, index) => priorities.indexOf(item) !== index,
    )
    return updatedPriorities
      .filter(({ priority }) => duplicatePriorities.includes(priority))
      .map(({ id }) => id)
  }, [updatedPriorities])

  return (
    <>
      <Button
        type="button"
        color="primary"
        variant="outlined"
        small={false}
        disabled={!isActiveClient}
        onClick={handleOpenEditPriority}
        className={cn({
          [styles.editPriorityButton]: priorityIdsWithTheSameValue.length > 0,
        })}
      >
        <EditIcon
          className={cn(styles.buttonIcon, {
            [styles.buttonIconWarning]: priorityIdsWithTheSameValue.length > 0,
          })}
        />
        Edit priority
      </Button>

      {isEditPriorityOpen && (
        <Modal title="Edit priority" onCancel={handleCloseEditPriority} open size="small">
          <Grid container spacing={2} mt={2}>
            <Grid container item xs={12}>
              <Grid item xs={10}>
                <h3>ABL</h3>
              </Grid>
              <Grid item xs={2}>
                <TextField useFinalForm={false} type="number" value={ablPriority} disabled />
              </Grid>
            </Grid>

            {updatedPriorities.map(({ priority, id, title }) => {
              const duplicateValue = priorityIdsWithTheSameValue.includes(id)

              return (
                <Grid container item xs={12}>
                  <Grid item xs={10}>
                    <h3>Overadvance - {title}</h3>
                  </Grid>
                  <Grid item xs={2}>
                    <TextField
                      useFinalForm={false}
                      type="number"
                      value={priority}
                      onChange={(e) => handleUpdatePriority(id, e.target.value)}
                    />
                    {duplicateValue && (
                      <span className={styles.duplicateValue}>Duplicate priority</span>
                    )}
                  </Grid>
                </Grid>
              )
            })}
            <Grid container item xs={12} justifyContent={'flex-end'}>
              <Button
                className={styles.addButton}
                type="button"
                color="primary"
                variant="contained"
                small={false}
                disabled={!isActiveClient || priorityIdsWithTheSameValue.length > 0}
                onClick={handleSavePriorities}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Modal>
      )}
    </>
  )
}

export default ClientOveradvances
