import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { useLocation, useParams } from 'react-router'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import InfiniteScroll from 'react-infinite-scroll-component'
import Popover from '@mui/material/Popover'
import MenuItem from '@mui/material/MenuItem'
import { Form } from 'react-final-form'
import { EditorState, convertToRaw, ContentState } from 'draft-js'
import cn from 'classnames'
import { useDragDropManager } from 'react-dnd'

import styles from './NotesFullScreenModal.module.scss'

import { ReactComponent as SortIcon } from '@assets/images/sort-icon.svg'
import FullscreenModal from '../../Common/FullscreenModal'
import {
  INotesData,
  WorkflowTypes,
  Pages,
  DD_NOTES_FILTER_OPTIONS,
  GENERAL_NOTES_FILTER_OPTIONS,
  INote,
  NotesFilterOptions,
} from '@common/interfaces/notes'
import Card from '../../Common/Card/Card'
import { buildFiltersDefaults } from '../../../helpers/filters'
import {
  NOTES_LIST_FILTERS_CONFIG,
  NOTES_LIST_PER_PAGE,
  NOTES_LIST_SORT_OPTIONS,
} from '@common/constants/filters'
import { currentWorkFlow, debounceEventHandler } from '../../../helpers/helpers'
import Note from './../Note'
import AddButton from '../../../components/Client/AddButton'
import QuickFilters from './QuickFilters'
import { useAutoScroll } from '../../../hooks/useAutoScroll'
import FilterContainer from '../../Filters/FilterContainer'
import ExportButton from '../../Common/ExportButton'
import CustomDragLayer from './CustomDragLayer'

interface IProps {
  handleClose: (value: boolean) => void
  listNotes: (id: string, data: object) => void
  notesData: INotesData
  addNote: (params: object) => void
  exportNotes: (id: string, data: object) => void
  updateNote: (id: string, data: object) => void
}

const NotesFullScreenModal = ({
  handleClose,
  notesData,
  listNotes,
  addNote,
  exportNotes,
  updateNote,
}: IProps) => {
  const { pathname }: { search: string; pathname: string } = useLocation()
  const workflow = useMemo(() => currentWorkFlow(pathname), [pathname])

  const isDueDiligence = useMemo(() => workflow === WorkflowTypes.dueDiligencePage, [workflow])
  const isBDO = useMemo(() => workflow === WorkflowTypes.opsFlow, [workflow])
  const scrollContainerRef = useRef<HTMLDivElement>(null)

  const { updatePosition } = useAutoScroll(scrollContainerRef)
  const dragDropManager = useDragDropManager()
  const monitor = dragDropManager.getMonitor()

  const isDraggingRef = useRef(monitor.isDragging())

  useEffect(() => {
    const unsubscribe = monitor.subscribeToOffsetChange(() => {
      const offset = monitor.getSourceClientOffset()?.y as number
      updatePosition({ position: offset, isScrollAllowed: true })
      isDraggingRef.current = monitor.isDragging()
    })
    return unsubscribe
  }, [monitor, updatePosition])

  const filtersDefaults = useMemo(() => {
    return buildFiltersDefaults(
      NOTES_LIST_FILTERS_CONFIG,
      isDueDiligence
        ? {
            dueDiligenceFilters: [NotesFilterOptions.CallPrep],
          }
        : {
            pages: isBDO
              ? [Pages.ops]
              : [
                  Pages.bbc,
                  Pages.financials,
                  Pages.salesBySKU,
                  Pages.projections,
                  Pages.arGeneralLedger,
                  Pages.clientPage,
                  Pages.ops,
                  Pages.entity,
                ],
          },
    )
  }, [isDueDiligence, isBDO])

  const [filters, setFilters] = useState(filtersDefaults)
  const [orderBy, setOrderBy] = useState({
    field: isDueDiligence ? 'tag' : 'created_at',
    direction: 'DESC',
  })
  const totalCount = useMemo(() => notesData?.total, [notesData])
  const itemsCount = useMemo(() => notesData?.data?.length, [notesData])
  const noteTags = useMemo(() => notesData?.distinctTags, [notesData])
  const [isExportLoading, setIsExportLoading] = useState(false)
  const [sortAnchorEl, setSortAnchorEl] = useState<SVGSVGElement | null>(null)
  const isSortOpen = useMemo(() => Boolean(sortAnchorEl), [sortAnchorEl])

  const [isAutoRefresh, setIsAutoRefresh] = useState(true)
  const [notesList, setNotesList] = useState<INote[]>([])

  useEffect(() => {
    if (!isAutoRefresh) {
      setIsAutoRefresh(true)
    } else {
      setNotesList(notesData?.data || [])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notesData])

  const { id } = useParams<{ id: string }>()

  const statusFilterOptions = useMemo(() => {
    return isDueDiligence ? DD_NOTES_FILTER_OPTIONS : GENERAL_NOTES_FILTER_OPTIONS
  }, [isDueDiligence])

  const quickFilters = useMemo(() => {
    if (isDueDiligence) {
      return [...DD_NOTES_FILTER_OPTIONS, ...noteTags]
    } else {
      return GENERAL_NOTES_FILTER_OPTIONS
    }
  }, [isDueDiligence, noteTags])

  const filtersConfig = useMemo(
    () => [
      isDueDiligence
        ? {
            field: 'tags',
            type: 'list' as const,
            title: 'Tags',
            isMultiple: true,
            options: noteTags.map((value) => ({
              value,
              label: value,
            })),
          }
        : {
            field: 'pages',
            type: 'list' as const,
            title: 'Deal stage',
            isMultiple: true,
            options: (isBDO
              ? [Pages.ops]
              : [
                  Pages.bbc,
                  Pages.financials,
                  Pages.salesBySKU,
                  Pages.projections,
                  Pages.arGeneralLedger,
                  Pages.clientPage,
                  Pages.dueDiligence,
                  Pages.entity,
                  Pages.ops,
                ]
            ).map((value) => ({
              value,
              label: value,
            })),
          },
      {
        field: 'dueDiligenceFilters',
        type: 'list' as const,
        title: 'Status',
        isMultiple: true,
        options: statusFilterOptions
          .filter((filter) => filter !== NotesFilterOptions.All)
          .map((value) => ({
            value,
            label: value,
          })),
      },
    ],
    [isDueDiligence, isBDO, noteTags, statusFilterOptions],
  )

  const isCallAgenda = useMemo(() => {
    return (
      filters?.dueDiligenceFilters?.length === 1 &&
      filters?.dueDiligenceFilters?.includes(NotesFilterOptions.CallAgenda)
    )
  }, [filters?.dueDiligenceFilters])

  const debounceListNotes = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        if (isDueDiligence) {
          listNotes(id, {
            filters,
            orderBy: orderBy.field,
            orderDirection: orderBy.direction,
            workflow,
            page: 0,
            perPage: NOTES_LIST_PER_PAGE,
            skipLoader: true,
          })
        } else {
          listNotes(id, data)
        }
      }, 500),
    [id, listNotes, filters, orderBy, workflow, isDueDiligence],
  )

  useEffect(() => {
    if (id && workflow) {
      debounceListNotes({
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        workflow,
        page: 0,
        perPage: NOTES_LIST_PER_PAGE,
      })
    }
  }, [filters, debounceListNotes, id, orderBy, workflow])

  const isNoteDragReversed = useMemo(
    () => orderBy.field === 'drag_order' && orderBy.direction === 'DESC',
    [orderBy.field, orderBy.direction],
  )

  const onNoteDrop = useCallback(
    async (note, dragOrder) => {
      await updateNote(note?.id, {
        newDragOrder: isNoteDragReversed ? totalCount - dragOrder : dragOrder,
        sortDirection: orderBy.direction,
        dueDiligenceFilters: filters?.dueDiligenceFilters,
      })
    },
    [updateNote, isNoteDragReversed, totalCount, orderBy.direction, filters?.dueDiligenceFilters],
  )

  const moveNote = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const updatedNotes = [...notesList]
      const [draggedNote] = updatedNotes.splice(dragIndex, 1)
      updatedNotes.splice(hoverIndex, 0, draggedNote)
      setNotesList(updatedNotes)
    },
    [notesList],
  )

  const loadMore = useCallback(() => {
    const data = {
      loadMore: true,
      skipLoader: true,
      workflow,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      page: Math.ceil(itemsCount / NOTES_LIST_PER_PAGE),
      perPage: NOTES_LIST_PER_PAGE,
      filters,
    }
    workflow && listNotes(id, data)
  }, [id, listNotes, filters, orderBy, workflow, itemsCount])

  const handleExport = useCallback(async () => {
    setIsExportLoading(true)
    await exportNotes(id, {
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      workflow,
      page: 0,
      perPage: NOTES_LIST_PER_PAGE,
      skipLoader: true,
      isExport: true,
    })
    setIsExportLoading(false)
  }, [exportNotes, id, filters, orderBy, workflow])

  const openSort = useCallback((event: React.MouseEvent<SVGSVGElement>) => {
    setSortAnchorEl((anchorEl: SVGSVGElement) => (anchorEl ? null : event.currentTarget))
  }, [])

  const closeSort = useCallback(() => {
    setSortAnchorEl(null)
  }, [])

  const handleChangeOrderBy = useCallback(
    (value) => {
      closeSort()

      const [field, direction] = value.split(' ')
      setOrderBy({
        field,
        direction,
      })
      setSortAnchorEl(null)
    },
    [setOrderBy, closeSort],
  )

  const createNewNote = useCallback(async () => {
    const draftJSData = EditorState.createWithContent(ContentState.createFromText(''))
    const draftJSDataJSON = convertToRaw(draftJSData.getCurrentContent())
    await addNote({
      workflow,
      note: draftJSDataJSON,
      id,
      filters: filters,
      sortDirection: orderBy.direction,
      sortValue: orderBy.field,
    })
  }, [addNote, id, workflow, filters, orderBy])

  const fullScreenModalSortOptions = useMemo(() => {
    return [
      ...NOTES_LIST_SORT_OPTIONS,
      ...(isDueDiligence
        ? [
            {
              value: 'tag DESC',
              label: 'Tags (Standard)',
            },
            {
              value: 'tag ASC',
              label: 'Tags (Reverse)',
            },
          ]
        : []),
      ...(isCallAgenda
        ? [
            {
              value: 'drag_order ASC',
              label: 'Custom Order (Standard)',
            },
            {
              value: 'drag_order DESC',
              label: 'Custom Order (Reverse)',
            },
          ]
        : []),
    ]
  }, [isCallAgenda, isDueDiligence])

  return (
    <FullscreenModal
      isOpen
      setIsOpen={handleClose}
      disableEnforceFocus
      classes={{ body: styles.fullScreenModal }}
      showCloseIcon
    >
      <Card
        className={styles.notesContainer}
        key="notes"
        classes={{
          content: styles.notesCardContainer,
        }}
        withBorder={false}
        noHeaderMargin
      >
        <CustomDragLayer />

        <div
          className={cn(styles.quickFiltersContainer, {
            [styles.quickFiltersContainerDiligence]: isDueDiligence,
          })}
        >
          <div className={styles.modalTitle}>Notes</div>
          {quickFilters.map((filter) => (
            <QuickFilters
              filter={filter}
              filters={filters}
              setFilters={setFilters}
              key={filter}
              isDiligence={isDueDiligence}
              setOrderBy={setOrderBy}
            />
          ))}
        </div>
        <div className={styles.modal}>
          <div className={cn(styles.modalBody)}>
            <Box
              display="flex"
              alignContent="center"
              flexDirection="column"
              gap={2}
              className={styles.actionsContainer}
            >
              <Box display="flex" justifyContent="space-between" alignContent="center" gap={2}>
                <Box display="flex" flex={1} alignContent="center" gap={2}>
                  <div
                    className={cn(
                      styles.actionButtonContainer,
                      styles.actionButtonContainerFilterContainer,
                    )}
                  >
                    <Form
                      onSubmit={setFilters}
                      initialValues={filters}
                      mutators={{
                        setFieldData: ([field, value], state, { changeValue }) => {
                          changeValue(state, field, () => value)
                        },
                      }}
                      render={({ values, handleSubmit, form: { mutators } }) => (
                        <FilterContainer
                          mutators={mutators}
                          filters={filtersConfig}
                          handleSubmit={handleSubmit}
                          values={values}
                          appliedFilters={filters}
                          withFilterItems={false}
                          filtersSize={6}
                          actionsSize={0}
                          actionsInsideFilters
                          actions={
                            <div className={styles.actionButtonContainer}>
                              <Popover
                                open={isSortOpen}
                                anchorEl={sortAnchorEl}
                                onClose={closeSort}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'left',
                                }}
                                classes={{ paper: styles.sortMenuPopoverPaper }}
                              >
                                <div className={styles.sortMenu}>
                                  {fullScreenModalSortOptions.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      classes={{
                                        root: styles.sortMenuItem,
                                        selected: styles.sortMenuItemSelected,
                                      }}
                                      onClick={() => handleChangeOrderBy(option.value)}
                                      selected={
                                        `${orderBy.field} ${orderBy.direction}` === option.value
                                      }
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </div>
                              </Popover>

                              <SortIcon
                                className={cn(styles.button, {
                                  [styles.buttonActive]: isSortOpen,
                                })}
                                onClick={openSort}
                              />
                            </div>
                          }
                        />
                      )}
                    />
                  </div>
                </Box>
                <div className={styles.exportAddContainer}>
                  <div className={styles.actionButtonContainer}>
                    <AddButton variant="outlined" onClick={createNewNote}></AddButton>
                  </div>
                  <div className={styles.actionButtonContainer}>
                    <ExportButton isLoading={isExportLoading} handleExport={handleExport} />
                  </div>
                </div>
              </Box>
            </Box>

            <div id="scrollableDivModal" className={styles.notesList} ref={scrollContainerRef}>
              <InfiniteScroll
                className={styles.infiniteScroll}
                dataLength={itemsCount || 0}
                next={loadMore}
                hasMore={itemsCount < totalCount}
                loader={
                  <Box className={styles.circularLoader}>
                    <CircularProgress color="primary" />
                  </Box>
                }
                scrollableTarget="scrollableDivModal"
              >
                {notesList?.map((note, index) => (
                  <Note
                    index={index}
                    key={note.id}
                    note={note}
                    listNotes={debounceListNotes}
                    isFullScreen
                    setIsAutoRefresh={setIsAutoRefresh}
                    noteTags={noteTags}
                    setQuickFilters={setFilters}
                    moveNote={moveNote}
                    onNoteDrop={onNoteDrop}
                    isDraggable={isCallAgenda && orderBy.field === 'drag_order'}
                    orderBy={orderBy}
                    filters={filters}
                    isNoteBeingDragged={isDraggingRef.current}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </Card>
    </FullscreenModal>
  )
}

export default NotesFullScreenModal
