import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import InvestmentDetailRow from './InvestmentDetailRow'

import { investmentDetailRow } from '../../redux/entityInfo/selectors'
import { getInvestmentDetailRow } from '../../redux/entityInfo/actions'

const selector = createStructuredSelector({
  investmentDetailRow,
})

const actions = {
  getInvestmentDetailRow,
}

export default connect(selector, actions)(InvestmentDetailRow)
