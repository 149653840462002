import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import Box from '@mui/material/Box'
import moment from 'moment'

import styles from './BankTransactions.module.scss'

import { BANK_TRANSACTIONS_RAW_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { WorkflowTypes } from '@common/interfaces/notes'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import { REPORTING_PERIOD_OPTIONS, ReportingFlow, ReportingPeriods } from '@common/interfaces/bbc'
import { debounceEventHandler } from '../../helpers/helpers'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import FilterContainer from '../Filters/FilterContainer'
import { ILoadingData } from '../../redux/types'
import { ExpandAndMinimize } from '../Common/Icons'
import FullscreenModal from '../Common/FullscreenModal'
import DatePicker from '../Common/DatePicker'
import SelectField from '../Common/SelectField'
import useTable from '../../hooks/useTable'
import useGraphToggle from '../../hooks/useGraphTogggle'
import ExportButton from '../Common/ExportButton'
import {
  BANK_TRANSACTIONS_GRAPH_GROUP_BY_OPTIONS,
  BANK_TRANSACTIONS_GRAPH_OPTION,
  BANK_TRANSACTIONS_TABS,
  BankTransactionsGraphOption,
  CATEGORY_OPTIONS,
  IBankTransactionGraphData,
  IBankTransactionRawData,
} from '@common/interfaces/bankTransactions'
import BankTransactionsTable from './BankTransactionsTable'
import BankTransactionsGraph from './BankTransactionsGraph'
import BankTransactionsMapping from '../BankTransactionsMapping'
import Card from '../Common/Card'
import BankTransactionsMappingLegend from '../BankTransactionsMappingLegend'
import { Divider } from '@mui/material'
import Tabs from '../Common/Tabs'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'

const TABS = [BANK_TRANSACTIONS_TABS.DEBITS, BANK_TRANSACTIONS_TABS.CREDITS]

const filtersValidate = buildFiltersValidateSchema(BANK_TRANSACTIONS_RAW_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(BANK_TRANSACTIONS_RAW_LIST_FILTERS_CONFIG)

const datesBoundary: any = {
  minDate: null,
  maxDate: null,
}

const sortDefault = {
  field: 'record_date',
  direction: 'DESC' as const,
}

interface IProps {
  workflow: WorkflowTypes
  bankTransactions: ILoadingData<IBankTransactionRawData>
  bankTransactionsGraph: ILoadingData<IBankTransactionGraphData>
  exportBankTransactions: (params: object) => void
  listBankTransactions: (params: object) => void
  listBankTransactionsGraph: (params: object) => void
  hideBankTransactionsData: () => void
  listEntityInfo: (data: object) => Promise<{ data: IEntityInfo[] }>
  refreshCounter?: number
  clientName?: string
}

const BankTransactions = ({
  workflow,
  bankTransactions,
  bankTransactionsGraph,
  exportBankTransactions,
  listBankTransactions,
  listBankTransactionsGraph,
  hideBankTransactionsData,
  listEntityInfo,
  refreshCounter,
  clientName,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const [isInitialized, setIsInitialized] = useState(false)
  const [isModalShown, setIsModalShown] = useState(false)
  const [option, setOption] = useState<BankTransactionsGraphOption>(
    BANK_TRANSACTIONS_GRAPH_OPTION.Category,
  )
  const [reportingPeriod, setReportingPeriod] = useState<ReportingPeriods>(ReportingPeriods.Monthly)
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [isMappingShown, setIsMappingShown] = useState(false)
  const [tabValue, setTabValue] = useState<string>(BANK_TRANSACTIONS_TABS.DEBITS)
  const [isExportLoading, setIsExportLoading] = useState(false)

  const isClientPage = useMemo(() => workflow === WorkflowTypes.clientPage, [workflow])

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const {
    filters,
    orderBy,
    handleFiltersChange,
    handleOrderChange,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
  } = useTable({
    tableId: 'bankTransactions',
    filtersDefaults,
    sortDefault,
  })

  const { isLoading, bankTransactionsData, itemsCount, totalItems } = useMemo(
    () => ({
      isLoading: bankTransactions.isLoading,
      bankTransactionsData: bankTransactions.data?.data || [],
      itemsCount: bankTransactions.data?.data?.length || 0,
      totalItems: bankTransactions.data?.totals?.totalItems || 0,
    }),
    [bankTransactions],
  )

  const {
    isLoading: isLoadingGraph,
    bankTransactionsGraphData,
    bankTransactionsGraphHeader,
    bankTransactionsGraphOption,
  } = useMemo(
    () => ({
      isLoading: bankTransactionsGraph.isLoading,
      bankTransactionsGraphData: bankTransactionsGraph.data?.data || [],
      bankTransactionsGraphHeader: bankTransactionsGraph.data?.headers || [],
      bankTransactionsGraphOption: bankTransactionsGraph.data?.option,
    }),
    [bankTransactionsGraph],
  )

  useEffect(() => {
    if (!isInitialized && bankTransactions.data) {
      if (isClientPage) {
        setCurrentDateRange({
          startDate: moment().subtract(3, 'month').format('YYYY-MM-DD'),
          endDate: moment().format('YYYY-MM-DD'),
        })
      }
      setIsInitialized(true)
    }
  }, [isInitialized, bankTransactions, isClientPage])

  useEffect(() => {
    if (isClientPage) {
      setCurrentDateRange({
        startDate: moment()
          .subtract(isGraphShown ? 12 : 3, 'month')
          .format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
      })
    }
  }, [isGraphShown, isClientPage])

  const fetchBankTransactions = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        [isClientPage ? 'clientId' : 'ongoingReportingId']: id,
      }
      listBankTransactions(params)
    },
    [
      listBankTransactions,
      id,
      currentDateRange?.startDate,
      currentDateRange?.endDate,
      isClientPage,
    ],
  )

  const handleExport = useCallback(async () => {
    setIsExportLoading(true)
    await exportBankTransactions({
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      isExport: true,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    })
    setIsExportLoading(false)
  }, [exportBankTransactions, filters, orderBy, currentDateRange])

  const loadMore = useCallback(
    () =>
      fetchBankTransactions({
        loadMore: true,
        page: Math.ceil(itemsCount / PER_PAGE),
        perPage: Number(PER_PAGE),
        filters,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      }),
    [filters, orderBy, fetchBankTransactions, itemsCount],
  )

  const debounceListBankTransactions = useMemo(
    () => debounceEventHandler(fetchBankTransactions, 500),
    [fetchBankTransactions],
  )

  const fetchBankTransactionsGraph = useCallback(() => {
    const params = {
      [isClientPage ? 'clientId' : 'ongoingReportingId']: id,
      option,
      reportingPeriod,
      startDate: currentDateRange.startDate,
      endDate: currentDateRange.endDate,
    }
    listBankTransactionsGraph(params)
  }, [
    listBankTransactionsGraph,
    id,
    option,
    reportingPeriod,
    currentDateRange?.startDate,
    currentDateRange?.endDate,
    isClientPage,
  ])

  useEffect(() => {
    !isGraphShown &&
      debounceListBankTransactions({
        filters,
        page: 0,
        perPage: PER_PAGE,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
      })
  }, [isGraphShown, filters, orderBy, debounceListBankTransactions])

  useEffect(() => {
    isGraphShown && fetchBankTransactionsGraph()
  }, [isGraphShown, fetchBankTransactionsGraph])

  const refetchBankTransactions = useCallback(() => {
    fetchBankTransactions({
      page: 0,
      perPage: itemsCount,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, itemsCount, fetchBankTransactions])

  useEffect(() => {
    if (refreshCounter) {
      if (isGraphShown) {
        fetchBankTransactionsGraph()
      } else {
        refetchBankTransactions()
      }
    }
  }, [isGraphShown, refreshCounter, refetchBankTransactions, fetchBankTransactionsGraph])

  useEffect(() => {
    return () => {
      hideBankTransactionsData()
    }
  }, [hideBankTransactionsData])

  const handleModalToggle = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const handleOptionChange = useCallback(({ target: { value } }) => {
    setOption(value)
  }, [])

  const handleReportingPeriodChange = useCallback(({ target: { value } }) => {
    setReportingPeriod(value)
  }, [])

  const loadEntities = useCallback(
    async (inputValue: string) => {
      const res = await listEntityInfo({
        name: inputValue,
      })
      return res.data.map(({ name }) => ({
        value: name,
        label: name,
      }))
    },
    [listEntityInfo],
  )

  const filtersConfig = useMemo(
    () =>
      isGraphShown
        ? []
        : BANK_TRANSACTIONS_RAW_LIST_FILTERS_CONFIG.map((item) => ({
            ...item,
            options: item.field === 'category' ? CATEGORY_OPTIONS : item.options,
            loadOptions: ['entity_name', 'intermediary_name'].includes(item.field)
              ? loadEntities
              : undefined,
          })),
    [isGraphShown, loadEntities],
  )

  const handleShowMapping = useCallback(() => {
    setIsMappingShown(true)
  }, [])

  const handleCloseMappingModal = useCallback(() => {
    setIsMappingShown(false)
  }, [])

  const visualizationsParams = useMemo(
    () => ({
      [isClientPage ? 'clientId' : 'ongoingReportingId']: id,
    }),
    [isClientPage, id],
  )

  const visualizationsCategory = useMemo(
    () => (isGraphShown ? CATEGORIES.bankTransactionsChart : CATEGORIES.bankTransactionsTable),
    [isGraphShown],
  )

  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      latestSalesDate: currentDateRange.endDate,
      earliestSalesDate: currentDateRange.startDate,
      reportingPeriod: reportingPeriod,
      groupBy: isGraphShown ? option : undefined,
    }),
    [filters, currentDateRange, reportingPeriod, isGraphShown, option],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filtersConfig: filtersConfig,
    filters: visualizationsFilters,
  })

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: !!isGraphShown,
  })

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              title={
                <div className={styles.header}>
                  <div className={styles.title}>Bank Transactions</div>
                  {isClientPage && (
                    <div className={styles.editLink} onClick={handleShowMapping}>
                      Edit
                    </div>
                  )}
                </div>
              }
              withFullSearch={!isGraphShown}
              actions={
                <Box display="flex" alignItems="center" gap={1}>
                  {isGraphShown && (
                    <SelectField
                      useFinalForm={false}
                      name="option"
                      value={option}
                      onChange={handleOptionChange}
                      className={styles.selectField}
                      label="Group by"
                      variant="outlined"
                      options={BANK_TRANSACTIONS_GRAPH_GROUP_BY_OPTIONS}
                      disabled={isLoadingGraph}
                      fullWidth={false}
                    />
                  )}

                  {isGraphShown && (
                    <SelectField
                      useFinalForm={false}
                      name="reportingPeriod"
                      value={reportingPeriod}
                      onChange={handleReportingPeriodChange}
                      className={styles.selectField}
                      label="Period"
                      variant="outlined"
                      options={REPORTING_PERIOD_OPTIONS}
                      disabled={isLoadingGraph}
                      fullWidth={false}
                    />
                  )}

                  <DatePicker
                    currentDateRange={currentDateRange}
                    datesBoundary={datesBoundary}
                    onChange={handleDateChange}
                  />
                  {!isGraphShown && isClientPage && (
                    <ExportButton isLoading={isExportLoading} handleExport={handleExport} />
                  )}

                  {TabsComponent}

                  <ExpandAndMinimize action={handleModalToggle} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />
        {isGraphShown ? (
          <BankTransactionsGraph
            isModalShown={isModalShown}
            bankTransactionsGraphData={bankTransactionsGraphData}
            bankTransactionsGraphHeader={bankTransactionsGraphHeader}
            bankTransactionsGraphOption={bankTransactionsGraphOption}
            option={option}
            reportingPeriod={reportingPeriod}
          />
        ) : (
          <BankTransactionsTable
            isLoading={isLoading}
            filtersConfig={filtersConfig}
            bankTransactionsData={bankTransactionsData}
            orderBy={orderBy}
            onOrderChange={handleOrderChange}
            activeItem={activeItem}
            activeItems={activeItems}
            setActiveItem={setActiveItem}
            setActiveItems={setActiveItems}
            handleSelectRow={handleSelectRow}
            loadMore={loadMore}
            itemsCount={itemsCount}
            totalItems={totalItems}
            resetActiveItems={resetActiveItems}
            wrapperRef={wrapperRef}
            isModalShown={isModalShown}
          />
        )}
        {isMappingShown && (
          <FullscreenModal
            isOpen
            setIsOpen={handleCloseMappingModal}
            disableEnforceFocus
            showCloseIcon
            classes={{
              body: styles.fullScreenModalBody,
            }}
          >
            <Card
              withBorder={false}
              title={<span className={styles.mappingContainerTitleText}>Mapping</span>}
              className={styles.mappingContainer}
              classes={{
                title: styles.mappingContainerTitle,
                content: styles.mappingContainerContent,
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={1} mx={3}>
                <Tabs tabs={TABS} selected={tabValue} handleChange={setTabValue} />
              </Box>

              <BankTransactionsMapping
                id={id}
                transactionType={tabValue === BANK_TRANSACTIONS_TABS.DEBITS ? 'Debit' : 'Credit'}
                isModalShown={true}
                reportingFlow={ReportingFlow.ClientPage}
                tableClassName={styles.mappingTable}
                currentClientName={clientName}
              />

              <Divider className={styles.divider} light />
              <Box my={2}>
                <BankTransactionsMappingLegend className={styles.mappingContainerLegend} />
              </Box>
            </Card>
          </FullscreenModal>
        )}
      </>
    </FullscreenModal>
  )
}

export default BankTransactions
