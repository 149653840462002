import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import OngoingReportingSummary from './OngoingReportingSummary'
import OngoingReportingSummaryLoader from './OngoingReportingSummaryLoader'

import {
  dataSummary,
  dataSummaryHeaders,
  dataSummaryHeadersColors,
  dataSummaryGrowth,
} from '../../redux/ongoingReporting/selectors'
import { listSummaryOngoingReporting } from '../../redux/ongoingReporting/actions'
import { listClientFinancialsSummary } from '../../redux/clientInfo/actions'
import { updateFinancialsDates } from '../../redux/prospect/actions'
import { reporting } from '../../redux/prospect/selectors'

const selector = createStructuredSelector({
  dataSummary,
  dataSummaryHeaders,
  dataSummaryHeadersColors,
  dataSummaryGrowth,
  reporting,
})
const actions = {
  listSummaryOngoingReporting,
  listClientFinancialsSummary,
  updateFinancialsDates,
}

export { OngoingReportingSummaryLoader }

export default connect(selector, actions)(OngoingReportingSummary)
