import React, { useEffect, useMemo } from 'react'
import styles from './BoxViewer.module.scss'
import cn from 'classnames'
import Drawer from '@mui/material/Drawer'
import { ReactComponent as ArrowRight } from '@assets/images/arrow-right-circle-icon.svg'
import { historyChangeEventHandler } from '../../helpers/helpers'
import { useHistory, useLocation } from 'react-router'

interface IProps {
  boxViewLink: string
  setBoxViewerLink: (link: string) => void
  isAdminLoggedAsClient: boolean
}

const BoxViewer = ({ boxViewLink, setBoxViewerLink, isAdminLoggedAsClient }: IProps) => {
  const onCancel = useMemo(
    () => () => {
      if (boxViewLink) {
        setBoxViewerLink('')
      }
    },
    [boxViewLink, setBoxViewerLink],
  )

  const history = useHistory()
  const { pathname } = useLocation()

  useEffect(() => {
    const unlisten = historyChangeEventHandler(onCancel, history, pathname)
    return unlisten
  }, [history, pathname, onCancel])

  return (
    <Drawer
      className={cn(styles.drawer, {
        [styles.expanded]: !!boxViewLink,
      })}
      classes={{
        paper: cn(styles.drawerPaper, {
          [styles.isAdminLoggedAsClient]: isAdminLoggedAsClient,
        }),
      }}
      variant="persistent"
      anchor="right"
      open={!!boxViewLink}
    >
      <ArrowRight className={cn(styles.modalClose)} onClick={onCancel} />
      <div className={cn(styles.modalBody)}>
        <iframe
          title="Dwight Funding"
          src={boxViewLink}
          width="100%"
          height="100%"
          allowFullScreen
          className={styles.iframe}
        />
      </div>
    </Drawer>
  )
}

export default BoxViewer
