import React, { useMemo, useEffect } from 'react'
import { useParams } from 'react-router'
import cn from 'classnames'

import styles from './InvestmentDetailRow.module.scss'
import genericSs from '@styles/generic.module.scss'

import TableContainer from '../Common/TableContainer'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import { IInvestmentRowDetail } from '@common/interfaces/entityInfo'
import { formatPrice } from '../../helpers/helpers'
import TableLoader from '../Common/TableLoader'
import { ILoadingData } from '../../redux/types'

interface IProps {
  investmentDetailRow: ILoadingData<IInvestmentRowDetail>
  getInvestmentDetailRow: (id: string, data: object) => void
  clientName: string
}

const InvestmentDetailRow = ({
  investmentDetailRow,
  getInvestmentDetailRow,
  clientName,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const { isLoading, data: rowData } = useMemo(() => {
    return {
      isLoading: investmentDetailRow?.isLoading,
      data: investmentDetailRow?.data?.data,
    }
  }, [investmentDetailRow])

  useEffect(() => {
    id && clientName && getInvestmentDetailRow(id, { clientName })
  }, [id, clientName, getInvestmentDetailRow])

  return (
    <TableContainer className={cn(styles.table)} size="small">
      <Table>
        <TableHead>
          <TableRow>
            {rowData?.rounds?.map((round, index) => (
              <TableCell key={index} className={genericSs.tableTextRight}>
                {round.round}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody id={clientName} className={styles.scrollableDiv}>
          {isLoading ? (
            <TableLoader columnsCount={7} />
          ) : (
            <TableRow>
              {rowData?.rounds?.map((round, index) => (
                <TableCell key={index} className={genericSs.tableTextRight}>
                  {`$${formatPrice(round?.totalInvestment)}`}
                </TableCell>
              ))}
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default InvestmentDetailRow
