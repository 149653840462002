import React from 'react'
import MuiPagination, { PaginationProps } from '@mui/lab/Pagination'
import MuiPaginationItem from '@mui/lab/PaginationItem'

import styles from './Pagination.module.scss'

const Pagination = (props: PaginationProps) => {
  return (
    <MuiPagination
      classes={{ root: styles.root }}
      renderItem={(item) => (
        <MuiPaginationItem
          classes={{
            root: styles.itemRoot,
            selected: styles.itemSelected,
            focusVisible: styles.itemFocusVisible,
            disabled: styles.itemDisabled,
          }}
          {...item}
        />
      )}
      {...props}
    />
  )
}

export default Pagination
