import Base from './base'

export default class Bbc extends Base {
  show(id: string, params: object) {
    return this.apiClient.get(`bbc/${id}`, params)
  }

  update(id: string, data: object) {
    return this.apiClient.patch(`bbc/${id}`, data)
  }

  getSummaryStats(id: string, params: object) {
    return this.apiClient.get(`bbc/${id}/stats/summary`, params)
  }

  getARSummaryStats(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/stats/ar-summary`, params)
  }

  getARDetailStats(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/stats/ar-detail`, params)
  }

  getAPSummaryStats(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/stats/ap-summary`, params)
  }

  getAPDetailStats(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/stats/ap-detail`, params)
  }

  getARAPChartData(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/charts/ar-ap-summary`, params)
  }

  getARAPChartDropdownOptions(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/charts/ar-ap-dropdown-options`, params)
  }

  getARRollforward(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ar/rollforward`, params)
  }

  getARRollforwardDetails(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ar/rollforward/details`, params)
  }

  getARRollforwardGraph(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ar/rollforward/graph`, params)
  }

  getAPRollforward(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ap/rollforward`, params)
  }

  getAPRollforwardDetails(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ap/rollforward/details`, params)
  }

  getFiles(id: string) {
    return this.apiClient.get(`bbc/${id}/files`)
  }

  uploadBBCFiles(id: string, data: FormData) {
    return this.apiClient.put(`bbc/${id}/files`, data)
  }

  submitBBCFiles(id: string, data: object) {
    return this.apiClient.post(`bbc/${id}/files`, data)
  }

  submitForReview(id: string) {
    return this.apiClient.put(`bbc/${id}/review`)
  }

  submit(id: string, data: object = {}) {
    return this.apiClient.put(`bbc/${id}/submit`, data)
  }

  inventoryIneligibleSubmit(id: string) {
    return this.apiClient.post(`bbc/${id}/inventory-ineligible`)
  }

  listInventoryIneligibleCategories(id: string, data: object) {
    return this.apiClient.get(`bbc/${id}/inventory-ineligible-categories/list`, data)
  }

  listMasterInventory(id: string, data: object) {
    return this.apiClient.get(`bbc/${id}/master-inventory/list`, data)
  }

  updateInventoryIneligibleCategories(id: string, itemId: string, data: object) {
    return this.apiClient.put(`bbc/${id}/inventory-ineligible-categories/${itemId}`, data)
  }

  updateMasterInventory(id: string, itemId: string, data: object) {
    return this.apiClient.put(`bbc/${id}/master-inventory/${itemId}`, data)
  }

  listArIneligibility(id: string, data: object) {
    return this.apiClient.get(`bbc/${id}/ar-ineligibility`, data)
  }

  updateArIneligibility(id: string, itemId: string, data: object) {
    return this.apiClient.put(`bbc/${id}/ar-ineligibility/${itemId}`, data)
  }

  submitArIneligibility(id: string) {
    return this.apiClient.post(`bbc/${id}/ar-ineligibility`)
  }

  listExtraReserves(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/extra-reserves`, params)
  }

  updateExtraReserves(id: string, data: object) {
    return this.apiClient.post(`bbc/${id}/extra-reserves`, data)
  }

  listFields(data: object) {
    return this.apiClient.get(`bbc/inventory-ineligible-fields`, data)
  }

  createFields(data: object) {
    return this.apiClient.post(`bbc/inventory-ineligible-fields`, data)
  }

  updateFields(data: object) {
    return this.apiClient.put(`bbc/inventory-ineligible-fields`, data)
  }

  deleteField(id: string) {
    return this.apiClient.delete(`bbc/inventory-ineligible-fields/${id}`)
  }

  listCustomRules(data: object) {
    return this.apiClient.get(`bbc/inventory-ineligible-custom-rules`, data)
  }

  createCustomRules(data: object) {
    return this.apiClient.post(`bbc/inventory-ineligible-custom-rules`, data)
  }

  updateCustomRule(id: string, data: object) {
    return this.apiClient.put(`bbc/inventory-ineligible-custom-rules/${id}`, data)
  }

  deleteCustomRule(id: string, data: object) {
    return this.apiClient.delete(`bbc/inventory-ineligible-custom-rules/${id}`, data)
  }

  listCustomRulesLabels() {
    return this.apiClient.get(`bbc/inventory-ineligible-custom-rules/labels`)
  }

  createCustomRulesLabel(data: object) {
    return this.apiClient.post(`bbc/inventory-ineligible-custom-rules/labels`, data)
  }

  listCustomRulesValues(data: object) {
    return this.apiClient.get(`bbc/inventory-ineligible-custom-rules/values`, data)
  }

  listInventoryDistributionAnalysis(id: string, data: object) {
    return this.apiClient.get(`bbc/${id}/inventory-distribution-analysis`, data)
  }

  listInventoryDistributionAnalysisCategories(id: string, data: object) {
    return this.apiClient.get(`bbc/${id}/inventory-distribution-analysis-categories`, data)
  }

  listInventoryIneligibleStatsSummary(id: string, data?: object) {
    return this.apiClient.get(`bbc/${id}/inventory-ineligible-stats-summary`, data)
  }

  listInventoryIneligibleStatsReason(id: string, data?: object) {
    return this.apiClient.get(`bbc/${id}/inventory-ineligible-stats-reason`, data)
  }

  listInventoryIneligibleStatsDetail(id: string, data?: object) {
    return this.apiClient.get(`bbc/${id}/inventory-ineligible-stats-detail`, data)
  }

  listARIneligibleStats(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ar-ineligible-stats`, params)
  }

  listArIneligibleStatsDetails(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ar-ineligible-stats-details`, params)
  }

  listArIneligibleStatsDetailsInvoices(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/ar-ineligible-stats-details-invoices`, params)
  }

  getPreviousBBCClientCollateral(id: string, params: object = {}) {
    return this.apiClient.get(`bbc/${id}/client-collateral/previous`, params)
  }

  calculateClientCollateral(id: string) {
    return this.apiClient.post(`bbc/${id}/client-collateral`)
  }

  export(id: string) {
    return this.apiClient.post(`bbc/${id}/export`, {}, { isFile: true })
  }

  updatePDF(id: string) {
    return this.apiClient.post(`bbc/${id}/update-pdf`)
  }

  updateAlias(data: object) {
    return this.apiClient.put(`aliases`, data)
  }

  deleteAlias(data: object) {
    return this.apiClient.delete(`aliases`, data)
  }

  downloadTemplate(fileType: string, fileId: string) {
    return this.apiClient.get(`bbc/template`, { isFile: true, fileType, fileId })
  }

  listBBCReserves(id: string, data: object) {
    return this.apiClient.get(`/bbc/${id}/reserves`, data)
  }

  createBBCReserve(id: string, data: object) {
    return this.apiClient.post(`/bbc/${id}/reserves`, data)
  }

  updateBBCReserve(id: string, itemId: string, data: object) {
    return this.apiClient.patch(`/bbc/${id}/reserves/${itemId}`, data)
  }

  deleteBBCReserve(id: string, itemId: string) {
    return this.apiClient.delete(`/bbc/${id}/reserves/${itemId}`)
  }
  calculateBBCFlags(id: string) {
    return this.apiClient.get(`bbc/${id}/calculate-flags`)
  }

  sendSignReminder(id: string) {
    return this.apiClient.post(`bbc/${id}/send-sign-reminder`)
  }
}
