import { Grid } from '@mui/material'
import React, { useMemo } from 'react'
import { generatePath, useParams } from 'react-router'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { BoxLink, SalesforceLink, SettingsIcon } from '../Common/Icons'
import Breadcrumbs from '../Common/Breadcrumbs'
import { ROUTES } from '../../constants/routes'
import LoanCommitteeReportTabs from '../LoanCommitteeReportTabs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { IDueDiligence } from '@common/interfaces/dueDiligence'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
}

const LoanCommitteeReportHeader = ({ dueDiligenceInfo, show }: IProps) => {
  const { id } = useParams<{ id: string }>()

  useLoadInfo(id, dueDiligenceInfo, show)
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE, { id }),
        title: 'Loan Committee Report',
      },
    ]
  }, [dueDiligenceInfo, id])

  const settingsLink = useMemo(() => {
    return generatePath(ROUTES.CLIENT_SETUP, { id })
  }, [id])

  return (
    <Grid item container xs={12} justifyContent="space-between" rowGap={5}>
      <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
        <Breadcrumbs noMarginLeft breadcrumbs={breadcrumbs} isLoading={!dueDiligenceInfo} />
      </Grid>
      <Grid container item xs={6} justifyContent="flex-end" gap={1.5}>
        <BoxLink link={dueDiligenceInfo?.salesforceDDInfo?.boxLink} />
        <SalesforceLink link={dueDiligenceInfo?.salesforceDDInfo?.salesforceLink} />
        <SettingsIcon link={settingsLink} title="Client settings" />
      </Grid>
      <Grid item container xs={12}>
        <LoanCommitteeReportTabs />
      </Grid>
    </Grid>
  )
}

export default LoanCommitteeReportHeader
