import React, { useMemo } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Skeleton from '@mui/material/Skeleton'
import Link from '@mui/material/Link'
import genericSs from '@styles/generic.module.scss'
import styles from './LoanCommitteeReportOverviewDealStructure.module.scss'
import { formatDate, formatPercent, formatPriceNoDecimal } from '../../../helpers/helpers'
import { ROUTES } from '../../../constants/routes'
import { LOAN_TYPES } from '@common/constants/client'
import { allClientTermsInfo, OtherClientTerms } from '@common/interfaces/client'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { ClientTerms } from '@common/interfaces/client'

const TERM_STRUCTURE_COLUMNS: (keyof ClientTerms | keyof OtherClientTerms)[] = [
  'maxLineAmount',
  'minimumLineAmount',
  'minimumInterestEffectiveDate',
  'facilityFee',
  'interestRate',
  'interestRateBase',
  'interestRateAbovePrime',
  'terminationYear1',
  'terminationOngoing',
  'loanType',
  'arAdvance',
  'nolv',
  'inventoryAdvanceRateCost',
  'inventoryAdvanceRateNolv',
  'sublimit',
]
const INVENTORY_SPECIFIC_FIELDS = [
  'inventoryAdvanceRateCost',
  'inventoryAdvanceRateNolv',
  'sublimit',
]
const AR_SPECIFIC_FIELDS = ['arAdvance']

interface IProps {
  dueDiligenceInfo: IDueDiligence
  isLoading: boolean
}

const RenderedValue = ({ value, type }: { value: string | number; type: string }) => {
  const renderedValue = useMemo(() => {
    if (type === 'currency') {
      return (
        <>
          <span className={genericSs.pricePrefix}>$</span>
          {formatPriceNoDecimal(value)}
        </>
      )
    }
    if (type === 'percent') {
      return formatPercent(value as number)
    }

    if (type === 'date') {
      return formatDate(value as string)
    }

    return value
  }, [value, type])

  return <>{renderedValue}</>
}

const LoanCommitteeReportOverviewDealStructure = ({ dueDiligenceInfo, isLoading }: IProps) => {
  const { id } = useParams<{ id: string }>()

  const columns = useMemo(() => {
    const filteredByAR = TERM_STRUCTURE_COLUMNS.filter(
      (term) =>
        !AR_SPECIFIC_FIELDS.includes(term) ||
        (dueDiligenceInfo?.loanType && dueDiligenceInfo.loanType !== LOAN_TYPES.inventory),
    )

    const filteredByInventory = filteredByAR.filter(
      (term) =>
        !INVENTORY_SPECIFIC_FIELDS.includes(term) ||
        (dueDiligenceInfo?.loanType && dueDiligenceInfo.loanType !== LOAN_TYPES.ar),
    )

    const fieldsWithConfig = filteredByInventory.map((column) =>
      allClientTermsInfo.find(({ value }) => value === column),
    )

    const withTerminationLabel = fieldsWithConfig.map((column) =>
      column.value === 'terminationYear1'
        ? {
            ...column,
            label: 'Termination fee (year 1 / year 2)',
          }
        : column,
    )

    return withTerminationLabel
  }, [dueDiligenceInfo])

  const terminationComponent = useMemo(() => {
    return (
      <>
        {dueDiligenceInfo?.terminationYear1
          ? formatPercent(dueDiligenceInfo.terminationYear1)
          : '-'}{' '}
        /{' '}
        {dueDiligenceInfo?.terminationYear2
          ? formatPercent(dueDiligenceInfo.terminationYear2)
          : '-'}
      </>
    )
  }, [dueDiligenceInfo])

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.titleContainer}>
          <div className={styles.title}>Deal Structure</div>
        </div>

        <Link
          component={RouterLink}
          to={generatePath(ROUTES.CLIENT_SETUP_LOAN_STRUCTURE_ASSET_BASED, { id })}
          className={styles.editLink}
        >
          Edit
        </Link>
      </div>
      <div className={styles.data}>
        {columns?.map(({ value, label, type }) => (
          <div key={value} className={styles.column}>
            <div className={styles.label}>{label}</div>
            <div className={styles.value}>
              {isLoading ? (
                <Skeleton width={100} height={15} />
              ) : value === 'terminationYear1' ? (
                terminationComponent
              ) : dueDiligenceInfo?.[value as keyof IDueDiligence] ? (
                <RenderedValue
                  value={dueDiligenceInfo[value as keyof IDueDiligence] as string | number}
                  type={type}
                />
              ) : (
                '-'
              )}
            </div>
          </div>
        ))}
        <div className={styles.column}>
          <div className={styles.label}>Risk Rating</div>
          <div className={styles.value}>{dueDiligenceInfo?.riskRating || '-'}</div>
        </div>
      </div>
    </div>
  )
}

export default LoanCommitteeReportOverviewDealStructure
