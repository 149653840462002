import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { matchPath, useHistory } from 'react-router'
import LinkButton from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import Box from '@mui/material/Box'
import { Form } from 'react-final-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import moment from 'moment'
import cn from 'classnames'
import styles from './ClientAccountActivity.module.scss'
import genericSs from '@styles/generic.module.scss'
import SelectField from '../../Common/SelectField'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { ReactComponent as LinkIcon } from '@assets/images/link-out-icon.svg'
import Card from '../../Common/Card'
import ActiveToolbar from '../../ActiveToolbar'
import {
  AccountActivityPeriods,
  IAggregationData,
  IClientInfo,
  ACCOUNT_ACTIVITY_PERIOD_OPTIONS,
} from '@common/interfaces/client'
import {
  CLIENT_PAGE_ACTIVITY_OPTIONS,
  ClientPageActivityOption,
  ROUTES,
} from '../../../constants/routes'
import {
  dateToString,
  debounceEventHandler,
  formatDate,
  formatPrice,
  formatter,
  handleStopPropagation,
} from '../../../helpers/helpers'
import TableContainer from '../../Common/TableContainer'
import Table from '../../Common/Table'
import TableHead from '../../Common/TableHead'
import TableBody from '../../Common/TableBody'
import TableFiltersRow from '../../Common/TableFiltersRow'
import TableRow from '../../Common/TableRow'
import TableCell from '../../Common/TableCell'
import { AGGREGATION_TYPES } from '@common/constants/client'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../../helpers/filters'
import { CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import FilterContainer from '../../Filters/FilterContainer'
import { CLIENT_AGGREGATION_TYPES } from '@common/constants/client'
import Button from '../../Common/Button'
import ClientAccountActivityAction from './ClientAccountActivityAction'
import ClientAccountActivityCreateModal from '../ClientAccountActivityCreateModal'
import ClientAccountActivityEditModal from '../ClientAccountActivityEditModal'
import ClientAccountActivityDeleteModal from '../ClientAccountActivityDeleteModal'
import ClientAccountActivityRestoreModal from '../ClientAccountActivityRestoreModal'
import { ILoadingData } from '../../../redux/types'
import TableLoader from '../../Common/TableLoader'
import useTrackVisualizationsTable from '../../../hooks/useTrackVisualizationsTable'
import { CATEGORIES } from '@common/constants/tracking'
import useGraphToggle from '../../../hooks/useGraphTogggle'
import AccountActivityGraph from '../../AccountActivityGraph'
import DatePicker from '../../Common/DatePicker'
import AddButton from '../AddButton'
import { usePermissions } from '../../../helpers/permissionContext'
import ExportButton from '../../Common/ExportButton'
import useTable from '../../../hooks/useTable'

const filtersValidate = buildFiltersValidateSchema(CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG)

const GRAPH_FILTER_FIELDS = ['type', 'description']

interface IProps {
  isAdminRightsRole: boolean
  isClientUser: boolean
  clientInfo: IClientInfo
  aggregationData: ILoadingData<IAggregationData>
  listAggregation: (
    clientId?: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  exportAggregation: (
    clientId?: string,
    params?: {
      page?: number
      perPage?: number
      filters?: object
      orderBy?: string
      orderDirection?: string
    },
  ) => void
  listCustomers: (id?: string) => void
  customers: ILoadingData<{
    data: string[]
  }>
  setBoxViewerLink: (link: string) => void
  boxViewLink: string
  getEmbedLink: (fileId: string) => void
  setActivityOption?: (option: ClientPageActivityOption) => void
}

const ClientAccountActivity = ({
  isAdminRightsRole,
  isClientUser,
  clientInfo,
  aggregationData,
  listAggregation,
  exportAggregation,
  customers,
  listCustomers,
  setBoxViewerLink,
  boxViewLink,
  getEmbedLink,
  setActivityOption,
}: IProps) => {
  const history = useHistory()
  const [isExporting, setIsExporting] = useState(false)
  const [isCreateModalShown, setIsCreateModalShown] = useState(false)
  const [isEditModalShown, setIsEditModalShown] = useState(false)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [isRestoreModalShown, setIsRestoreModalShown] = useState(false)
  const [currentReportingPeriod, setCurrentReportingPeriod] = useState(
    AccountActivityPeriods.Monthly,
  )
  const { isParticipant } = usePermissions()

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'accountActivity',
    filtersDefaults,
    sortDefault: {
      field: 'record_date',
      direction: 'DESC',
    },
  })

  const isInitialized = useMemo(() => !!aggregationData?.data?.data, [aggregationData])
  const visualizationsParams = useMemo(
    () => ({
      clientId: clientInfo?.id,
    }),
    [clientInfo],
  )
  useTrackVisualizationsTable({
    isInitialized,
    category: CATEGORIES.clientAccountActivity,
    params: visualizationsParams,
    filtersConfig: CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG,
    filters,
    orderBy,
  })

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const endOfToday = useMemo(() => moment().endOf('day'), [])

  const { aggregation, isLoading } = useMemo(
    () => ({
      aggregation: aggregationData?.data,
      isLoading: aggregationData?.isLoading,
    }),
    [aggregationData],
  )
  const itemsCount = useMemo(() => aggregation?.data.length, [aggregation])

  const fetchAggregationList = useCallback(
    (data: any) => {
      const params = {
        ...data,
        filters: {
          ...data.filters,
        },
      }
      if (params?.filters.recordDateFrom && typeof params.filters.recordDateFrom !== 'string') {
        params.filters.recordDateFrom = dateToString(params.filters.recordDateFrom)
      }
      if (params?.filters.recordDateTo && typeof params.filters.recordDateTo !== 'string') {
        params.filters.recordDateTo = dateToString(params.filters.recordDateTo)
      }
      clientInfo && listAggregation(isClientUser ? undefined : clientInfo.id, params)
    },
    [isClientUser, clientInfo, listAggregation],
  )

  const debounceListAggregation = useMemo(
    () => debounceEventHandler(fetchAggregationList, 500),
    [fetchAggregationList],
  )

  const handleExportAggregation = useCallback(async () => {
    setIsExporting(true)
    await exportAggregation(isClientUser ? undefined : clientInfo.id, {
      page: 0,
      perPage: 0,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
    setIsExporting(false)
  }, [isClientUser, clientInfo, filters, orderBy, exportAggregation])

  useEffect(() => {
    debounceListAggregation({
      page: 0,
      perPage: PER_PAGE,
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [filters, orderBy, debounceListAggregation])

  const refetchListAggregation = useCallback(() => {
    debounceListAggregation({
      page: 0,
      perPage: Math.max(itemsCount, PER_PAGE),
      filters,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
    })
  }, [itemsCount, filters, orderBy, debounceListAggregation])

  const handleItemClick = useCallback(
    (item: any) => {
      if (activeItems.length === 1) {
        if (!item.boxLink && boxViewLink) {
          setBoxViewerLink('')
        } else if (isClientUser && item?.borrowingBase?.clientBoxLink) {
          const fileId = item?.borrowingBase?.clientBoxLink?.split('/').pop() || null
          getEmbedLink(fileId)
        } else if ((!isClientUser || item?.type === AGGREGATION_TYPES.check) && item.boxLink) {
          const fileId = item.boxLink.split('/').pop() || null
          getEmbedLink(fileId)
        }
      }
    },
    [isClientUser, getEmbedLink, boxViewLink, setBoxViewerLink, activeItems],
  )

  useEffect(() => {
    if (activeItems.length > 1 && boxViewLink) {
      setBoxViewerLink('')
    }
  }, [activeItems, setBoxViewerLink, boxViewLink])

  const handleOpenLink = useCallback(
    (e: React.MouseEvent, item: any) => {
      e.stopPropagation()
      if (isClientUser && item?.borrowingBase?.clientBoxLink) {
        window.open(item?.borrowingBase?.clientBoxLink, '_blank')
      } else if (!isClientUser && item.boxLink) {
        window.open(item.boxLink, '_blank')
      }
    },
    [isClientUser],
  )

  const totalRow = useMemo(
    () =>
      aggregation?.data
        .filter((_, index) => activeItems.includes(index))
        .reduce(
          (result, row) => {
            result.amount += row.amount || 0
            if (row.isDeletable && !row.isDeleted) {
              result.isDeletable = true
            }

            return result
          },
          {
            amount: 0,
            isDeletable: false,
          },
        ),
    [aggregation, activeItems],
  )

  const loadMore = useCallback(() => {
    fetchAggregationList({
      loadMore: true,
      page: Math.ceil(aggregation?.data.length / PER_PAGE),
      perPage: PER_PAGE,
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
    })
  }, [aggregation, orderBy, filters, fetchAggregationList])

  useEffect(() => {
    if (isClientUser) {
      listCustomers()
    } else if (clientInfo) {
      listCustomers(clientInfo.id)
    }
  }, [listCustomers, clientInfo, isClientUser])

  const { customersList } = useMemo(
    () => ({
      customersList: customers?.data?.data || [],
    }),
    [customers],
  )

  const filtersConfig = useMemo(
    () =>
      CLIENT_ACCOUNT_ACTIVITY_LIST_FILTERS_CONFIG?.filter((filter) => {
        if (!isAdminRightsRole) {
          return filter.type !== 'empty'
        }
        return true
      })
        ?.map((filter) => {
          if (isClientUser && filter.type === 'quickFilter') {
            return {
              ...filter,
              quickFilters: [
                {
                  title: 'Last Month',
                  filters: {
                    recordDateFrom: moment()
                      .startOf('month')
                      .subtract(1, 'month')
                      .format('YYYY-MM-DD'),
                    recordDateTo: moment().endOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
                  },
                },
                {
                  title: 'Last Quarter',
                  filters: {
                    recordDateFrom: moment()
                      .startOf('quarter')
                      .subtract(1, 'quarter')
                      .format('YYYY-MM-DD'),
                    recordDateTo: moment()
                      .endOf('quarter')
                      .subtract(1, 'quarter')
                      .format('YYYY-MM-DD'),
                  },
                },
                {
                  title: 'Fundings',
                  filters: {
                    type: [AGGREGATION_TYPES.funding],
                  },
                },
              ],
            }
          }

          if (filter.field === 'type' && isClientUser) {
            return {
              ...filter,
              options: CLIENT_AGGREGATION_TYPES,
            }
          } else if (filter.field === 'description') {
            return {
              ...filter,
              title: 'Customer',
              options: customersList?.map((customer) => ({
                value: customer,
                label: customer,
              })),
            }
          }
          return filter
        })
        .filter((filter) =>
          isGraphShown && !isClientUser
            ? GRAPH_FILTER_FIELDS.includes(filter.field)
            : isGraphShown && isClientUser
            ? filter.field === 'description'
            : true,
        ),

    [isClientUser, isAdminRightsRole, customersList, isGraphShown],
  )

  const handleCreate = useCallback(() => {
    setIsCreateModalShown(true)
  }, [])

  const handleEdit = useCallback((item: any) => {
    setIsEditModalShown(true)
  }, [])

  const handleDelete = useCallback(() => {
    setIsDeleteModalShown(true)
  }, [])

  const handleRestore = useCallback(() => {
    setIsRestoreModalShown(true)
  }, [])

  const handleModalConfirm = useCallback(() => {
    setActiveItems([])

    setIsCreateModalShown(false)
    setIsEditModalShown(false)
    setIsDeleteModalShown(false)
    setIsRestoreModalShown(false)
    refetchListAggregation()
  }, [refetchListAggregation, setActiveItems])

  const handleModalCancel = useCallback(() => {
    setIsCreateModalShown(false)
    setIsEditModalShown(false)
    setIsDeleteModalShown(false)
    setIsRestoreModalShown(false)
  }, [])

  const activeAggregationItems = useMemo(
    () => aggregation?.data.filter((_, index) => activeItems.includes(index)),
    [aggregation, activeItems],
  )

  const handleClickRow = useCallback(
    (event: React.MouseEvent, index: number, item: any) => {
      // check if the elemnt clicked isnt the actions menu
      handleSelectRow(event, index)
      if (
        !(
          event.target instanceof HTMLElement &&
          event.target.closest('.MuiMenu-root') &&
          event.target.closest('.MuiMenu-root')?.contains(event.target)
        )
      ) {
        handleItemClick(item)
      }
    },
    [handleSelectRow, handleItemClick],
  )

  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: moment().subtract(1, 'year').format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
  })

  const datesBoundary = useMemo(
    () => ({
      minDate: moment(clientInfo?.contractDate).format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
    }),
    [clientInfo],
  )

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const handleReportingPeriodChange = useCallback(
    ({ target: { value } }) => {
      setCurrentReportingPeriod(value)
    },
    [setCurrentReportingPeriod],
  )

  const handleActivityOptionChange = useCallback(
    ({ target: { value } }) => {
      setActivityOption && setActivityOption(value)
    },
    [setActivityOption],
  )

  return (
    <Card noHeaderMargin withBorder={!isClientUser} noPadding={isClientUser}>
      <TableContainer
        className={cn(styles.aggregationTable, {
          [styles.aggregationTableFullHeight]: matchPath(history.location.pathname, {
            path: ROUTES.ACCOUNT_ACTIVITY,
          })?.isExact,
        })}
        size={isClientUser ? 'large' : 'small'}
        onActiveRowsChange={setActiveItems}
        onActiveRowChange={setActiveItem}
      >
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={filtersConfig}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              withFullSearch={!isGraphShown}
              title="Account Activity"
              actions={
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                  {!isClientUser && setActivityOption && (
                    <SelectField
                      useFinalForm={false}
                      name="option"
                      value={ClientPageActivityOption.AccountActivity}
                      onChange={handleActivityOptionChange}
                      className={styles.selectField}
                      label="View"
                      variant="outlined"
                      options={CLIENT_PAGE_ACTIVITY_OPTIONS}
                      fullWidth={false}
                    />
                  )}
                  {isAdminRightsRole && activeItems.length > 1 && totalRow?.isDeletable && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleDelete}
                      startIcon={<DeleteIcon className={genericSs.iconPathStrokeWhite} />}
                    >
                      Delete
                    </Button>
                  )}
                  {isAdminRightsRole && !isGraphShown && (
                    <AddButton variant="outlined" onClick={handleCreate}></AddButton>
                  )}
                  {!isGraphShown && (
                    <ExportButton isLoading={isExporting} handleExport={handleExportAggregation} />
                  )}

                  {isGraphShown && (
                    <div className={styles.recordDateWrapper}>
                      <DatePicker
                        currentDateRange={currentDateRange}
                        datesBoundary={datesBoundary}
                        onChange={handleDateChange}
                      />
                    </div>
                  )}
                  {isGraphShown && (
                    <SelectField
                      key={currentReportingPeriod}
                      label="Period"
                      variant="outlined"
                      useFinalForm={false}
                      name="reportingPeriod"
                      options={ACCOUNT_ACTIVITY_PERIOD_OPTIONS}
                      onChange={handleReportingPeriodChange}
                      value={currentReportingPeriod}
                      defaultValue=""
                      className={styles.selectField}
                    />
                  )}

                  <div>{TabsComponent}</div>
                </Box>
              }
            />
          )}
        />
        {!isGraphShown ? (
          <>
            <Table>
              <TableHead>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                />
              </TableHead>
              <TableBody id="scrollableTable">
                {isLoading ? (
                  <TableLoader columnsCount={filtersConfig.length} height={26} />
                ) : (
                  aggregation?.data &&
                  aggregation.data.length > 0 && (
                    <InfiniteScroll
                      dataLength={aggregation?.data.length}
                      next={loadMore}
                      hasMore={aggregation?.data.length < aggregation?.totals.totalItems}
                      loader={<TableLoader columnsCount={filtersConfig.length} rowsCount={1} />}
                      scrollableTarget="scrollableTable"
                    >
                      {aggregation.data.map((item, index) => {
                        let hideRow = false
                        if (
                          item.isDeleted ||
                          (moment(item.recordDate).isAfter(endOfToday) && !isClientUser)
                        ) {
                          hideRow = true
                        }

                        return (
                          <TableRow
                            key={`${item.id}-${index}`}
                            data-index={index}
                            isActiveRow={activeItems.includes(index)}
                            isCurrentActiveRow={activeItem === index}
                            className={cn('activableRow', {
                              [styles.deletedRow]: hideRow,
                            })}
                            index={index}
                            onClick={(event) => handleClickRow(event, index, item)}
                          >
                            <TableCell className={genericSs.tableTextRight}>
                              {formatDate(item.recordDate)}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item?.boxLink &&
                              !isClientUser &&
                              !isParticipant &&
                              item?.type === AGGREGATION_TYPES.check ? (
                                <span className={styles.aggregationTableType}>
                                  {item.type}
                                  {isAdminRightsRole && (
                                    <LinkIcon
                                      onClick={(e) => handleOpenLink(e, item)}
                                      className={styles.linkIcon}
                                    />
                                  )}
                                </span>
                              ) : !isClientUser &&
                                item.type === AGGREGATION_TYPES.funding &&
                                !item.isHistorical ? (
                                <>
                                  {item.borrowingBaseId ? (
                                    <LinkButton
                                      className={styles.aggregationTableType}
                                      component={Link}
                                      to={generatePath(ROUTES.BBC_SUMMARY, {
                                        id: item.borrowingBaseId,
                                      })}
                                      onClick={handleStopPropagation}
                                    >
                                      {item.type}
                                    </LinkButton>
                                  ) : (
                                    item.type
                                  )}

                                  {!isParticipant && (
                                    <LinkIcon
                                      onClick={(e) => handleOpenLink(e, item)}
                                      className={styles.linkIcon}
                                    />
                                  )}
                                </>
                              ) : item.boxLink && isClientUser ? (
                                <span className={styles.aggregationTableType}>{item.type}</span>
                              ) : (
                                item.type
                              )}
                            </TableCell>
                            <TableCell className={genericSs.tableTextLeft}>
                              {item.type === AGGREGATION_TYPES.wire ? (
                                <Tooltip title={item.originalMemo || item.description}>
                                  <span>{item.description}</span>
                                </Tooltip>
                              ) : (
                                item.description
                              )}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              <span className={genericSs.pricePrefix}>$</span>
                              {formatPrice(item.amount)}
                            </TableCell>
                            <TableCell className={genericSs.tableTextRight}>
                              {!hideRow && (
                                <span>
                                  <span className={genericSs.pricePrefix}>$</span>
                                  {formatPrice(item.loanBalanceEnding)}{' '}
                                </span>
                              )}
                            </TableCell>
                            {isAdminRightsRole && (
                              <ClientAccountActivityAction
                                item={item}
                                onEdit={handleEdit}
                                onDelete={handleDelete}
                                onRestore={handleRestore}
                              />
                            )}
                          </TableRow>
                        )
                      })}
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
            </Table>

            <ActiveToolbar activeItems={activeItems} className={styles.activeToolbar}>
              <div className={genericSs.tableTextRight}>{formatter.format(totalRow?.amount)}</div>
            </ActiveToolbar>
          </>
        ) : (
          <AccountActivityGraph
            filters={filters}
            startDate={currentDateRange.startDate}
            endDate={currentDateRange.endDate}
            period={currentReportingPeriod}
            isClientUser={isClientUser}
          />
        )}
      </TableContainer>

      {isCreateModalShown && (
        <ClientAccountActivityCreateModal
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}

      {isEditModalShown && aggregation?.data[activeItem] && (
        <ClientAccountActivityEditModal
          item={aggregation?.data[activeItem]}
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}

      {isDeleteModalShown && activeItems.length > 0 && (
        <ClientAccountActivityDeleteModal
          activeItems={activeAggregationItems}
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}

      {isRestoreModalShown && activeItems.length > 0 && (
        <ClientAccountActivityRestoreModal
          activeItems={activeAggregationItems}
          handleCancel={handleModalCancel}
          handleConfirm={handleModalConfirm}
        />
      )}
    </Card>
  )
}

export default ClientAccountActivity
