import React, { useMemo } from 'react'
import { IBrand } from '@common/interfaces/newsfeed'
import LinkUrl from '@mui/material/Link'

import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import { usePermissions } from '../../helpers/permissionContext'

interface IProps {
  brandInfo: IBrand
  type: string
  salesforceBaseUrl: string
}

const LinkedAccountItem = ({ brandInfo, type, salesforceBaseUrl }: IProps) => {
  const { isBDO } = usePermissions()

  const salesforceLink = useMemo(() => {
    const salesforceType = brandInfo?.salesforceType
    const salesforceId = brandInfo?.salesforceId
    if (!salesforceType || !salesforceId) {
      return null
    }
    return `${salesforceBaseUrl}${salesforceType}/${salesforceId}/view`
  }, [brandInfo, salesforceBaseUrl])
  return (
    <>
      {isBDO ? (
        <LinkUrl color="primary" href={salesforceLink} target="_blank" rel="noopener noreferrer">
          {brandInfo?.brandName}
        </LinkUrl>
      ) : brandInfo ? (
        brandInfo?.entityInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.ENTITY_PAGE, {
              id: brandInfo.entityInfo.id,
            })}
          >
            {brandInfo?.brandName}
          </LinkButton>
        ) : brandInfo?.clientInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.CLIENT_PAGE, {
              id: brandInfo.clientInfo.id,
            })}
          >
            {brandInfo?.brandName}
          </LinkButton>
        ) : brandInfo?.salesforceProspectInfo?.id ? (
          <LinkButton
            component={Link}
            to={generatePath(ROUTES.PROSPECT_PAGE, {
              id: brandInfo.salesforceProspectInfo.id,
            })}
          >
            {brandInfo?.brandName}
          </LinkButton>
        ) : (
          <LinkUrl color="primary" href={salesforceLink} target="_blank" rel="noopener noreferrer">
            {brandInfo?.brandName}
          </LinkUrl>
        )
      ) : null}
    </>
  )
}

export default LinkedAccountItem
