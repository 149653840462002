import { IClientInfo } from './client'
import { IEntityInfo } from './entityInfo'
import { ISalesforceProspectInfo } from './prospects'

export interface INewsfeedDataSheet {
  industry: string
  description: string
  recordDate: string | null
  link: string
  clientName: string
  salesforceId: string
  referralClientName: string
  referralSalesforceId: string
  wholesaler: string
  isBankruptcy: boolean
  isDistribution: boolean
  isFundraise: boolean
  isLawsuit: boolean
  isExit: boolean
  isNewFund: boolean
  amountRaised: string
  roundType: string
  isPublishing: boolean
  owner: string
  newAccountName: string
  isLeadingInvestor: boolean
}

export interface IClientNewsfeed {
  id: string
  createdAt: string
  newsfeedHeadlines: INewsfeed
  type: string
  displayUntil: string
}

export interface INewsfeedEmails {
  id: string
  email: string
  recordDate: string
}

export interface IBrand {
  id: string
  brandName: string
  salesforceId: string
  clientInfo: IClientInfo
  salesforceProspectInfo: ISalesforceProspectInfo
  entityInfo: IEntityInfo
  salesforceType: string
  brandIcon: string
  industry: string | null
}

export interface ILinkedAccount {
  id: string
  salesforceId: string | null
  type: string
  brandInfo: IBrand | null
}

export interface INewsfeed {
  id: string
  headline: string
  link: string
  relevanceScore: number | null
  type: string | null
  brand: string | null
  round: string | null
  amount: number | null
  industry: string | null
  isProcessed: boolean | null
  isUploaded: boolean
  createdAt: string
  updatedAt: string
  processedAt: string
  salesforceId: string | null
  isUrlTransformed: boolean
  clientNewsFeed: IClientNewsfeed[]
  clientName?: string
  clientInfo?: IClientInfo
  entityName?: string
  entityInfo?: IEntityInfo
  recordDate: string
  description: string
  headlineSummary: string
  newsfeedEmail: INewsfeedEmails
  brandFromSalesforce: IBrand
  isClient: boolean
  isProspect: boolean
  isEntity: boolean
  brandOptionValue?: { value: string; label: string }
  salesforceBaseUrl: string
  linkedAccounts: ILinkedAccount[]
  salesforceSyncProcessed: boolean
  clientNewsfeedHeadlines: IClientNewsfeed[]
}

export enum ClientNewsIndustries {
  Apparel = 'Apparel',
  FoodAndBeverage = 'Food & Beverage',
  Beauty = 'Beauty',
  Lifestyle = 'Lifestyle',
}

export interface INewsfeedData {
  data: INewsfeed[]
  totals: {
    totalItems: number
    lastRefreshed: string
  }
  clientIndustries: {
    [ClientNewsIndustries.Apparel]: IClientInfo[]
    [ClientNewsIndustries.FoodAndBeverage]: IClientInfo[]
    [ClientNewsIndustries.Beauty]: IClientInfo[]
    [ClientNewsIndustries.Lifestyle]: IClientInfo[]
  }
}

export interface INewsfeedItemData {
  data: INewsfeed
}

export interface IBrandOptions {
  brandsOptions: { label: string; value: string }[]
}

export interface IClientNewsfeedData {
  data: IClientNewsfeed[]
}

export enum NewsTags {
  Lawsuit = 'Lawsuit',
  Fundraise = 'Fundraise',
  Other = 'Other',
  Bankruptcy = 'Bankruptcy',
  Distribution = 'Distribution',
  NewFund = 'New Fund',
  Exit = 'Exit',
  Client = 'Client',
  Prospect = 'Prospect',
  Personnel = 'Personnel',
  ABL = 'ABL',
  Industry = 'Industry',
}

export enum FundraiseRounds {
  Seed = 'Seed',
  SeriesA = 'Series A',
  SeriesB = 'Series B',
  SeriesC = 'Series C',
  SeriesD = 'Series D',
  SeriesE = 'Series E',
  Other = 'Other',
}

export const TAG_COLOR_MAPPING = {
  [NewsTags.Lawsuit]: { backgroundColor: '#1F2124', color: '#FFFFFF' },
  [NewsTags.Bankruptcy]: { backgroundColor: '#1F2124', color: '#FFFFFF' },
  [NewsTags.Other]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.NewFund]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.Exit]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.Personnel]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.ABL]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.Distribution]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.Fundraise]: { backgroundColor: '#002C2E', color: '#FFFFFF' },
  [NewsTags.Client]: { backgroundColor: '#0066F5', color: '#FFFFFF' },
  [NewsTags.Prospect]: { backgroundColor: '#0066F5', color: '#FFFFFF' },
  [NewsTags.Industry]: { backgroundColor: '#FC6639', color: '#FFFFFF' },
}

export const TAG_OPTIONS = Object.values(NewsTags)
  .filter((item) => ![NewsTags.Client, NewsTags.Prospect, NewsTags.Industry].includes(item))
  .map((item) => {
    return { label: item, value: item }
  })

export const EMAIL_NEWSLETTER_MAPPING = {
  'expresscheckout@mail.beehiiv.com': 'Express Checkout',
  'mike@whipstitchcapital.com': 'Whipstitch Capital',
  'news@thehustle.co': 'The Hustle',
  'newsletter@divenewsletter.com': 'Dive Newsletter',
  'stitch@whipstitchcapital.com': 'Whipstitch Capital',
  'thisweekincpg@mail.beehiiv.com': 'This Week in CPG',
}
// Needs to be updated with salesforce if industries are removed or added
export enum BrandIndustries {
  Apparel = 'Apparel',
  Beauty = 'Beauty',
  Beverages = 'Beverages',
  BusinessServices = 'Business Services',
  Cannabis = 'Cannabis',
  Food = 'Food',
  Healthcare = 'Healthcare',
  Home = 'Home',
  Lifestyle = 'Lifestyle',
  Manufacturing = 'Manufacturing',
  Other = 'Other',
  Outdoor = 'Outdoor',
  PetProducts = 'Pet Products',
  Technology = 'Technology',
  ChildBaby = 'Child/Baby',
  FourWall = 'Four-wall',
}

export interface IUpdateData {
  id: string
  headline: string
  headlineSummary: string
  type: string
  link: string
  investor: string
  amount: number
  round: string
  linkedAccounts: string
  salesforceId: string
  salesforceLink: string
  otherAccountId: string
  linkedAccountId: string
  isFundraise: boolean
  isBrand: boolean
  isOtherAccount: boolean
  industries: ClientNewsIndustries[]
  displayUntil: string
  clientNewsfeedId: string
}
