import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './MarketNewsDetails.module.scss'
import {
  TAG_COLOR_MAPPING,
  EMAIL_NEWSLETTER_MAPPING,
  NewsTags,
  INewsfeedItemData,
  FundraiseRounds,
  TAG_OPTIONS,
  INewsfeed,
  ClientNewsIndustries,
} from '@common/interfaces/newsfeed'
import moment from 'moment'
import { ReactComponent as Square } from '@assets/images/square.svg'
import { ReactComponent as EditIcon } from '@assets/images/edit-outlined-icon.svg'
import { ReactComponent as DeleteIcon } from '@assets/images/delete-icon.svg'
import { ReactComponent as ExpandChevron } from '@assets/images/chevron-forward-icon.svg'
import genericSs from '@styles/generic.module.scss'
import TextField from '../Common/TextField'
import { DATE_FORMAT } from '../../constants/common'
import KeyboardDatePicker from '../Common/KeyboardDatePicker'
import { debounceEventHandler, formatDateCalendarNoTime, formatPrice } from '../../helpers/helpers'
import Button from '../Common/Button'
import ModalButton from '../Common/Button/Button'
import { Box, InputLabel, Tooltip } from '@mui/material'
import { Field, Form } from 'react-final-form'
import CurrencyField from '../Common/CurrencyField'
import LinkedAccountItem from './LinkedAccountItem'
import { stringAvatar } from '../Notes/helpers/helpers'
import Modal from '../Common/Modal'
import { ILoadingData } from '../../redux/types'
import TableLoader from '../Common/TableLoader'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import SelectField from '../Common/SelectField'
import UpdateBrandImageModal from '../UpdateBrandImageModal'
import CreatableSelectField from '../Common/CreatableSelectField'
import { ReactComponent as CloseChip } from '@assets/images/close-with-circle.svg'
import { ReactComponent as PlusCircleIcon } from '@assets/images/plus-circle-icon.svg'
import LinkedAccountInput from './LinkedAccountInput'
import WarningModal from '../WarningModal'
import { IClientInfo } from '@common/interfaces/client'
import Checkbox from '../Common/Checkbox'
import ClientDistributionIndustries from './ClientDistributionIndustries'

const validateSFLink = makeValidate(
  Yup.object().shape({
    salesforceLink: Yup.string()
      .url('Paste a valid URL')
      .matches(/\/(Account|Lead)/, 'Paste a valid URL')
      .required('Paste a valid URL'),
  }),
)

const validateLink = Yup.object().shape({
  link: Yup.string().url('Paste a valid URL'),
})

interface IProps {
  newsItemId: string
  updateNewsfeed: (id: string, params: object) => Promise<any>
  showNewsfeed: (id: string, params?: object) => void
  newsfeedItem: ILoadingData<INewsfeedItemData>
  listBrandOptions: (params: object) => Promise<any>
  deleteNews: (id: string) => Promise<any>
  setCurrentNewsItem: (item: INewsfeed | null) => void
  clientIndustries: Record<ClientNewsIndustries, IClientInfo[]>
}

export type OpenIndustriesState = {
  all: boolean
} & Record<ClientNewsIndustries, boolean>

const MarketNewsDetails = ({
  newsItemId,
  newsfeedItem,
  updateNewsfeed,
  showNewsfeed,
  listBrandOptions,
  deleteNews,
  setCurrentNewsItem,
  clientIndustries,
}: IProps) => {
  const [editingStates, setEditingStates] = useState({
    headline: false,
    form: false,
  })
  const [modalStates, setModalStates] = useState({
    isEditProfilePicModalOpen: false,
    isNewBrandModalOpen: false,
    isNewLinkedAccountModalOpen: false,
    isDeleteModalOpen: false,
  })

  const [isSaving, setIsSaving] = useState(false)
  const [newBrandError, setNewBrandError] = useState(null)
  const [isAddTagShown, setIsAddTagShown] = useState(false)
  const [currentLink, setCurrentLink] = useState(null)
  const [linkError, setLinkError] = useState('')
  const [openIndustries, setOpenIndustries] = useState<OpenIndustriesState>({
    all: false,
    [ClientNewsIndustries.Apparel]: false,
    [ClientNewsIndustries.FoodAndBeverage]: false,
    [ClientNewsIndustries.Beauty]: false,
    [ClientNewsIndustries.Lifestyle]: false,
  })
  const [isAllChecked, setIsAllChecked] = useState(false)

  const { data: newsItem, isLoading } = useMemo(
    () => ({
      data: newsfeedItem?.data?.data,
      isLoading: newsfeedItem?.isLoading,
    }),
    [newsfeedItem],
  )

  useEffect(() => {
    if (newsItemId) {
      showNewsfeed(newsItemId)
      setIsAddTagShown(false)
      setEditingStates({
        headline: false,
        form: false,
      })
      setOpenIndustries({
        all: false,
        [ClientNewsIndustries.Apparel]: false,
        [ClientNewsIndustries.FoodAndBeverage]: false,
        [ClientNewsIndustries.Beauty]: false,
        [ClientNewsIndustries.Lifestyle]: false,
      })
    }
  }, [newsItemId, showNewsfeed])

  useEffect(() => {
    if (!newsItem?.clientNewsfeedHeadlines || !clientIndustries) return

    const allIndustries = Object.keys(clientIndustries)
    const selectedIndustries = newsItem.clientNewsfeedHeadlines.map((item) => item.type)
    setIsAllChecked(allIndustries.length === selectedIndustries.length)
  }, [newsItem?.clientNewsfeedHeadlines, clientIndustries])

  const handleIndustryToggle = useCallback((industry: keyof OpenIndustriesState) => {
    setOpenIndustries((prev) => ({
      ...prev,
      [industry]: !prev[industry],
    }))
  }, [])

  const toggleEditingState = useCallback((key: 'headline' | 'form') => {
    setEditingStates((prev) => ({ ...prev, [key]: !prev[key] }))
  }, [])

  const handleToggleForm = useCallback(() => {
    toggleEditingState('form')
  }, [toggleEditingState])

  const handleOpenNewBrandModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isNewBrandModalOpen: true,
    }))
  }, [])

  const handleOpenNewLinkedAccountModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isNewLinkedAccountModalOpen: true,
    }))
  }, [])

  const handleSaveDetails = useCallback(
    async ({ target: { name, value } }) => {
      const updateObject = {
        [name]: name === 'amount' ? +value : value,
      }
      await updateNewsfeed(newsItemId, updateObject)
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [updateNewsfeed, showNewsfeed, newsItemId],
  )

  const handleClearRound = useCallback(async () => {
    updateNewsfeed(newsItemId, { round: null })
    await showNewsfeed(newsItemId, { skipLoader: true })
  }, [updateNewsfeed, newsItemId, showNewsfeed])

  useEffect(() => {
    if (newsItem?.link) {
      setCurrentLink(newsItem.link)
    } else {
      setCurrentLink(null)
    }
    setLinkError('')
  }, [newsItem?.link])

  const handleSaveLinkedAccounts = useCallback(
    async (event, formValues) => {
      const {
        value = null,
        inputValue = null,
        investor = null,
      } = formValues || {
        value: null,
        inputValue: null,
        investor: null,
      }
      if (inputValue) {
        handleOpenNewBrandModal()
      } else {
        await updateNewsfeed(newsItemId, {
          salesforceId: value || null,
          investor: investor || null,
        })
        await showNewsfeed(newsItemId, { skipLoader: true })
      }
    },
    [updateNewsfeed, showNewsfeed, newsItemId, handleOpenNewBrandModal],
  )

  const handleSaveHeadline = useCallback(
    async (formValues) => {
      const { headline, headlineSummary = null } = formValues
      await updateNewsfeed(newsItemId, { headline, headlineSummary })
      await showNewsfeed(newsItemId, { skipLoader: true })

      toggleEditingState('headline')
    },
    [toggleEditingState, updateNewsfeed, showNewsfeed, newsItemId],
  )

  const loadOptions = useCallback(
    async (search: string) => {
      const res = await listBrandOptions({ search })
      return res.data
    },
    [listBrandOptions],
  )
  const loadBrandOptions = useCallback(async (search: string) => loadOptions(search), [loadOptions])

  const toggleShowAddTag = useCallback(() => {
    setIsAddTagShown((prev) => !prev)
  }, [setIsAddTagShown])

  const initials = useMemo(() => {
    const name = newsItem?.brandFromSalesforce?.brandName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [newsItem])

  const handleCloseNewBrandModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isNewBrandModalOpen: false,
      isNewLinkedAccountModalOpen: false,
    }))
    setIsSaving(false)
  }, [])

  const handleToggleDeleteModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isDeleteModalOpen: !prevState.isDeleteModalOpen,
    }))
  }, [])

  const handleDeleteNewsConfirm = useCallback(async () => {
    setIsSaving(true)

    await deleteNews(newsItemId)
    handleToggleDeleteModal()
    setIsSaving(false)
    setCurrentNewsItem(null)
  }, [deleteNews, newsItemId, handleToggleDeleteModal, setCurrentNewsItem])

  const openEditProfilePicModal = useCallback(() => {
    setModalStates((prevState) => ({
      ...prevState,
      isEditProfilePicModalOpen: true,
    }))
  }, [])

  const handleCreateNewBrand = useCallback(
    async (formValues) => {
      const { salesforceLink = null } = formValues
      setIsSaving(true)
      const result = await updateNewsfeed(newsItemId, {
        salesforceLink,
        isBrand: modalStates.isNewBrandModalOpen,
        isOtherAccount: modalStates.isNewLinkedAccountModalOpen,
        isFundraise: newsItem.type === NewsTags.Fundraise,
      })
      if (!!result?.data?.error) {
        setIsSaving(false)
        setNewBrandError('Brand either exists or URL is invalid')
      } else {
        handleCloseNewBrandModal()
        await showNewsfeed(newsItemId, { skipLoader: true })

        setNewBrandError(null)
      }
    },
    [
      handleCloseNewBrandModal,
      updateNewsfeed,
      newsItemId,
      showNewsfeed,
      modalStates.isNewBrandModalOpen,
      modalStates.isNewLinkedAccountModalOpen,
      newsItem?.type,
    ],
  )

  const handleDeleteTag = useCallback(async () => {
    await updateNewsfeed(newsItemId, { type: null })
    await showNewsfeed(newsItemId, { skipLoader: true })
  }, [showNewsfeed, newsItemId, updateNewsfeed])

  const handleAddTag = useCallback(
    ({ target: { value } }) => {
      updateNewsfeed(newsItemId, { type: value })
      toggleShowAddTag()
      showNewsfeed(newsItemId, { skipLoader: true })
    },
    [updateNewsfeed, showNewsfeed, newsItemId, toggleShowAddTag],
  )

  const investors = useMemo(() => {
    return newsItem?.linkedAccounts?.filter((item) => item.type === NewsTags.Fundraise)
  }, [newsItem])

  const handleAddNewOtherAccount = useCallback(
    async (newValue, isFundraise) => {
      const { isNew, value } = newValue[newValue.length - 1]
      if (isNew) {
        handleOpenNewLinkedAccountModal()
      } else {
        await updateNewsfeed(newsItemId, {
          otherAccountId: value || null,
          isFundraise: isFundraise,
        })
        await showNewsfeed(newsItemId, { skipLoader: true })
      }
    },
    [handleOpenNewLinkedAccountModal, updateNewsfeed, newsItemId, showNewsfeed],
  )

  const handleDeleteLinkedAccount = useCallback(
    async (linkedAccountId) => {
      await updateNewsfeed(newsItemId, {
        linkedAccountId,
      })
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [updateNewsfeed, newsItemId, showNewsfeed],
  )

  const debounceSaveDetails = useMemo(
    () =>
      debounceEventHandler((values: any) => {
        handleSaveDetails(values)
      }, 500),
    [handleSaveDetails],
  )

  const showShareSection = useMemo(() => {
    return moment(newsItem?.processedAt)?.add(14, 'days')?.toDate() > moment()?.toDate() || false
  }, [newsItem?.processedAt])

  const handleLinkChange = useCallback(
    (event) => {
      const { value } = event.target
      setCurrentLink(value)

      validateLink
        .validate({ link: value })
        .then(() => {
          setLinkError('')
          debounceSaveDetails(event)
        })
        .catch((validationError) => {
          setLinkError(validationError.message)
        })
    },
    [setCurrentLink, debounceSaveDetails],
  )

  const handleUpdateIndustry = useCallback(
    async (industry) => {
      const currentIndustries = newsItem.clientNewsfeedHeadlines.map((item) => item.type)
      const isIndustrySelected = currentIndustries.includes(industry)

      const updatedIndustries = isIndustrySelected
        ? currentIndustries.filter((type) => type !== industry)
        : [...currentIndustries, industry]

      await updateNewsfeed(newsItemId, {
        industries: updatedIndustries,
      })
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [newsItem?.clientNewsfeedHeadlines, newsItemId, showNewsfeed, updateNewsfeed],
  )

  const handleUpdateDisplayUntil = useCallback(
    async (date: Date, clientNewsfeedId?: string) => {
      await updateNewsfeed(newsItemId, {
        clientNewsfeedId,
        displayUntil: date,
      })
      await showNewsfeed(newsItemId, { skipLoader: true })
    },
    [newsItemId, showNewsfeed, updateNewsfeed],
  )

  const handleAllIndustriesToggle = useCallback(async () => {
    const allIndustries = Object.keys(clientIndustries)
    const newValue = !isAllChecked

    await updateNewsfeed(newsItemId, {
      industries: newValue ? allIndustries : [],
    })
    await showNewsfeed(newsItemId, { skipLoader: true })
  }, [isAllChecked, clientIndustries, updateNewsfeed, newsItemId, showNewsfeed])

  const newsIndustry = useMemo(
    () => newsItem?.brandFromSalesforce?.industry || newsItem?.industry || null,
    [newsItem?.industry, newsItem?.brandFromSalesforce?.industry],
  )

  return !isLoading && newsItem ? (
    <div className={styles.detailsContainer}>
      <div className={styles.header}>
        <Form
          onSubmit={handleSaveHeadline}
          initialValues={newsItem}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className={styles.headerContainer}>
                  {newsItem?.brandFromSalesforce?.id ? (
                    <div
                      className={styles.avatar}
                      {...stringAvatar(newsItem?.brandFromSalesforce?.brandName)}
                    >
                      {newsItem?.brandFromSalesforce.brandIcon ||
                      newsItem?.brandFromSalesforce?.clientInfo?.iconUrl ? (
                        <div className={styles.avatarContainer}>
                          <img
                            className={styles.avatarImg}
                            src={
                              newsItem?.brandFromSalesforce.brandIcon ||
                              newsItem?.brandFromSalesforce?.clientInfo?.iconUrl
                            }
                            alt="logo"
                          />
                        </div>
                      ) : (
                        <div className={styles.avatarText}>{initials}</div>
                      )}
                      <EditIcon className={styles.editIcon} onClick={openEditProfilePicModal} />
                    </div>
                  ) : (
                    <Tooltip title={'Brand is required to update the image.'} placement="top">
                      <Square className={styles.avatarImg} />
                    </Tooltip>
                  )}
                  <div className={styles.titleContainer}>
                    <div className={styles.titleRow}>
                      <div className={styles.title}>
                        {editingStates.headline ? (
                          <Field
                            name="headline"
                            render={({ input }) => (
                              <TextField {...input} className={styles.titleEditing} multiline />
                            )}
                          />
                        ) : (
                          newsItem?.headline
                        )}
                      </div>
                      {!editingStates.headline ? (
                        <div className={styles.iconContainer}>
                          <EditIcon
                            className={styles.editFieldIcon}
                            onClick={() => toggleEditingState('headline')}
                          />
                          <DeleteIcon
                            className={styles.deleteIcon}
                            onClick={handleToggleDeleteModal}
                          />
                        </div>
                      ) : (
                        <Button onClick={handleSubmit}>Save</Button>
                      )}
                    </div>
                    <div className={styles.titleRow}>
                      <div className={styles.tagsContainer}>
                        {newsItem.type && (
                          <div className={styles.typeTagContainer}>
                            <div
                              style={
                                TAG_COLOR_MAPPING[
                                  newsItem.type as keyof typeof TAG_COLOR_MAPPING
                                ] || TAG_COLOR_MAPPING.Other
                              }
                              className={styles.tags}
                            >
                              {newsItem.type}
                            </div>
                            <CloseChip onClick={handleDeleteTag} className={styles.tagClose} />
                          </div>
                        )}
                        {newsItem.isClient && (
                          <div style={TAG_COLOR_MAPPING[NewsTags.Client]} className={styles.tags}>
                            {NewsTags.Client}
                          </div>
                        )}
                        {newsItem.isProspect && (
                          <div style={TAG_COLOR_MAPPING[NewsTags.Prospect]} className={styles.tags}>
                            {NewsTags.Prospect}
                          </div>
                        )}
                        {newsIndustry && (
                          <div style={TAG_COLOR_MAPPING[NewsTags.Industry]} className={styles.tags}>
                            {newsIndustry}
                          </div>
                        )}
                        <div className={styles.tags}>
                          {isAddTagShown && (
                            <SelectField
                              name="type"
                              className={cn(styles.select, {
                                [styles.fieldOpen]: isAddTagShown,
                              })}
                              variant="outlined"
                              withClear
                              options={TAG_OPTIONS}
                              useFinalForm={false}
                              onChange={handleAddTag}
                            />
                          )}
                          {!newsItem.type && !isAddTagShown && (
                            <div className={styles.addTags} onClick={toggleShowAddTag}>
                              <PlusCircleIcon className={styles.circleIcon} />
                              <div className={styles.addTagsText}>Add tag</div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={styles.date}>
                        {formatDateCalendarNoTime(newsItem.processedAt)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.headlineSummary}>
                  {editingStates.headline ? (
                    <Field
                      name="headlineSummary"
                      onChange={handleSaveDetails}
                      render={({ input }) => (
                        <TextField
                          {...input}
                          placeholder="Add Summary"
                          className={styles.titleEditing}
                          multiline
                        />
                      )}
                    />
                  ) : (
                    newsItem?.headlineSummary
                  )}
                </div>
              </form>
            )
          }}
        />
      </div>

      <div className={styles.body}>
        <Form
          onSubmit={handleToggleForm}
          initialValues={newsItem}
          render={({ handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Box className={styles.fieldsContainer}>
                  <div className={styles.bodyContainer}>
                    <div className={styles.bodyTitle}>
                      <div className={styles.bodyTitleName}>Linked Accounts</div>
                      <div className={styles.bodyTitleEdit}>
                        {editingStates.form ? (
                          <Button color="primary" variant="contained" small type="submit">
                            Close
                          </Button>
                        ) : (
                          <EditIcon className={styles.editFieldIcon} onClick={handleToggleForm} />
                        )}
                      </div>
                    </div>
                    <div className={cn(styles.bodyDetailsBrands, styles.autoCompleteField)}>
                      <div className={styles.bodyItem}>Brand:</div>
                      {editingStates.form ? (
                        <CreatableSelectField
                          className={styles.autocompleteField}
                          name="brandOptionValue"
                          isAsync
                          loadOptions={loadBrandOptions}
                          options={[]}
                          placeholder="Add account"
                          onAddValue={handleOpenNewBrandModal}
                          getOptionLabel={(option: any) => option?.label || option || ''}
                          getOptionValue={(option: any) => option?.value || option || ''}
                          onChangeCustom={handleSaveLinkedAccounts}
                        />
                      ) : (
                        <LinkedAccountItem
                          type="brand"
                          salesforceBaseUrl={newsItem.salesforceBaseUrl}
                          brandInfo={newsItem.brandFromSalesforce}
                        />
                      )}
                    </div>
                    <div className={styles.bodyDetailsBrands}>
                      <div className={styles.bodyItem}>Other Account(s):</div>
                      {editingStates.form ? (
                        <LinkedAccountInput
                          handleAddNewOtherAccount={handleAddNewOtherAccount}
                          newsItem={newsItem}
                          handleDeleteLinkedAccount={handleDeleteLinkedAccount}
                          loadBrandOptions={loadBrandOptions}
                        />
                      ) : (
                        <div>
                          {newsItem.linkedAccounts.map((linkedAccount, index) => (
                            <span key={index}>
                              <LinkedAccountItem
                                type="other"
                                salesforceBaseUrl={newsItem.salesforceBaseUrl}
                                brandInfo={linkedAccount.brandInfo}
                              />
                              {index < newsItem.linkedAccounts.length - 1 && ', '}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {showShareSection && (
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        <div className={styles.bodyTitleName}>Share</div>
                      </div>
                      <div className={styles.industryRow}>
                        <div
                          className={styles.industryHeader}
                          onClick={() => handleIndustryToggle('all')}
                        >
                          <div className={styles.industryCheckboxTitle}>
                            <Checkbox
                              name="all"
                              checked={isAllChecked}
                              color="primary"
                              onChange={handleAllIndustriesToggle}
                            />
                            <div className={styles.industryName}>All</div>
                          </div>
                          <ExpandChevron
                            className={cn(styles.expandChevron, {
                              [styles.chevronOpen]: openIndustries.all,
                            })}
                          />
                        </div>
                        {openIndustries.all && (
                          <div className={styles.clientList}>
                            <KeyboardDatePicker
                              name="recordDate"
                              inputFormat={DATE_FORMAT}
                              withTopLabel={isAllChecked}
                              label="Display Until"
                              placeholder="Display Until"
                              disabled={!isAllChecked}
                              maxDate={moment().add(14, 'days').toDate()}
                              calendarSelectOnly
                              value={
                                isAllChecked
                                  ? moment(newsItem.clientNewsfeedHeadlines?.[0]?.displayUntil)
                                  : null
                              }
                              className={styles.recordDate}
                              useFinalForm={false}
                              minDate={moment().toDate()}
                              onChange={(date) => handleUpdateDisplayUntil(date)}
                            />
                          </div>
                        )}
                      </div>
                      {Object.keys(clientIndustries).map((key) => (
                        <ClientDistributionIndustries
                          industry={key as ClientNewsIndustries}
                          newsItem={newsItem}
                          openIndustries={openIndustries}
                          clientIndustries={clientIndustries}
                          handleIndustryToggle={handleIndustryToggle}
                          handleUpdateIndustry={handleUpdateIndustry}
                          handleUpdateDisplayUntil={handleUpdateDisplayUntil}
                        />
                      ))}
                    </div>
                  )}
                  {newsItem?.type === NewsTags.Fundraise && (
                    <div className={styles.bodyContainer}>
                      <div className={styles.bodyTitle}>
                        <div className={styles.bodyTitleName}>Fundraise</div>
                      </div>
                      <div className={styles.bodyDetails}>
                        <div className={styles.bodyItem}>Investor(s):</div>
                        {editingStates.form ? (
                          <LinkedAccountInput
                            handleAddNewOtherAccount={handleAddNewOtherAccount}
                            newsItem={newsItem}
                            handleDeleteLinkedAccount={handleDeleteLinkedAccount}
                            loadBrandOptions={loadBrandOptions}
                            isFundraise
                          />
                        ) : (
                          investors.map((investor, index) => (
                            <span key={index}>
                              <LinkedAccountItem
                                type="other"
                                salesforceBaseUrl={newsItem.salesforceBaseUrl}
                                brandInfo={investor.brandInfo}
                              />
                              {index < investors.length - 1 && ', '}
                            </span>
                          ))
                        )}
                      </div>
                      <div className={styles.bodyDetails}>
                        <div className={styles.bodyItem}>Amount:</div>
                        {editingStates.form ? (
                          <CurrencyField
                            name="amount"
                            onChange={debounceSaveDetails}
                            useFinalForm={false}
                            className={styles.attributeEditing}
                            value={newsItem?.amount}
                          />
                        ) : (
                          `$${formatPrice(newsItem?.amount)}`
                        )}
                      </div>
                      <div className={styles.bodyDetails}>
                        <div className={styles.bodyItem}>Round:</div>
                        {editingStates.form ? (
                          <Field
                            name="round"
                            render={({ input }) => (
                              <SelectField
                                {...input}
                                className={styles.selectField}
                                variant="outlined"
                                handleClear={handleClearRound}
                                useFinalForm={false}
                                value={newsItem?.round}
                                onChange={handleSaveDetails}
                                options={Object.values(FundraiseRounds).map((item) => ({
                                  label: item,
                                  value: item,
                                }))}
                              />
                            )}
                          />
                        ) : (
                          newsItem?.round
                        )}
                      </div>
                    </div>
                  )}
                  <div className={styles.bodyContainer}>
                    <div className={styles.bodyTitle}>
                      <div className={styles.bodyTitleName}>Source</div>
                    </div>
                    <div className={styles.bodyDetails}>
                      <div className={styles.bodyItem}>News Source:</div>
                      {EMAIL_NEWSLETTER_MAPPING[
                        newsItem.newsfeedEmail?.email as keyof typeof EMAIL_NEWSLETTER_MAPPING
                      ] || ''}
                    </div>
                    <div className={styles.bodyDetails}>
                      <div className={styles.bodyItem}>Link:</div>
                      {editingStates.form ? (
                        <TextField
                          className={styles.textField}
                          useFinalForm={false}
                          value={currentLink}
                          onChange={handleLinkChange}
                          multiline
                          name="link"
                          helperText={linkError}
                        />
                      ) : (
                        <a
                          className={styles.bodyItemLink}
                          href={newsItem?.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {newsItem?.link}
                        </a>
                      )}
                    </div>
                  </div>
                </Box>
              </form>
            )
          }}
        />
      </div>

      {modalStates.isEditProfilePicModalOpen && (
        <UpdateBrandImageModal
          newsItem={newsItem}
          setIsEditProfilePicModalOpen={() =>
            setModalStates((prevState) => ({
              ...prevState,
              isEditProfilePicModalOpen: false,
            }))
          }
        />
      )}

      {(modalStates.isNewBrandModalOpen || modalStates.isNewLinkedAccountModalOpen) && (
        <Modal
          open={modalStates.isNewBrandModalOpen || modalStates.isNewLinkedAccountModalOpen}
          onCancel={handleCloseNewBrandModal}
          title="Create Brand"
          classes={{ root: styles.addBrandModalRoot, body: styles.modalBody }}
        >
          <Form
            onSubmit={handleCreateNewBrand}
            validate={validateSFLink}
            render={({ invalid, handleSubmit, dirty }) => (
              <form onSubmit={handleSubmit}>
                <Box display="flex" flexDirection="column" gap={3}>
                  <Box flex={1}>
                    <InputLabel
                      htmlFor="type"
                      className={cn(genericSs.textLeft, styles.newBrandLabel)}
                    >
                      Paste a valid Salesforce URL
                    </InputLabel>
                    <TextField
                      className={styles.newBrandField}
                      name="salesforceLink"
                      placeholder="Salesforce Link"
                      multiline
                    />
                    {newBrandError && <span>{newBrandError}</span>}
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column" mt={2}>
                  <ModalButton
                    isLoading={isSaving}
                    color="primary"
                    small={false}
                    variant="contained"
                    onClick={handleSubmit}
                    disabled={!dirty || invalid}
                  >
                    Create
                  </ModalButton>
                </Box>
              </form>
            )}
          />
        </Modal>
      )}

      {modalStates.isDeleteModalOpen && (
        <WarningModal
          warningMessage="Are you sure you want to delete this news item?"
          onConfirm={handleDeleteNewsConfirm}
          onCancel={handleToggleDeleteModal}
          confirmText="Delete"
          cancelText="Cancel"
          isLoading={isSaving}
        />
      )}
    </div>
  ) : (
    <TableLoader columnsCount={1} rowsCount={1} height={90} />
  )
}

export default MarketNewsDetails
