import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import useTable from '../../hooks/useTable'
import styles from './MarketNewsTable.module.scss'
import TableLoader from '../Common/TableLoader'
import { INewsfeedData, INewsfeed } from '@common/interfaces/newsfeed'
import { ILoadingData } from '../../redux/types'
import MarketNewsItem from './MarketNewsItem'
import TableContainer from '../Common/TableContainer'
import FilterContainer from '../Filters/FilterContainer'
import { Form } from 'react-final-form'
import Table from '../Common/Table'
import TableBody from '../Common/TableBody'
import { NEWS_FEED_LIST_FILTERS_CONFIG, PER_PAGE } from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import cn from 'classnames'
import { IClientInfo } from '@common/interfaces/client'

interface IProps {
  newsfeedData: ILoadingData<INewsfeedData>
  listNewsfeed: (data: object) => void
  setCurrentNewsItem: (item: INewsfeed | null) => void
  currentNewsItem: INewsfeed
  listBrandOptions: (data: object) => Promise<{ data: any[] }>
  exportNewsfeed: (data: object) => void
  setClientIndustries: (industries: Record<string, IClientInfo[]>) => void
}

const filtersValidate = buildFiltersValidateSchema(NEWS_FEED_LIST_FILTERS_CONFIG)
const filtersDefaults = buildFiltersDefaults(NEWS_FEED_LIST_FILTERS_CONFIG)

const MarketNewsTable = ({
  newsfeedData,
  listNewsfeed,
  setCurrentNewsItem,
  currentNewsItem,
  listBrandOptions,
  exportNewsfeed,
  setClientIndustries,
}: IProps) => {
  const containerRef = useRef(null)

  const {
    data: newsfeed,
    totalItems,
    clientIndustries,
  } = useMemo(
    () => ({
      data: newsfeedData?.data?.data,
      isLoading: newsfeedData?.isLoading,
      totalItems: newsfeedData?.data?.totals?.totalItems,
      clientIndustries: newsfeedData?.data?.clientIndustries,
    }),
    [newsfeedData],
  )

  const { filters, handleFiltersChange, quickFilter, handleQuickFilterChange } = useTable({
    tableId: 'newsfeedTable',
    filtersDefaults,
    sortDefault: {
      field: 'processedAt',
      direction: 'DESC',
    },
  })

  const handleListNewsfeed = useCallback(
    (params?: object) => {
      listNewsfeed({
        perPage: PER_PAGE,
        filters,
        ...params,
      })
    },
    [listNewsfeed, filters],
  )

  useEffect(() => {
    handleListNewsfeed()
  }, [handleListNewsfeed])

  useEffect(() => {
    setClientIndustries(clientIndustries)
  }, [clientIndustries, setClientIndustries])

  const exportRiskRating = useCallback(async () => {
    await exportNewsfeed({
      isExport: true,
      filters,
    })
  }, [exportNewsfeed, filters])

  const loadMore = useCallback(() => {
    handleListNewsfeed({
      loadMore: true,
      page: Math.ceil(newsfeedData.data.data.length / PER_PAGE),
    })
  }, [handleListNewsfeed, newsfeedData])

  const handleNewsHeadlineClick = useCallback(
    (item: INewsfeed) => {
      setCurrentNewsItem(item)
    },
    [setCurrentNewsItem],
  )

  useEffect(() => {
    const isCurrentItemInNewsfeed = newsfeed?.some((item) => item.id === currentNewsItem?.id)

    if (!newsfeed?.length) {
      setCurrentNewsItem(null)
    } else if (!isCurrentItemInNewsfeed) {
      setCurrentNewsItem(newsfeed[0])
      containerRef.current?.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }, [newsfeed, setCurrentNewsItem, currentNewsItem?.id])

  const loadOptions = useCallback(
    async (search: string) => {
      const res = await listBrandOptions({ search })
      return res.data.map(({ label }) => ({
        value: label,
        label,
      }))
    },
    [listBrandOptions],
  )

  const filtersConfig = useMemo(
    () =>
      NEWS_FEED_LIST_FILTERS_CONFIG.map((item) => ({
        ...item,
        options: !['brand', 'referral_source'].includes(item.field) ? item.options : undefined,
        loadOptions: ['brand', 'referral_source'].includes(item.field) ? loadOptions : undefined,
      })),
    [loadOptions],
  )

  const isFilterMenuOpen = useMemo(() => Object.keys(filters).length, [filters])

  return (
    <div className={styles.newsContainer}>
      <TableContainer className={styles.table}>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <div className={styles.filterContainer}>
              <FilterContainer
                filters={filtersConfig}
                handleSubmit={handleSubmit}
                mutators={mutators}
                values={values}
                appliedFilters={filters}
                appliedQuickFilter={quickFilter}
                handleExportAggregation={exportRiskRating}
                handleAppliedQuickFilterChange={handleQuickFilterChange}
              />
            </div>
          )}
        />
        <Table
          classes={{
            root: styles.tableRoot,
          }}
        >
          {newsfeed?.length > 0 ? (
            <TableBody
              ref={containerRef}
              className={cn(styles.container, { [styles.containerWithFilter]: isFilterMenuOpen })}
              id="marketNewsTable"
            >
              <InfiniteScroll
                dataLength={newsfeed?.length}
                className={styles.infiniteScroll}
                next={loadMore}
                hasMore={newsfeed?.length < totalItems}
                loader={<TableLoader columnsCount={1} rowsCount={1} />}
                scrollableTarget="marketNewsTable"
              >
                {newsfeed?.map((item) => (
                  <MarketNewsItem
                    isCurrentItem={currentNewsItem?.id === item.id}
                    key={item.id}
                    item={item}
                    handleNewsHeadlineClick={handleNewsHeadlineClick}
                  />
                ))}
              </InfiniteScroll>
            </TableBody>
          ) : null}
        </Table>
      </TableContainer>
    </div>
  )
}

export default MarketNewsTable
