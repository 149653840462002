import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'
import { generatePath, Link as RouterLink } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import cn from 'classnames'
import styles from './ClientDashboard.module.scss'
import Link from '@mui/material/Link'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IClientInfo, ClientInfoStatus } from '@common/interfaces/client'
import { formatDate, formatPrice, formatPercent, formatAmount } from '../../helpers/helpers'
import { stringAvatar } from '../Notes/helpers/helpers'
import { ROUTES } from '../../constants/routes'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { MenuIcon, SalesforceLink, SettingsIcon } from '../Common/Icons'
import Breadcrumbs from '../Common/Breadcrumbs'
import ArchivedChip from '../Client/ArchivedChip'
import CodatSyncChip from '../Client/CodatSyncChip'
import { usePermissions } from '../../helpers/permissionContext'
import { formatMaxNumber } from '../../helpers/helpers'

interface IProps {
  clientId?: string
  clientInfo: IClientInfo
  isLoading?: boolean
  loginAsClient: (clientId: string) => void
  isAdminRightsRole: boolean
}

const HeaderLoader = ({ isLoading, value }: { isLoading: boolean; value: string }) => {
  return isLoading ? <Skeleton width={120} /> : <>{value || '-'}</>
}

const ClientDashboardHeader = ({
  clientInfo,
  clientId,
  isLoading,
  loginAsClient,
  isAdminRightsRole,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  useSetPageTitle(clientInfo?.clientName || '')
  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const { isParticipant } = usePermissions()

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.CLIENT_MANAGEMENT,
        Icon: HomeIcon,
      },
      {
        title: 'Clients',
        link: ROUTES.CLIENT_MANAGEMENT,
      },
      {
        link: generatePath(ROUTES.CLIENT_PAGE, { id }),
        title: clientInfo?.clientName,
      },
    ]
  }, [clientInfo, id])

  const initials = useMemo(() => {
    const name = clientInfo?.clientName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [clientInfo])

  const isClearbitLogo = useMemo(() => {
    return clientInfo?.iconUrl?.includes('logo.clearbit.com')
  }, [clientInfo])

  const handleLoginAsClient = useCallback(() => {
    if (isAdminRightsRole) {
      loginAsClient(clientId)
    }
  }, [clientId, loginAsClient, isAdminRightsRole])

  const isCurrentOrDueDiligence = useMemo(() => {
    return [ClientInfoStatus.Current, ClientInfoStatus.DueDiligence].includes(
      clientInfo?.clientStatus,
    )
  }, [clientInfo])

  const isPastOrArchived = useMemo(() => {
    return [ClientInfoStatus.Past, ClientInfoStatus.Archived].includes(clientInfo?.clientStatus)
  }, [clientInfo])

  const settingsLink = useMemo(() => {
    return generatePath(ROUTES.CLIENT_SETUP, { id: clientId })
  }, [clientId])

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  return (
    <>
      <Grid container item xs={12} data-cy="client-dashboard-header">
        <Grid item xs={6} justifyContent={'flex-start'}>
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        </Grid>
        {!isParticipant && (
          <Grid
            className={styles.infoItems}
            item
            rowSpacing={1}
            columnSpacing={2}
            container
            xs={6}
            justifyContent={'flex-end'}
          >
            <Grid item>
              <ArchivedChip clientInfo={clientInfo} />
            </Grid>
            <Grid item>
              <CodatSyncChip clientInfo={clientInfo} />
            </Grid>
            <Grid item>
              <SalesforceLink link={clientInfo?.salesforceLink} />
            </Grid>
            <Grid item>
              <SettingsIcon link={settingsLink} title="Client settings" />
            </Grid>
            <Grid item>
              {isAdminRightsRole && (
                <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} />
              )}
              <Menu open={isActionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
                <MenuItem onClick={handleLoginAsClient}>Client View</MenuItem>
                <Link
                  className={styles.menuItemLink}
                  component={RouterLink}
                  to={generatePath(ROUTES.DUE_DILIGENCE_LOAN_COMMITTEE_REPORT_PAGE, { id })}
                >
                  <MenuItem onClick={handleCloseMenu}>LCR</MenuItem>
                </Link>
              </Menu>
            </Grid>
          </Grid>
        )}
      </Grid>
      <div className={styles.headerContainer}>
        <div className={styles.avatarGridContainer}>
          {isLoading ? (
            <Skeleton width={140} height={140} />
          ) : (
            clientInfo && (
              <div
                className={styles.clientDashboardHeaderAvatar}
                {...stringAvatar(clientInfo?.clientName)}
              >
                {clientInfo?.iconUrl ? (
                  <img
                    className={cn({
                      [styles.clientDashboardHeaderAvatarImg]: !isClearbitLogo,
                    })}
                    src={clientInfo?.iconUrl}
                    alt="logo"
                  />
                ) : (
                  initials
                )}
              </div>
            )
          )}
        </div>
        <div className={styles.contentContainer}>
          <Grid container spacing={1}>
            <Grid container item xs={12} justifyContent="flex-start" alignItems={'flex-start'}>
              <Grid item xs={2}>
                <div className={styles.clientDashboardHeaderTitle}>ABL loan balance</div>
                <div className={styles.clientDashboardHeaderValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={` $ ${formatPrice(clientInfo?.currentAmount)}`}
                  />
                </div>
              </Grid>
              {isParticipant && (
                <>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Exposure</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={`$ ${formatPrice(clientInfo?.totalParticipantExposureAmount)}`}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Current exposure</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={formatPercent(
                          clientInfo?.totalParticipantExposureAmount / clientInfo?.currentAmount ||
                            0,
                          2,
                          100,
                        )}
                      />
                    </div>
                  </Grid>
                  {isParticipant && (
                    <Grid item xs={2}>
                      <div className={styles.clientDashboardHeaderTitle}>Contractual exposure</div>
                      <div className={styles.clientDashboardHeaderValue}>
                        <HeaderLoader
                          isLoading={isLoading}
                          value={formatPercent(
                            clientInfo?.participantContractualExposure || 0,
                            2,
                            100,
                          )}
                        />
                      </div>
                    </Grid>
                  )}
                </>
              )}

              <Grid item xs={2}>
                <div className={styles.clientDashboardHeaderTitle}>Max line amount</div>
                <div className={styles.clientDashboardHeaderValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={` $ ${formatAmount(clientInfo?.maxLineAmount)}`}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.clientDashboardHeaderTitle}>Contract date</div>
                <div className={styles.clientDashboardHeaderValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={formatDate(
                      isParticipant
                        ? clientInfo?.participationContractDate
                        : clientInfo?.contractDate,
                    )}
                  />
                </div>
              </Grid>
              {!isParticipant && (
                <>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Line turn</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={formatMaxNumber(clientInfo?.lineTurn, 50)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Interest rate</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={formatPercent(clientInfo?.interestRate, 2, 1)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>AR advance</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={formatPercent(clientInfo?.arAdvance, 2, 1)}
                      />
                    </div>
                  </Grid>
                </>
              )}
            </Grid>
            <div className={styles.infoDivider}></div>

            <Grid container item xs={12} justifyContent="space-between">
              {!isParticipant &&
                (clientInfo?.totalNFE ? (
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>NFE</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={` $ ${formatPrice(clientInfo?.totalNFE)}  (${formatPercent(
                          clientInfo.totalNFE / clientInfo.currentAmount,
                          2,
                          100,
                        )})`}
                      />
                    </div>
                  </Grid>
                ) : (
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Total loan balance</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={` $ ${formatPrice(clientInfo?.totalLoanBalance)}`}
                      />
                    </div>
                  </Grid>
                ))}
              {isParticipant && (
                <>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Line turn</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={
                          clientInfo?.lineTurn && clientInfo?.lineTurn >= 0
                            ? clientInfo?.lineTurn.toFixed(2)
                            : '-'
                        }
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Interest rate</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={formatPercent(clientInfo?.interestRate, 2, 1)}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>AR advance</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={formatPercent(clientInfo?.arAdvance, 2, 1)}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={2}>
                {isCurrentOrDueDiligence && (
                  <>
                    <div className={styles.clientDashboardHeaderTitle}>Minimum</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={` $ ${formatAmount(clientInfo?.minimumLineAmount)}`}
                      />
                    </div>
                  </>
                )}
                {isPastOrArchived && (
                  <>
                    <div className={styles.clientDashboardHeaderTitle}>Servicing fee</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={` $ ${formatPrice(clientInfo?.servicingFee)}`}
                      />
                    </div>
                  </>
                )}
              </Grid>
              {!isParticipant && (
                <>
                  <Grid item xs={2}>
                    {isCurrentOrDueDiligence && clientInfo?.minimumInterestEffectiveDate ? (
                      <>
                        <div className={styles.clientDashboardHeaderTitle}>
                          Min interest waived until
                        </div>
                        <div className={styles.clientDashboardHeaderValue}>
                          <HeaderLoader
                            isLoading={isLoading}
                            value={
                              clientInfo?.minimumInterestEffectiveDate
                                ? formatDate(clientInfo?.minimumInterestEffectiveDate)
                                : '-'
                            }
                          />
                        </div>
                      </>
                    ) : isCurrentOrDueDiligence ? (
                      <>
                        <div className={styles.clientDashboardHeaderTitle}>Maturity date</div>
                        <div className={styles.clientDashboardHeaderValue}>
                          <HeaderLoader
                            isLoading={isLoading}
                            value={formatDate(clientInfo?.maturityDate)}
                          />
                        </div>
                      </>
                    ) : null}

                    {isPastOrArchived && (
                      <>
                        <div className={styles.clientDashboardHeaderTitle}>Payoff date</div>
                        <div className={styles.clientDashboardHeaderValue}>
                          <HeaderLoader
                            isLoading={isLoading}
                            value={
                              clientInfo?.payoffDate ? formatDate(clientInfo?.payoffDate) : '-'
                            }
                          />
                        </div>
                      </>
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>Risk rating</div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={clientInfo?.risk ? clientInfo?.risk.toFixed(2) : '-'}
                      />
                    </div>
                  </Grid>
                </>
              )}
              <Grid item xs={2}>
                <div className={styles.clientDashboardHeaderTitle}>Inv. advance</div>
                <div className={styles.clientDashboardHeaderValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={formatPercent(clientInfo?.inventoryAdvanceRateCost, 2, 1)}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.clientDashboardHeaderTitle}>NOLV</div>
                <div className={styles.clientDashboardHeaderValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={formatPercent(clientInfo?.nolv, 2, 1)}
                  />{' '}
                </div>
              </Grid>
            </Grid>

            {clientInfo?.termLoans && clientInfo?.termLoans.length > 0 && !isParticipant && (
              <Grid container item xs={12} justifyContent="flex-start">
                {(clientInfo?.termLoans || []).map(({ id, collateralName, loanBalance }) => (
                  <Grid item xs={2}>
                    <div className={styles.clientDashboardHeaderTitle}>
                      {collateralName} Loan Balance
                    </div>
                    <div className={styles.clientDashboardHeaderValue}>
                      <HeaderLoader
                        isLoading={isLoading}
                        value={` $ ${formatPrice(loanBalance)}`}
                      />
                    </div>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </div>
      </div>
    </>
  )
}

export default ClientDashboardHeader
