import { prefix } from './actions'
import { IRootState } from '../reducer'

export const isLoading = (state: IRootState) => state[prefix].loadingArray.length > 0

export const data = (state: IRootState) => state[prefix].data

export const ongoingReporting = (state: IRootState) => state[prefix].ongoingReporting

export const dataConsolidated = (state: IRootState) => state[prefix].dataConsolidated

export const dataSummary = (state: IRootState) => state[prefix].dataSummary

export const dataSummaryHeaders = (state: IRootState) => state[prefix].dataSummaryHeaders

export const dataSummaryHeadersColors = (state: IRootState) =>
  state[prefix].dataSummaryHeadersColors

export const dataSummaryGrowth = (state: IRootState) => state[prefix].dataSummaryGrowth

export const reportingUpdatesList = (state: IRootState) => state[prefix].reportingUpdatesList

export const skuDetails = (state: IRootState) => state[prefix].skuDetails

export const skuVisualization = (state: IRootState) => state[prefix].skuVisualization

export const skuVisualizationsFilters = (state: IRootState) =>
  state[prefix].skuVisualizationsFilters

export const salesByDistributionGraph = (state: IRootState) =>
  state[prefix].salesByDistributionGraph
