import React, { useEffect, useMemo } from 'react'
import { useParams, useHistory, generatePath, useLocation } from 'react-router'
import queryString from 'query-string'
import Box from '@mui/material/Box'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import Loader from '../../components/Loader'
import { IBorrowingBase } from '@common/interfaces/bbc'
import { LOAN_TYPES } from '@common/constants/client'
import { ROUTES } from '../../constants/routes'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { formatDate } from '../../helpers/helpers'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'

interface IProps {
  isLoading: boolean
  bbc: IBorrowingBase
  show: (id: string) => void
  changePageTitle: (title: string) => void
}

const BBCCollateralEligibilityPage = ({ isLoading, bbc, show, changePageTitle }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const { search }: { search: string } = useLocation()
  const { bbcStep } = queryString.parse(search)

  useLoadInfo(id, bbc, show)
  useSetPageTitle(
    bbc
      ? `${bbc.clientName} ${bbc?.isTest ? 'Test ' : ''}BBC ${formatDate(bbc.recordDate)}`
      : 'BBC',
  )

  useEffect(() => {
    if (bbc) {
      history.push(
        `${generatePath(
          bbc?.clientInfo?.loanType === LOAN_TYPES.inventory
            ? ROUTES.BBC_COLLATERAL_ELIGIBILITY_INVENTORY
            : ROUTES.BBC_COLLATERAL_ELIGIBILITY_RECEIVABLES,
          { id },
        )}?bbcStep=${bbcStep}`,
      )
    }
  }, [bbc, history, id, bbcStep])

  const breadcrumbs = useMemo(() => {
    return [
      {
        link: ROUTES.HOMEPAGE,
        Icon: HomeIcon,
      },
      {
        link: ROUTES.ACTIVITY_QUEUE,
        title: 'Operations',
      },
      {
        title: bbc?.clientName,
        link: bbc?.clientInfo?.id
          ? generatePath(ROUTES.CLIENT_PAGE, { id: bbc?.clientInfo?.id })
          : '',
      },
      {
        link: generatePath(
          ROUTES[bbcStep as string] ||
            (bbc?.clientInfo?.loanType === LOAN_TYPES.inventory
              ? ROUTES.BBC_SUMMARY_INVENTORY
              : ROUTES.BBC_SUMMARY_RECEIVABLES),
          { id },
        ),
        title: bbc
          ? `${bbc?.isTest ? 'Test ' : ''}BBC ${formatDate(bbc.recordDate)}`
          : `${bbc?.isTest ? 'Test ' : ''}BBC`,
      },
      {
        link: `${generatePath(ROUTES.BBC_COLLATERAL_ELIGIBILITY_RECEIVABLES, {
          id,
        })}?bbcStep=${bbcStep}`,
        title: 'Collateral Eligibility',
      },
    ]
  }, [bbc, id, bbcStep])

  return (
    <Box py={1} pr={2}>
      {isLoading && <Loader />}

      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </Box>
  )
}

export default BBCCollateralEligibilityPage
