import React, { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router'

import Grid from '@mui/material/Grid'
import chunk from 'lodash/chunk'
import cn from 'classnames'

import styles from './DueDiligenceCompanyBackgroundPage.module.scss'
import Card from '../../components/Common/Card'
import { IDueDiligence } from '@common/interfaces/dueDiligence'
import { formatDateYear } from '../../helpers/helpers'
import { VisibilityIcon } from '../../components/Common/Icons'
import DueDiligencePageHeader from '../../components/DueDiligencePageHeader'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { DEFAULT_EMPTY_ARRAY_PROP } from '../../constants/common'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  show: (id: string) => void
  isClientPage?: boolean
}

interface IApplicationData {
  label: string
  value?: string | number | boolean
  values?: string[]
  size?: number
  hidden?: boolean
}

const DueDiligenceInfoApplicationDataBooleanItem = ({
  label,
  value,
  showCommentOn,
  comment,
}: {
  label: string
  value: boolean
  showCommentOn: boolean
  comment?: string
}) => {
  return (
    <Grid container mx={2} pt={2} rowGap={1.5}>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="space-between">
        <div className={styles.booleanLabel}>{label}</div>

        <div
          className={cn(styles.booleanValue, {
            [styles.booleanValueTrue]: value,
          })}
        >
          {value ? 'Yes' : 'No'}
        </div>
      </Grid>
      {showCommentOn === value && (
        <Grid item xs={12} display="flex" className={styles.booleanComment}>
          {comment || '-'}
        </Grid>
      )}
    </Grid>
  )
}

export const DueDiligenceInfoApplicationDataItem = ({
  label,
  value = '',
  values = DEFAULT_EMPTY_ARRAY_PROP,
  size = 4,
  hidden = false,
}: IApplicationData) => {
  const [isVisible, setIsVisible] = useState(false)

  const toggleVisibility = useCallback(() => {
    setIsVisible((visible) => !visible)
  }, [])

  return (
    <Grid item xs={size}>
      <div className={styles.label}>{label}</div>
      <div className={styles.valueWrapper}>
        {values?.length > 0 ? (
          <div className={styles.valuesContainer}>
            {values.map((item) => (
              <div key={item} className={styles.valuesItem}>
                {item}
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.value}>
            {hidden && !isVisible
              ? '*********'
              : typeof value === 'boolean'
              ? value
                ? 'Yes'
                : 'No'
              : value || '-'}
          </div>
        )}
        {hidden && <VisibilityIcon isVisible={isVisible} action={toggleVisibility} />}
      </div>
    </Grid>
  )
}

export const DueDiligenceInfoApplicationData = ({
  data,
  withBottomBorder = false,
}: {
  data: IApplicationData[]
  withBottomBorder?: boolean
}) => {
  const rows = useMemo(() => chunk(data, 3), [data])

  return (
    <>
      {rows.map((columns, rowIndex) => (
        <Grid
          key={rowIndex}
          container
          mx={2}
          py={2}
          className={cn({ [styles.withBorder]: rowIndex !== rows.length - 1 || withBottomBorder })}
        >
          {columns.map((props, columnIndex) => (
            <DueDiligenceInfoApplicationDataItem key={columnIndex} {...props} />
          ))}
        </Grid>
      ))}
    </>
  )
}

const DueDiligenceCompanyBackgroundPage = ({ dueDiligenceInfo, show, isClientPage }: IProps) => {
  const { id } = useParams<{ id: string }>()
  useLoadInfo(id, dueDiligenceInfo, show)
  const data = useMemo(
    () =>
      dueDiligenceInfo
        ? [
            { label: 'Company name', value: dueDiligenceInfo.clientName },
            {
              label: 'Legal company name',
              value: dueDiligenceInfo.dueDiligenceClientInfo?.fullLegalName,
            },
            {
              label: 'HQ address',
              value: [
                dueDiligenceInfo.dueDiligenceClientInfo?.billingStreet || '',
                dueDiligenceInfo.dueDiligenceClientInfo?.billingCity || '',
                dueDiligenceInfo.dueDiligenceClientInfo?.billingState || '',
                dueDiligenceInfo.dueDiligenceClientInfo?.billingPostalCode || '',
                dueDiligenceInfo.dueDiligenceClientInfo?.billingCountry || '',
              ]
                .filter(Boolean)
                .join(', '),
            },
            {
              label: '# of Employees',
              value: dueDiligenceInfo.dueDiligenceClientInfo?.employeeCount,
            },
            {
              label: 'Year founded',
              value: dueDiligenceInfo.dueDiligenceClientInfo?.establishedDate
                ? formatDateYear(dueDiligenceInfo.dueDiligenceClientInfo?.establishedDate)
                : '',
            },
            {
              label: 'Company phone number',
              value: dueDiligenceInfo.dueDiligenceClientInfo?.phone,
            },
            {
              label: 'State of formation',
              value: dueDiligenceInfo.dueDiligenceClientInfo?.stateOfFormation,
            },
            {
              label: 'Federal tax ID',
              value: (dueDiligenceInfo.dueDiligenceClientInfo?.federalTaxId || '').replace(
                /(\d{2})(.*)/,
                '$1-$2',
              ),
              hidden: true,
            },
          ]
        : [],
    [dueDiligenceInfo],
  )

  if (!dueDiligenceInfo) {
    return null
  }

  return (
    <div>
      {!isClientPage && <DueDiligencePageHeader />}
      <Grid container item xs={12}>
        <Grid item xs={12} mb={2} px={2}>
          <Card title={'Background'}>
            <DueDiligenceInfoApplicationData data={data} withBottomBorder />
            {!isClientPage && (
              <>
                <DueDiligenceInfoApplicationDataBooleanItem
                  label="Are you current with all local, state, and federal taxes?"
                  value={dueDiligenceInfo?.dueDiligenceClientInfo?.isWithAllTaxes}
                  comment={dueDiligenceInfo?.dueDiligenceClientInfo?.isWithAllTaxesComment}
                  showCommentOn={false}
                />
                <DueDiligenceInfoApplicationDataBooleanItem
                  label="Are there any pending judgments or litigations?"
                  value={dueDiligenceInfo?.dueDiligenceClientInfo?.isPendingJudgement}
                  comment={dueDiligenceInfo?.dueDiligenceClientInfo?.isPendingJudgementComment}
                  showCommentOn
                />
                <DueDiligenceInfoApplicationDataBooleanItem
                  label="Are any of your assets pledged as collateral?"
                  value={dueDiligenceInfo?.dueDiligenceClientInfo?.isAssetsPledged}
                  comment={dueDiligenceInfo?.dueDiligenceClientInfo?.isAssetsPledgedComment}
                  showCommentOn
                />
              </>
            )}
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default DueDiligenceCompanyBackgroundPage
