import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import CapTableSummary from './CapTableSummaryTable'
import { listCapTable } from '../../redux/capTable/actions'
import { capTableSummary } from '../../redux/capTable/selectors'
import { clientInfo } from '../../redux/clientInfo/selectors'

const selector = createStructuredSelector({
  clientInfo,
  capTableSummary,
})
const actions = {
  listCapTable,
}

export { CapTableSummary }

export default connect(selector, actions)(CapTableSummary)
