import React, { useMemo, useEffect, useState } from 'react'
import styles from './FinancialTrendsGraph.module.scss'
import { Chart } from 'react-chartjs-2'
import {
  FinancialKeys,
  CLIENT_FINANCIALS_FIELDS_MAPPING,
  IOngoingReportingSummaryData,
  IOngoingReportingSummaryHeaders,
  OngoingReportingType,
  CLIENT_FINANCIALS_PERCENT_FIELDS,
  CLIENT_FINANCIALS_RATIO_FIELDS,
  ReportingPeriods,
} from '@common/interfaces/bbc'
import { formatAmount, formatPercent, getRGBFFromHex } from '../../helpers/helpers'
import Skeleton from '@mui/material/Skeleton'
import classNames from 'classnames'
import { BORDER_RADIUS, COLORS, FONT_SIZE, TOOLTIP } from '../../constants/graphs'

const SECOND_AXIS_FIELDS = [...CLIENT_FINANCIALS_PERCENT_FIELDS, ...CLIENT_FINANCIALS_RATIO_FIELDS]

const LINE_FIELDS = [...SECOND_AXIS_FIELDS, 'collections']

interface IProps {
  financialData: Partial<Record<OngoingReportingType, IOngoingReportingSummaryData>>
  dataSummaryHeaders: IOngoingReportingSummaryHeaders
  statementType: OngoingReportingType
  fieldsToDisplay: FinancialKeys[]
  reportingPeriod: ReportingPeriods
  fullScreen: boolean
}

const FinancialTrendsGraph = ({
  financialData,
  statementType,
  fieldsToDisplay,
  dataSummaryHeaders,
  fullScreen,
  reportingPeriod,
}: IProps) => {
  const [initialLoading, setInitialLoading] = useState(true)

  const labels = useMemo(() => {
    return dataSummaryHeaders?.[statementType]?.map((header: string) => {
      let newHeader = header
      //Regex replace 20XX with XX
      if (reportingPeriod === ReportingPeriods.Monthly) {
        newHeader = newHeader.replace(/20\d{2}/g, (year) => year.slice(2))
      }
      if (newHeader.includes('Proj')) {
        return newHeader.split('Proj')[0].trim() + ' Proj'
      }
      return newHeader
    })
  }, [dataSummaryHeaders, statementType, reportingPeriod])

  const positionsOfProj: number[] = useMemo(() => {
    return labels
      ?.map((label: string) => {
        return label.includes('Proj') ? labels.indexOf(label) : null
      })
      ?.filter((index: number | null) => index !== null)
  }, [labels])

  useEffect(() => {
    if (financialData) {
      setInitialLoading(false)
    }
  }, [financialData])

  const chartData = useMemo(() => {
    if (financialData) {
      return {
        labels,
        stepped: true,
        datasets: [
          ...fieldsToDisplay.map((field) => ({
            label: CLIENT_FINANCIALS_FIELDS_MAPPING[field],
            data: financialData[statementType]?.[field],
            backgroundColor: financialData[statementType]?.[field]?.map((dataPoint, index) => {
              if (positionsOfProj.includes(index)) {
                return getRGBFFromHex(COLORS[fieldsToDisplay.indexOf(field)], 0.7)
              }
              return getRGBFFromHex(COLORS[fieldsToDisplay.indexOf(field)], 1)
            }),
            borderRadius: BORDER_RADIUS,
            type: LINE_FIELDS.includes(field) ? ('line' as const) : ('bar' as const),
            order: LINE_FIELDS.includes(field) ? 0 : 1,
            pointStyle: 'line',
            pointRadius: 20,
            pointHoverRadius: 20,
            pointHoverBorderWidth: 3,
            pointBorderWidth: 3,
            pointBorderColor: COLORS[fieldsToDisplay.indexOf(field)],
            showLine: false,
            yAxisID: SECOND_AXIS_FIELDS.includes(field) ? 'y1' : 'y',
          })),
        ],
      }
    } else {
      return null
    }
  }, [labels, financialData, fieldsToDisplay, statementType, positionsOfProj])

  const chartOptions = useMemo(() => {
    return {
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
          },
        },
        y: {
          stacked: false,
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatAmount(value) ? `$${formatAmount(value)}` : `$0`
            },
          },
        },
        y1: {
          display: SECOND_AXIS_FIELDS.some((field) => fieldsToDisplay.includes(field)),
          grid: {
            display: false,
          },
          ticks: {
            font: {
              size: FONT_SIZE,
            },
            callback: (value: any) => {
              return formatPercent(value) ? `${formatPercent(value)}` : `0%`
            },
          },
          position: 'right' as const,
        },
      },
      plugins: {
        legend: {
          position: 'top' as const,
          labels: {
            font: {
              size: FONT_SIZE,
              weight: '500',
            },
            color: 'black',
            boxWidth: 20,
            boxHeight: 20,
          },
        },
        tooltip: {
          ...TOOLTIP,
          itemSort: (a: any, b: any) => {
            return a.datasetIndex - b.datasetIndex
          },
          callbacks: {
            label: (tooltipItem: any) => {
              const datasetIndex = tooltipItem.datasetIndex
              const yAxisID = chartData.datasets[datasetIndex].yAxisID
              const value = tooltipItem.raw
              const title = chartData.datasets[datasetIndex].label

              if (yAxisID === 'y1') {
                return formatPercent(value) ? `${title}: ${formatPercent(value)}` : `${title}: 0%`
              } else {
                return tooltipItem.yLabel
              }
            },
          },
        },
      },
    }
  }, [fieldsToDisplay, chartData])

  return (
    <div
      className={classNames(styles.chartContainer, {
        [styles.fullScreen]: fullScreen,
      })}
    >
      {initialLoading ? (
        <Skeleton variant="rectangular" width="100%" height="100%" />
      ) : (
        chartData && (
          <Chart type="bar" className={styles.chart} data={chartData} options={chartOptions} />
        )
      )}
    </div>
  )
}

export default FinancialTrendsGraph
