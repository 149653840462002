import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router'
import { generatePath } from 'react-router-dom'
import Grid from '@mui/material/Grid'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IDueDiligence, IDueDiligenceReportingFlow } from '@common/interfaces/dueDiligence'
import { ROUTES } from '../../constants/routes'
import Breadcrumbs from '../../components/Common/Breadcrumbs'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import InvetoryMappingTable from '../../components/InvetoryMappingTable'

import DueDiligenceProcessDocumentsMappingLoader from '../../components/DueDiligenceProcessDocumentsMappingLoader'
import { OngoingReportingType } from '@common/interfaces/bbc'
import { ILoadingData } from '../../redux/types'
import { ClientInfoStatus } from '@common/interfaces/client'

interface IProps {
  dueDiligenceInfo: IDueDiligence
  dueDiligenceReportingFlowsData: ILoadingData<{ data: IDueDiligenceReportingFlow[] }>
  show: (id: string) => void
  showDocuments: (id: string, type: string, params: object) => void
  processDocuments: (id: string, type: string, data: object) => void
}

const DueDiligenceProcessDocumentsInventoryPage = ({
  dueDiligenceInfo,
  dueDiligenceReportingFlowsData,
  show,
  showDocuments,
  processDocuments,
}: IProps) => {
  const { id, type: routeType } = useParams<{ id: string; type: string }>()
  const { pathname } = useLocation()
  const history = useHistory()
  const [isProcessing, setIsProcessing] = useState(false)

  useLoadInfo(id, dueDiligenceInfo, show)
  useSetPageTitle(dueDiligenceInfo?.clientName || '')

  useEffect(() => {
    showDocuments(id, OngoingReportingType.Inventory, {})
  }, [id, showDocuments])

  const readOnly = useMemo(
    () => dueDiligenceInfo?.clientStatus !== ClientInfoStatus.DueDiligence,
    [dueDiligenceInfo],
  )

  const breadcrumbs = useMemo(
    () => [
      {
        link: ROUTES.DUE_DILIGENCE_HOME,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE, { id }),
        title: dueDiligenceInfo?.clientName,
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }),
        title: 'Process Documents',
      },
      {
        link: generatePath(ROUTES.DUE_DILIGENCE_PROCESS_DOCUMENTS_LIST, { id, type: routeType }),
        title: 'Inventory Reports',
      },
      {
        link: pathname,
        title: 'Map Inventory Reports',
      },
    ],
    [dueDiligenceInfo, id, routeType, pathname],
  )

  const handleGoNext = useCallback(async () => {
    if (readOnly) {
      history.push(generatePath(ROUTES.DUE_DILIGENCE_ANALYSIS_QUEUE, { id }))
      return
    }
    setIsProcessing(true)
    await processDocuments(id, OngoingReportingType.Inventory, {
      flowIds: dueDiligenceReportingFlowsData?.data?.data
        .filter(({ isCompleted }) => isCompleted)
        .map(({ id }) => id),
      isSubmit: true,
    })
    setIsProcessing(false)
  }, [id, processDocuments, dueDiligenceReportingFlowsData, readOnly, history])

  if (!dueDiligenceInfo) {
    return <DueDiligenceProcessDocumentsMappingLoader />
  }

  return (
    <Grid container py={3} pr={2} alignItems="flex-start" justifyContent="center" rowSpacing={2}>
      <Grid item container xs={12} justifyContent="space-between">
        <Grid item container xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>
      <InvetoryMappingTable
        isDueDiligence
        handleGoNext={handleGoNext}
        isProcessing={isProcessing}
        readOnly={readOnly}
        isInventory
      />
    </Grid>
  )
}

export default DueDiligenceProcessDocumentsInventoryPage
