import React, { useCallback, useMemo, useState } from 'react'
import cn from 'classnames'
import Grid from '@mui/material/Grid'
import Popover from '@mui/material/Popover'
import InputAdornment from '@mui/material/InputAdornment'

import styles from './FilterMenu.module.scss'

import TextField from '../../Common/TextField'
import KeyboardDatePicker from '../../Common/KeyboardDatePicker'
import KeyboardDateTimePicker from '../../Common/KeyboardDateTimePicker'
import { DATE_FORMAT, DATE_TIME_FORMAT, DEFAULT_EMPTY_ARRAY_PROP } from '../../../constants/common'
import Button from '../../Common/Button'
import { formatDate, formatDateTime } from '../../../helpers/helpers'
import SelectField from '../../Common/FilterSelectField'
import { titleCase } from 'title-case'
import {
  IFilter,
  IQuickFilter,
  SHORT_FILTERS_COUNT,
  SHORT_FILTERS_SORTING_TYPES,
} from '@common/constants/filters'
import CurrencyField from '../../Common/CurrencyField'
import Autocomplete from '../../Common/Autocomplete'
import { ReactComponent as FilterDownIcon } from '@assets/images/filter-down-icon.svg'
import { ReactComponent as ListIcon } from '@assets/images/list-rectangle.svg'
import { ReactComponent as PoundIcon } from '@assets/images/pound-icon.svg'
import { ReactComponent as DollarIcon } from '@assets/images/dollar.svg'
import { ReactComponent as PercentIcon } from '@assets/images/percent-icon.svg'
import { ReactComponent as CalendarIcon } from '@assets/images/calendar-icon.svg'
import genericSs from '@styles/generic.module.scss'

interface IProps {
  isOpen: boolean
  anchorEl: HTMLButtonElement | null
  onClose: () => void
  className?: string
  filters: IFilter[]
  handleSubmit: () => void
  values: any
  appliedFilters?: any
  filtersSize?: number
  quickFilters?: IQuickFilter[]
  appliedQuickFilter?: string | null
  handleQuickFilterApply: (filter: IQuickFilter) => void
  quickFiltersOutside?: boolean
}

interface QuickFilterProps {
  filter: IQuickFilter
  isSelected: boolean
  handleApply: (filter: IQuickFilter) => void
}

export const QuickFilter = ({ filter, isSelected, handleApply }: QuickFilterProps) => {
  const handleClick = useCallback(() => {
    handleApply(filter)
  }, [handleApply, filter])

  return (
    <span
      className={cn(genericSs.grayCard, styles.quickFiltersItem, {
        [styles.quickFiltersItemActive]: isSelected,
      })}
      onClick={handleClick}
    >
      {filter.title}
    </span>
  )
}

interface FilterProps {
  filter: IFilter
  values: any
  handleSubmit: () => void
  handleBlur?: (e: React.FocusEvent) => void
  filtersSize?: number
}

const TextFilter = ({ filter, handleBlur }: FilterProps) => {
  return (
    <div>
      <TextField
        name={filter.field}
        size="filterInput"
        placeholder={filter?.title || filter?.field}
        fullWidth
        onBlur={handleBlur}
        InputProps={{
          startAdornment: <ListIcon className={styles.startAdornment} />,
        }}
      />
    </div>
  )
}

const TypeFilter = ({ filter, handleSubmit, handleBlur, values }: FilterProps) => {
  const options = filter?.options?.filter((option) => option.value !== 'all')

  return (
    <div>
      <SelectField
        multiple={filter?.isMultiple}
        name={filter?.field}
        options={options}
        fullWidth={false}
        isFilterMenu
        handleSubmit={handleSubmit}
        values={values}
        onBlur={handleBlur}
        placeholder={filter?.title || filter?.field}
        label={filter?.title || filter?.field}
        withTopLabel
        startAdornment={<ListIcon className={styles.startAdornment} />}
      />
    </div>
  )
}

const AutocompleteFilter = ({ filter, handleBlur, values, handleSubmit }: FilterProps) => {
  const handleOnChange = useCallback(
    (e) => {
      const isChip = ['svg', 'path'].includes(e?.target?.tagName)
      if (isChip) {
        handleSubmit()
      }
    },
    [handleSubmit],
  )

  const isSelectedValues = useMemo(() => values[filter.field]?.length > 0, [values, filter.field])

  const selectedValues = useMemo(
    () =>
      values[filter.field]?.map((item: string) => ({
        value: item,
        label: item,
      })) || [],
    [values, filter.field],
  )

  return (
    <div>
      <Autocomplete
        PopperProps={{
          className: 'commonFilter',
        }}
        className={styles.autocompleteField}
        autocompleteSize="large"
        value={selectedValues}
        // @ts-ignore
        multiple
        name={filter.field}
        onBlur={handleBlur}
        placeholder={filter?.title || filter?.field}
        options={filter.options}
        isAsync={!!filter.loadOptions}
        loadOptions={filter.loadOptions}
        getOptionValue={(option) => option.value}
        onChange={handleOnChange}
        hideTags
        isFilterMenu
        label={filter?.title || filter?.field}
        isSelectedValues={isSelectedValues}
        withTopLabel
        noOptionsText={
          !!filter.loadOptions
            ? `Start typing ${(filter?.title || filter?.field)?.toLowerCase()}`
            : 'No options'
        }
        startAdornment={<ListIcon className={styles.startAdornment} />}
      />
    </div>
  )
}

const AmountFilter = ({ filter, values, handleBlur }: FilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const filterRange = useMemo(() => {
    const min = values[`${filter?.field}Min`] || ''
    const max = values[`${filter?.field}Max`] || ''
    return min || max ? `${min} - ${max}` : filter?.title
  }, [filter, values])

  const amountSelected = useMemo(() => {
    return filterRange.includes(' - ')
  }, [filterRange])

  const startIcon = useMemo(() => {
    switch (filter?.type) {
      case 'amount':
        return (
          <DollarIcon
            className={cn(styles.startAdornmentAmount, { [styles.iconSelected]: amountSelected })}
          />
        )
      case 'percent':
        return (
          <PercentIcon
            className={cn(styles.startAdornmentAmount, { [styles.iconSelected]: amountSelected })}
          />
        )
      default:
        return (
          <PoundIcon
            className={cn(styles.startAdornmentAmount, styles.startAdornmentPound, {
              [styles.iconSelectedPound]: amountSelected,
            })}
          />
        )
    }
  }, [filter, amountSelected])

  return (
    <div className={styles.filterBox}>
      <Button
        variant="contained"
        onClick={handleClick}
        className={cn(styles.filterButton, {
          [styles.filterButtonActive]: amountSelected && !open,
          [styles.buttonFocused]: open,
        })}
        endIcon={<FilterDownIcon className={styles.filterDownIcon} />}
        startIcon={amountSelected || open ? null : startIcon}
        withFieldset
        label={filter?.title}
        isOpen={open}
        selected={amountSelected}
      >
        <span
          className={cn(styles.filterButtonText, genericSs.textCapitalize, {
            [styles.filterButtonTextSelected]: amountSelected,
          })}
        >
          {!amountSelected && open ? null : filterRange}
        </span>
      </Button>
      <div className={styles.popperHolder}>
        <Popover
          className={cn('commonFilter', styles.amountFilter)}
          classes={{ paper: styles.filterPopoverPaper }}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onBlur={handleBlur}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div className={styles.dateFilterGrid}>
            <Grid container spacing={3} direction="column">
              <Grid item xs={3}>
                <div className={styles.filterTitle}> Min</div>
                {filter?.type === 'amount' ? (
                  <CurrencyField
                    placeholder={titleCase('Min ' + filter?.title)}
                    className={styles.amountInput}
                    name={`${filter?.field}Min`}
                    size="large"
                  />
                ) : (
                  <TextField
                    name={`${filter?.field}Min`}
                    size="filterInput"
                    placeholder={titleCase('Min ' + filter?.title)}
                    InputProps={{
                      type: 'number',
                      endAdornment: filter?.type === 'percent' && (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
              </Grid>
              <Grid item xs={3}>
                <div className={styles.filterTitle}> Max</div>
                {filter?.type === 'amount' ? (
                  <CurrencyField
                    placeholder={titleCase('Max ' + filter?.title)}
                    className={styles.amountInput}
                    name={`${filter?.field}Max`}
                    size="large"
                  />
                ) : (
                  <TextField
                    size="filterInput"
                    name={`${filter?.field}Max`}
                    placeholder={titleCase('Max ' + filter?.title)}
                    InputProps={{
                      type: 'number',
                      endAdornment: filter?.type === 'percent' && (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </div>
        </Popover>
      </div>
    </div>
  )
}

const DateFilter = ({ filter, handleBlur, values }: FilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const filterRange = useMemo(
    () =>
      values[`${filter?.field}From`] || values[`${filter?.field}To`]
        ? `${values[`${filter?.field}From`] ? formatDate(values[`${filter?.field}From`]) : ''} - ${
            values[`${filter?.field}To`] ? formatDate(values[`${filter?.field}To`]) : ''
          }`
        : filter?.title,
    [filter, values],
  )

  const dateSelected = useMemo(() => {
    return filterRange.includes(' - ')
  }, [filterRange])

  return (
    <div className={styles.filterBox}>
      <Button
        variant="contained"
        onClick={handleClick}
        className={cn(styles.filterButton, {
          [styles.filterButtonActive]: dateSelected && !open,
          [styles.buttonFocused]: open,
        })}
        endIcon={<FilterDownIcon className={styles.filterDownIcon} />}
        startIcon={
          dateSelected || open ? null : (
            <CalendarIcon className={cn(styles.calendarIcon, styles.startAdornmentPound)} />
          )
        }
        withFieldset
        label={filter?.title}
        isOpen={open}
        selected={dateSelected}
      >
        <span
          className={cn(styles.filterButtonText, {
            [styles.filterButtonTextSelected]: dateSelected,
          })}
        >
          {!dateSelected && open ? null : filterRange}
        </span>
      </Button>
      <Popover
        className={cn('commonFilter', styles.filterPopover)}
        classes={{ paper: styles.filterPopoverPaper }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onBlur={handleBlur}
      >
        <div className={styles.dateFilterGrid}>
          <Grid container spacing={3} direction="column" justifyContent="center">
            <Grid item xs={4}>
              <div className={styles.filterTitle}>From</div>
              <KeyboardDatePicker
                PopperProps={{
                  className: 'commonFilter',
                }}
                name={`${filter?.field}From`}
                placeholder="Filter by date range"
                inputFormat={DATE_FORMAT}
                size={'large'}
              />
            </Grid>

            <Grid item xs={4}>
              <div className={styles.filterTitle}>To</div>
              <KeyboardDatePicker
                PopperProps={{
                  className: 'commonFilter',
                }}
                name={`${filter?.field}To`}
                placeholder="Filter by date range"
                inputFormat={DATE_FORMAT}
                size={'large'}
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  )
}

const DateTimeFilter = ({ filter, values, handleBlur }: FilterProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])

  const filterRange = useMemo(
    () =>
      values[`${filter?.field}From`] || values[`${filter?.field}To`]
        ? `${
            values[`${filter?.field}From`] ? formatDateTime(values[`${filter?.field}From`]) : ''
          } - ${values[`${filter?.field}To`] ? formatDateTime(values[`${filter?.field}To`]) : ''}`
        : filter?.title,
    [filter, values],
  )

  const dateSelected = useMemo(() => {
    return filterRange.includes(' - ')
  }, [filterRange])

  return (
    <div>
      <Button
        variant="contained"
        onClick={handleClick}
        className={cn(styles.filterButton, {
          [styles.filterButtonActive]: dateSelected && !open,
          [styles.buttonFocused]: open,
        })}
        endIcon={<FilterDownIcon className={styles.filterDownIcon} />}
        startIcon={
          dateSelected || open ? null : <CalendarIcon className={styles.startAdornmentPound} />
        }
        withFieldset
        label={filter?.title}
        isOpen={open}
        selected={dateSelected}
      >
        <span
          className={cn(styles.filterButtonText, {
            [styles.filterButtonTextSelected]: dateSelected,
          })}
        >
          {!dateSelected && open ? null : filterRange}
        </span>
      </Button>
      <Popover
        className={cn('commonFilter', styles.filterPopover)}
        classes={{ paper: styles.filterPopoverPaper }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        onBlur={handleBlur}
      >
        <div className={styles.dateFilterGrid}>
          <Grid container spacing={3} direction="column" justifyContent="center">
            <Grid item xs={4}>
              <div className={styles.filterTitle}>From</div>
              <KeyboardDateTimePicker
                PopperProps={{
                  className: 'commonFilter',
                }}
                name={`${filter?.field}From`}
                placeholder="Filter by date range"
                inputFormat={DATE_TIME_FORMAT}
                isFilterMenu
              />
            </Grid>
            <Grid item xs={4}>
              <div className={styles.filterTitle}>To</div>
              <KeyboardDateTimePicker
                PopperProps={{
                  className: 'commonFilter',
                }}
                name={`${filter?.field}To`}
                placeholder="Filter by date range"
                inputFormat={DATE_TIME_FORMAT}
                isFilterMenu
              />
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  )
}

const Filter = ({ filter, handleSubmit, values, filtersSize = 4 }: FilterProps) => {
  const handleBlur = useCallback(
    (e: React.FocusEvent) => {
      // check that the target is not a child of the popover
      if (
        (e.relatedTarget && !e.currentTarget.contains(e.relatedTarget as Node)) ||
        !e.relatedTarget
      ) {
        handleSubmit()
      }
    },
    [handleSubmit],
  )

  const FilterComponent = useMemo(() => {
    let Component
    switch (filter?.type) {
      case 'text':
        if (filter.excludeFullSearchFilter) {
          Component = TextFilter
        }
        break
      case 'list':
        if (filter.options) {
          Component = TypeFilter
        }
        break
      case 'autocomplete':
        Component = AutocompleteFilter
        break
      case 'amount':
      case 'number':
      case 'percent':
        Component = AmountFilter
        break
      case 'date':
        Component = DateFilter
        break
      case 'datetime':
        Component = DateTimeFilter
        break
      default:
        break
    }
    return Component
  }, [filter])

  if (!FilterComponent) {
    return null
  }

  return (
    <Grid item xs={filtersSize} className={styles.gridItem}>
      <FilterComponent
        filter={filter}
        handleSubmit={handleSubmit}
        handleBlur={handleBlur}
        values={values}
      />
    </Grid>
  )
}

const FilterMenu = ({
  isOpen,
  anchorEl,
  onClose,
  filters,
  handleSubmit,
  values,
  className,
  filtersSize,
  quickFilters = DEFAULT_EMPTY_ARRAY_PROP,
  appliedQuickFilter = null,
  handleQuickFilterApply,
  quickFiltersOutside,
}: IProps) => {
  const [isFiltersExpanded, setIsFiltersExpanded] = useState<boolean>(false)

  const preparedFilters = useMemo(
    () =>
      (filters || [])
        // Filter out filters that not shown (excluded, empty, text)
        .filter(
          ({ type, excludeFilter, excludeFullSearchFilter }) =>
            !excludeFilter &&
            type !== 'empty' &&
            type !== 'quickFilter' &&
            (type !== 'text' || excludeFullSearchFilter),
        )
        .sort((a, b) =>
          // Checking for min/max filters (amount/number/percent)
          // If `a` is a min/max filter and b is not a min/max, then `a` should come after `b`
          SHORT_FILTERS_SORTING_TYPES.includes(a.type) &&
          !SHORT_FILTERS_SORTING_TYPES.includes(b.type)
            ? 1
            : // If `a` is not a min/max filter and b is a min/max, then `a` should come before `b`
            !SHORT_FILTERS_SORTING_TYPES.includes(a.type) &&
              SHORT_FILTERS_SORTING_TYPES.includes(b.type)
            ? -1
            : // `a` and `b` are considered equal
              0,
        ),
    [filters],
  )

  const shouldShowViewMore = useMemo(
    () => preparedFilters.length > SHORT_FILTERS_COUNT,
    [preparedFilters],
  )

  const toggleIsFiltersExpanded = useCallback(() => {
    setIsFiltersExpanded((isExpanded) => !isExpanded)
  }, [])

  return (
    <Popover
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      classes={{ paper: cn(styles.filterMenuPopoverPaper, className) }}
    >
      {quickFilters.length > 0 && !quickFiltersOutside && (
        <div className={styles.quickFiltersContainer}>
          <span className={styles.quickFiltersLabel}>Quick Filters:</span>

          {quickFilters.map((quickFilter) => (
            <QuickFilter
              key={quickFilter.title}
              filter={quickFilter}
              isSelected={appliedQuickFilter === quickFilter.title}
              handleApply={handleQuickFilterApply}
            />
          ))}
        </div>
      )}
      <Grid
        container
        className={styles.filterGrid}
        direction="row"
        alignItems="flex-start"
        justifyContent="flex-start"
        spacing={{
          xs: 1,
          md: 2,
        }}
      >
        {preparedFilters
          .filter((_, index) => isFiltersExpanded || index < SHORT_FILTERS_COUNT)
          .map((filter) => (
            <Filter
              key={filter.field}
              filter={filter}
              handleSubmit={handleSubmit}
              values={values}
              filtersSize={filtersSize}
            />
          ))}
      </Grid>
      {shouldShowViewMore && (
        <Button
          color="primary"
          variant="outlined"
          onClick={toggleIsFiltersExpanded}
          secondary
          fullWidth
          className={styles.filterMenuViewMoreButton}
          small
          disableRipple
        >
          View {isFiltersExpanded ? 'Less' : 'More'}
        </Button>
      )}
    </Popover>
  )
}

export default FilterMenu
