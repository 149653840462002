import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import ARAPSummaryOverTime from './ARAPSummaryOverTime'

import { arapChartData, arapChartDataDropdownOptions } from '../../redux/bbc/selectors'
import { getARAPChartData, getARAPChartDropdownOptions } from '../../redux/bbc/actions'

const selector = createStructuredSelector({
  arapChartData,
  arapChartDataDropdownOptions,
})

const actions = {
  getARAPChartData,
  getARAPChartDropdownOptions,
}

export default connect(selector, actions)(ARAPSummaryOverTime)
