import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react'
import { useParams } from 'react-router'
import { Form } from 'react-final-form'
import cn from 'classnames'
import moment from 'moment'
import InfiniteScroll from 'react-infinite-scroll-component'
import styles from './BBCRollforwardTable.module.scss'
import genericSs from '@styles/generic.module.scss'
import ActiveToolbar from '../ActiveToolbar'
import Card from '../Common/Card'
import TableContainer from '../Common/TableContainer'
import TableRow from '../Common/TableRow'
import TableCell from '../Common/TableCell'
import Table from '../Common/Table'
import TableHead from '../Common/TableHead'
import TableBody from '../Common/TableBody'
import TableFooter from '../Common/TableFooter'
import TableFiltersRow from '../Common/TableFiltersRow'
import TableLoader from '../Common/TableLoader'
import {
  IARRollforwardSummary,
  IArRollforwardData,
  IApRollforwardData,
  BBC_RECEIVABLES_GROUP_BY_OPTIONS,
  BBCReceivablesGroupBy,
  ReportingPeriods,
  REPORTING_PERIOD_OPTIONS,
  IARRollforwardGraphData,
  IARAPChartDropdownOptions,
  BBC_AGING_REPORT,
  IDebtorIneligibleCategories,
  IneligibleCategory,
  IPreviousCollateralDateOptions,
  ReportingFlow,
} from '@common/interfaces/bbc'
import { formatDate, debounceEventHandler } from '../../helpers/helpers'
import SelectField from '../Common/SelectField'
import Tooltip from '@mui/material/Tooltip'
import FullscreenModal from '../Common/FullscreenModal'
import Box from '@mui/material/Box'
import {
  BBC_AR_ROLLFORWARD_LIST_FILTERS_CONFIG,
  BBC_AP_ROLLFORWARD_LIST_FILTERS_CONFIG,
  PER_PAGE,
  getApplicableFilters,
} from '@common/constants/filters'
import { buildFiltersDefaults, buildFiltersValidateSchema } from '../../helpers/filters'
import { Link, generatePath } from 'react-router-dom'
import LinkButton from '@mui/material/Link'
import { ROUTES } from '../../constants/routes'
import BBCRollforwardDetails from '../BBCRollforwardDetailsTable'
import FilterContainer from '../Filters/FilterContainer'
import { ExpandAndMinimize, ExpandDetailIcon } from '../Common/Icons'
import { ILoadingData } from '../../redux/types'
import useTable from '../../hooks/useTable'
import { BBC_TABS } from '@common/interfaces/bbc'
import FormattedTableCell from '../Common/FormattedTableCell'
import { formatValue } from '../../helpers/helpers'
import DatePicker from '../Common/DatePicker'
import BBCARRollforwardGraph from './BBCARRollforwardGraph'
import Autocomplete from '../Common/Autocomplete'
import useGraphToggle from '../../hooks/useGraphTogggle'
import { usePermissions } from '../../helpers/permissionContext'
import EntityPreview from '../EntityPreview'
import { WorkflowTypes } from '@common/interfaces/notes'
import ExportButton from '../Common/ExportButton'
import { CATEGORIES } from '@common/constants/tracking'
import useTrackVisualizationsTable from '../../hooks/useTrackVisualizationsTable'
import useTrackVisualizationsTableChartSelection from '../../hooks/useTrackVisualizationsTableChartSelection'
import { useReportingFlow } from '../../hooks/useReportingFlow'

const sortDefault = { field: 'newBalance', direction: 'DESC' } as const

interface IProps {
  isLoadingBBC: boolean
  arRollforward: ILoadingData<IArRollforwardData>
  arRollforwardGraph: ILoadingData<IARRollforwardGraphData>
  apRollforward: ILoadingData<IApRollforwardData>
  previousClientCollaterals: IPreviousCollateralDateOptions[]
  getBBCAPRollforward: (id: string, data: object) => void
  getBBCARRollforward: (id: string, data: object) => void
  getBBCARRollforwardGraph: (id: string, data: object) => void
  exportBBCARRollforward: (id: string, data: object) => void
  exportBBCAPRollforward: (id: string, data: object) => void
  refreshCount: number
  type: BBC_TABS
  reportingFlow: ReportingFlow
  getARAPChartDropdownOptions: (id: string, data: object) => void
  arapChartDataDropdownOptions: ILoadingData<IARAPChartDropdownOptions>
  debtorIneligibleCategories: ILoadingData<IDebtorIneligibleCategories>
}

const BBCRollforwardTable = ({
  isLoadingBBC,
  arRollforward,
  arRollforwardGraph,
  apRollforward,
  previousClientCollaterals,
  getBBCARRollforward,
  getBBCAPRollforward,
  getBBCARRollforwardGraph,
  exportBBCARRollforward,
  exportBBCAPRollforward,
  refreshCount,
  type,
  reportingFlow,
  getARAPChartDropdownOptions,
  arapChartDataDropdownOptions,
  debtorIneligibleCategories,
}: IProps) => {
  const { id } = useParams<{ id: string }>()
  const wrapperRef = useRef(null)
  const { isParticipant } = usePermissions()

  const [expanded, setExpanded] = useState([])
  const [currentDate, setCurrentDate] = useState<string>('')
  const [recordDate, setRecordDate] = useState<string>('')
  const [isModalShown, setIsModalShown] = useState(false)
  const [groupBy, setGroupBy] = useState<BBCReceivablesGroupBy>(BBCReceivablesGroupBy.Customer)
  const [isInitialized, setIsInitialized] = useState(false)
  const [reportingPeriod, setReportingPeriod] = useState<ReportingPeriods>(ReportingPeriods.Monthly)
  const [currentDateRange, setCurrentDateRange] = useState<{ startDate: string; endDate: string }>({
    startDate: '',
    endDate: '',
  })
  const [currentCustomers, setCurrentCustomers] = useState<any>([])

  const exportRollforward = useMemo(
    () => (type === BBC_TABS.RECEIVABLES ? exportBBCARRollforward : exportBBCAPRollforward),
    [type, exportBBCARRollforward, exportBBCAPRollforward],
  )

  const getRollforward = useMemo(
    () => (type === BBC_TABS.RECEIVABLES ? getBBCARRollforward : getBBCAPRollforward),
    [type, getBBCARRollforward, getBBCAPRollforward],
  )

  const rollforwardData = useMemo(
    () => (type === BBC_TABS.RECEIVABLES ? arRollforward : apRollforward),
    [type, arRollforward, apRollforward],
  )

  const { data: entities } = useMemo(
    () => arapChartDataDropdownOptions,
    [arapChartDataDropdownOptions],
  )

  useEffect(() => {
    getARAPChartDropdownOptions(id, {
      agingReport: BBC_AGING_REPORT.AR,
      reportingFlow,
    })
  }, [id, getARAPChartDropdownOptions, reportingFlow])

  const {
    isLoading,
    isSaving,
    data: rollforwardSummary,
  } = useMemo(() => rollforwardData, [rollforwardData])

  const ineligibleCategories = useMemo(
    () => debtorIneligibleCategories.data?.ineligibleCategories || [],
    [debtorIneligibleCategories],
  )
  const debtorIneligibleCategoriesOptions = useMemo(
    () =>
      ineligibleCategories.map((ineligibleCategory) => ({
        label: ineligibleCategory,
        value: ineligibleCategory,
      })),
    [ineligibleCategories],
  )

  const filtersConfig = useMemo(
    () =>
      type === BBC_TABS.RECEIVABLES
        ? BBC_AR_ROLLFORWARD_LIST_FILTERS_CONFIG.map((item) =>
            item.field === 'ineligible_category'
              ? {
                  ...item,
                  options: debtorIneligibleCategoriesOptions,
                }
              : item,
          ).map((filter) =>
            filter.type === 'quickFilter'
              ? {
                  ...filter,
                  quickFilters: [
                    {
                      title: 'Eligible Customers',
                      filters: {
                        ineligible_category: [
                          IneligibleCategory.Eligible,
                          IneligibleCategory.Foreign,
                          IneligibleCategory.Government,
                          IneligibleCategory.Intercompany,
                          IneligibleCategory.Other,
                          IneligibleCategory.Distressed,
                          IneligibleCategory.Affiliate,
                        ].filter((category) => ineligibleCategories.includes(category)),
                      },
                    },
                  ],
                }
              : filter,
          )
        : BBC_AP_ROLLFORWARD_LIST_FILTERS_CONFIG.map((filter) =>
            filter.type === 'quickFilter'
              ? {
                  ...filter,
                  quickFilters: [
                    {
                      title: 'Priority Vendors',
                      isHidden: true,
                      filters: {
                        isPriorityPayable: 'true',
                      },
                    },
                  ],
                }
              : filter,
          ),
    [type, debtorIneligibleCategoriesOptions, ineligibleCategories],
  )

  const listFieldsConfig = useMemo(
    () =>
      filtersConfig
        .filter((filter) => ['amount', 'percent', 'date'].includes(filter.type))
        .map((filter) => ({
          field: filter.field,
          fieldType: filter.type,
        })),

    [filtersConfig],
  )

  const filtersValidate = useMemo(() => buildFiltersValidateSchema(filtersConfig), [filtersConfig])

  const filtersDefaults = useMemo(() => buildFiltersDefaults(filtersConfig), [filtersConfig])

  const {
    filters,
    handleFiltersChange,
    handleOrderChange,
    orderBy,
    activeItem,
    activeItems,
    setActiveItem,
    setActiveItems,
    handleSelectRow,
    resetActiveItems,
    quickFilter,
    handleQuickFilterChange,
  } = useTable({
    tableId: 'rollforward',
    filtersDefaults,
    sortDefault,
  })

  const handleExportBBCActivityStats = useCallback(() => {
    exportRollforward(id, {
      recordDate,
      reportingFlow,
      groupBy,
      isExport: true,
    })
  }, [id, recordDate, groupBy, exportRollforward, reportingFlow])

  const debounceFilterList = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        getRollforward(id, {
          ...data,
        })
      }, 500),
    [id, getRollforward],
  )

  const debounceGraphList = useMemo(
    () =>
      debounceEventHandler((data: any) => {
        getBBCARRollforwardGraph(id, {
          ...data,
        })
      }, 500),
    [id, getBBCARRollforwardGraph],
  )

  const customerValues: string[] = useMemo(
    () => currentCustomers?.map((company: any) => company?.value),
    [currentCustomers],
  )

  const { TabsComponent, isGraphShown } = useGraphToggle({})

  const isTable = useMemo(() => !isGraphShown, [isGraphShown])

  const { isClientPage } = useReportingFlow({ reportingFlow })

  useEffect(() => {
    isTable &&
      refreshCount &&
      debounceFilterList({
        page: 0,
        orderBy: orderBy.field,
        orderDirection: orderBy.direction,
        filters,
        recordDate,
        groupBy,
        reportingFlow,
        currentDate,
        reportingPeriod,
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        debtors: customerValues,
      })
  }, [
    isTable,
    recordDate,
    groupBy,
    orderBy,
    reportingPeriod,
    currentDateRange.startDate,
    currentDateRange.endDate,
    customerValues,
    filters,
    debounceFilterList,
    reportingFlow,
    currentDate,
    refreshCount,
  ])

  useEffect(() => {
    !isTable &&
      refreshCount &&
      debounceGraphList({
        reportingFlow,
        reportingPeriod,
        startDate: currentDateRange.startDate,
        endDate: currentDateRange.endDate,
        debtors: customerValues,
      })
  }, [
    isTable,
    reportingPeriod,
    currentDateRange.startDate,
    currentDateRange.endDate,
    customerValues,
    debounceGraphList,
    reportingFlow,
    refreshCount,
  ])

  const loadMore = useCallback(() => {
    debounceFilterList({
      loadMore: true,
      page: Math.ceil(rollforwardSummary?.data.length / PER_PAGE),
      orderBy: orderBy.field,
      orderDirection: orderBy.direction,
      filters,
      recordDate,
      groupBy,
      reportingFlow,
      currentDate,
    })
  }, [
    rollforwardSummary,
    orderBy,
    filters,
    debounceFilterList,
    recordDate,
    reportingFlow,
    currentDate,
    groupBy,
  ])

  const handleCurrentDateChange = useCallback(({ target: { value } }) => {
    setCurrentDate(value)
  }, [])

  const handleRecordDateChange = useCallback(({ target: { value } }) => {
    setRecordDate(value)
  }, [])

  const handleGroupByChange = useCallback(({ target: { value } }) => {
    setExpanded([])
    setGroupBy(value)
  }, [])

  const previousClientCollateralsDatesOptions = useMemo(() => {
    return (previousClientCollaterals || []).map((clientCollateral) => ({
      value: clientCollateral.recordDate,
      label: formatDate(clientCollateral.recordDate),
    }))
  }, [previousClientCollaterals])

  useEffect(() => {
    if (previousClientCollateralsDatesOptions.length) {
      if (isClientPage) {
        setCurrentDate(previousClientCollateralsDatesOptions[0].value)
        setRecordDate(
          previousClientCollateralsDatesOptions[1]
            ? previousClientCollateralsDatesOptions[1].value
            : previousClientCollateralsDatesOptions[0].value,
        )
      } else {
        setRecordDate(previousClientCollateralsDatesOptions[0].value)
      }
    }
  }, [previousClientCollateralsDatesOptions, isClientPage])
  const totalRow = useMemo(
    () =>
      rollforwardSummary?.data
        ?.map((element, index) => ({ element, index }))
        .filter(({ index }) => activeItems.includes(index))
        .map(({ element }) => element)
        .reduce(
          (totalRowResult, row) => {
            totalRowResult.newBalance += Number(row.newBalance) || 0

            listFieldsConfig.forEach(({ field }) => {
              totalRowResult[field] += Number(row[field]) || 0
            })
            return totalRowResult
          },
          {
            newBalance: 0,
            percent: 0,
            ...listFieldsConfig
              .map(({ field }) => field)
              .reduce((totalRowResult: Partial<IARRollforwardSummary>, field: string) => {
                totalRowResult[field] = 0

                return totalRowResult
              }, {}),
          },
        ),
    [rollforwardSummary, activeItems, listFieldsConfig],
  )

  const totalImpliedDilution = useMemo(() => {
    return totalRow?.impliedReal > 0 ? totalRow?.impliedReal / totalRow?.impliedCollections : 0
  }, [totalRow])

  const handleExpand = useCallback(
    (label: string) => {
      if (expanded.length === 0) {
        setIsModalShown(true)
      }
      setExpanded((values) =>
        values.includes(label) ? values.filter((item) => item !== label) : [...values, label],
      )
    },
    [expanded],
  )

  const reportType = useMemo(() => (type === BBC_TABS.RECEIVABLES ? 'AR' : 'AP'), [type])

  const toggleModal = useCallback(() => {
    setIsModalShown((prev) => !prev)
  }, [])

  const handleReportingPeriodChange = useCallback(({ target: { value } }) => {
    setReportingPeriod(value)
  }, [])

  const handleDateChange = useCallback(
    (values: { startDate: string; endDate: string }) => {
      setCurrentDateRange(values)
    },
    [setCurrentDateRange],
  )

  const datesBoundary = useMemo(
    () => ({
      minDate: arRollforwardGraph.data?.minDate || null,
      maxDate: arRollforwardGraph.data?.maxDate || null,
    }),
    [arRollforwardGraph],
  )

  useEffect(() => {
    if (!isInitialized && arRollforwardGraph.data) {
      setCurrentDateRange({
        startDate: moment
          .max([
            moment(arRollforwardGraph.data.minDate),
            moment(arRollforwardGraph.data.maxDate).subtract(1, 'year'),
          ])
          .format('YYYY-MM-DD'),
        endDate: arRollforwardGraph.data.maxDate,
      })
      setIsInitialized(true)
    }
  }, [isInitialized, arRollforwardGraph])

  const handleCustomerChange = useCallback((event, newValue) => {
    setCurrentCustomers([...newValue])
  }, [])

  const debtorOptions = useMemo(
    () =>
      entities?.entities?.map((entity) => ({
        value: entity,
        label: entity,
      })) || [],
    [entities?.entities],
  )

  const columnsCount = useMemo(() => getApplicableFilters(filtersConfig).length, [filtersConfig])

  const visualizationsCategory = useMemo(
    () =>
      isGraphShown
        ? CATEGORIES.arRollforwardChart
        : type === BBC_TABS.RECEIVABLES
        ? CATEGORIES.arRollforwardTable
        : CATEGORIES.apRollforwardTable,
    [isGraphShown, type],
  )
  const visualizationsParams = useMemo(
    () => ({
      [isClientPage ? 'clientId' : 'borrowingBaseId']: id,
    }),
    [isClientPage, id],
  )
  const visualizationsFilters = useMemo(
    () => ({
      ...filters,
      latestSalesDate: currentDateRange.endDate,
      earliestSalesDate: currentDateRange.startDate,
      reportingPeriod: reportingPeriod,
      recordDate: recordDate,
      currentDate: currentDate,
      groupBy: groupBy,
    }),
    [filters, currentDateRange, reportingPeriod, recordDate, currentDate, groupBy],
  )

  useTrackVisualizationsTable({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    filtersConfig: filtersConfig,
    filters: visualizationsFilters,
    orderBy,
  })

  useTrackVisualizationsTableChartSelection({
    isInitialized,
    category: visualizationsCategory,
    params: visualizationsParams,
    isChart: !!isGraphShown,
  })

  return (
    <FullscreenModal
      isOpen={isModalShown}
      setIsOpen={setIsModalShown}
      classes={{ body: styles.fullScreenModal }}
    >
      <Card noHeaderMargin ref={wrapperRef}>
        <Form
          validate={filtersValidate}
          onSubmit={handleFiltersChange}
          initialValues={filters}
          mutators={{
            setFieldData: ([field, value], state, { changeValue }) => {
              changeValue(state, field, () => value)
            },
          }}
          render={({ values, handleSubmit, form: { mutators } }) => (
            <FilterContainer
              filters={isTable ? filtersConfig : []}
              withFullSearch={isTable}
              handleSubmit={handleSubmit}
              mutators={mutators}
              values={values}
              appliedFilters={filters}
              appliedQuickFilter={quickFilter}
              handleAppliedQuickFilterChange={handleQuickFilterChange}
              title={
                <Box mr={2}>
                  <h2>{reportType} Rollforward</h2>
                </Box>
              }
              actions={
                <Box display="flex" justifyContent="space-between" alignItems="center" gap={1}>
                  {isClientPage && isTable && (
                    <SelectField
                      key={'currentDate'}
                      className={styles.selectField}
                      label="Date"
                      useFinalForm={false}
                      name="currentDate"
                      variant="outlined"
                      options={previousClientCollateralsDatesOptions}
                      onChange={handleCurrentDateChange}
                      value={currentDate}
                      defaultValue=""
                      withTopLabel
                    />
                  )}
                  {isTable && (
                    <SelectField
                      key={'compareDate'}
                      className={styles.selectField}
                      label={'Compare'}
                      useFinalForm={false}
                      name="recordDate"
                      variant="outlined"
                      options={previousClientCollateralsDatesOptions}
                      onChange={handleRecordDateChange}
                      value={recordDate}
                      defaultValue=""
                      withTopLabel
                    />
                  )}
                  {type === BBC_TABS.RECEIVABLES && isTable && (
                    <SelectField
                      key={groupBy}
                      label="Group by"
                      variant="outlined"
                      className={styles.selectField}
                      useFinalForm={false}
                      name="groupBy"
                      options={BBC_RECEIVABLES_GROUP_BY_OPTIONS}
                      onChange={handleGroupByChange}
                      value={groupBy}
                      defaultValue=""
                      withTopLabel
                    />
                  )}
                  {type === BBC_TABS.RECEIVABLES && !isTable && (
                    <Autocomplete
                      withTopLabel
                      label="Select customer"
                      className={styles.selectField}
                      value={currentCustomers}
                      name="currentCustomers"
                      options={debtorOptions}
                      getOptionLabel={(option: any) => option?.label || option || ''}
                      getOptionValue={(option: any) => option?.value || option || ''}
                      onChange={handleCustomerChange}
                      defaultValue=""
                      //@ts-ignore
                      multiple
                      disabled={arRollforwardGraph.isLoading}
                    />
                  )}
                  {isTable && !isParticipant && (
                    <ExportButton
                      isLoading={isSaving}
                      handleExport={handleExportBBCActivityStats}
                    />
                  )}

                  {!isTable && (
                    <SelectField
                      useFinalForm={false}
                      name="reportingPeriod"
                      value={reportingPeriod}
                      onChange={handleReportingPeriodChange}
                      className={styles.selectFieldWithTopLabel}
                      label="Period"
                      variant="outlined"
                      options={REPORTING_PERIOD_OPTIONS}
                      disabled={arRollforwardGraph.isLoading}
                      fullWidth={false}
                    />
                  )}

                  {!isTable && (
                    <DatePicker
                      reportingPeriod={ReportingPeriods.Monthly}
                      currentDateRange={currentDateRange}
                      datesBoundary={datesBoundary}
                      onChange={handleDateChange}
                      disabled={arRollforwardGraph.isLoading}
                    />
                  )}

                  {type === BBC_TABS.RECEIVABLES && TabsComponent}

                  <ExpandAndMinimize action={toggleModal} isExpanded={isModalShown} />
                </Box>
              }
            />
          )}
        />

        {isTable || type !== BBC_TABS.RECEIVABLES ? (
          <TableContainer
            className={styles.table}
            onActiveRowsChange={setActiveItems}
            onActiveRowChange={setActiveItem}
            hasFooter
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell colSpan={2} className={styles.highlightedCurrent}>
                    {isClientPage
                      ? formatDate(rollforwardSummary?.currentFunding?.recordDate)
                      : 'Current BBC'}
                  </TableCell>
                  <TableCell colSpan={columnsCount - 3} className={styles.highlightedDate}>
                    {formatDate(rollforwardSummary?.previousFunding?.recordDate)}
                  </TableCell>
                </TableRow>
                <TableFiltersRow
                  filters={filtersConfig}
                  orderBy={orderBy}
                  handleOrderChange={handleOrderChange}
                  isChildrenAtStart
                >
                  <TableCell />
                </TableFiltersRow>
              </TableHead>
              <TableBody id={`scrollable${type}Rollforward`}>
                {isLoadingBBC || isLoading ? (
                  <TableLoader columnsCount={columnsCount} />
                ) : (
                  rollforwardSummary?.data && (
                    <InfiniteScroll
                      dataLength={rollforwardSummary?.data.length}
                      next={loadMore}
                      hasMore={rollforwardSummary?.data.length < rollforwardSummary?.totalItems}
                      loader={<TableLoader columnsCount={columnsCount} rowsCount={1} />}
                      scrollableTarget={`scrollable${type}Rollforward`}
                    >
                      {rollforwardSummary?.data.map((item, index) => {
                        const isExpanded = expanded.includes(item.label)
                        return (
                          <React.Fragment key={item.label}>
                            <TableRow
                              data-index={index}
                              className={cn('activableRow', {
                                activeRow: activeItems.includes(index),
                                currentActiveRow: activeItem === index,
                              })}
                              onClick={(event) => handleSelectRow(event, index)}
                            >
                              <TableCell className={genericSs.tableTextCenter}>
                                {item.invoiceCount > 0 && (
                                  <ExpandDetailIcon
                                    onClick={() => handleExpand(item.label)}
                                    isExpanded={isExpanded}
                                  />
                                )}
                              </TableCell>
                              <TableCell className={genericSs.tableTextLeft}>
                                <Tooltip
                                  title={item.label || ''}
                                  placement="top"
                                  disableHoverListener={item.label?.length < 20 || !item.label}
                                  disableTouchListener
                                >
                                  {item?.entityId && !isParticipant ? (
                                    <LinkButton
                                      component={Link}
                                      to={generatePath(ROUTES.ENTITY_PAGE, {
                                        id: item.entityId,
                                      })}
                                      className={cn(styles.entityLink, {
                                        [styles.entityLinkWithPriority]:
                                          type === BBC_TABS.PAYABLES &&
                                          'priorityPayable' in item &&
                                          item.priorityPayable,
                                      })}
                                    >
                                      {item.label}
                                    </LinkButton>
                                  ) : (
                                    <span>{item.label}</span>
                                  )}
                                </Tooltip>

                                {item?.entityId && !isParticipant && (
                                  <EntityPreview
                                    id={item.entityId}
                                    workflow={
                                      isClientPage ? WorkflowTypes.clientPage : WorkflowTypes.bbc
                                    }
                                    className={styles.entityPreviewIcon}
                                  />
                                )}

                                {type === BBC_TABS.PAYABLES &&
                                  'priorityPayable' in item &&
                                  item.priorityPayable && (
                                    <Tooltip placement="top" title="Priority Payable">
                                      <div className={genericSs.yellowTag}>Priority</div>
                                    </Tooltip>
                                  )}
                              </TableCell>
                              {listFieldsConfig.map(({ field, fieldType }) => {
                                const value = item[field]
                                const nullCondition =
                                  !rollforwardSummary?.previousFunding?.recordDate || !value
                                return (
                                  <FormattedTableCell
                                    key={field}
                                    value={value}
                                    fieldType={fieldType}
                                    nullCondition={nullCondition}
                                  />
                                )
                              })}
                            </TableRow>
                            {isExpanded && (
                              <TableRow>
                                <TableCell className={genericSs.nestedRowColumn} colSpan={11}>
                                  <BBCRollforwardDetails
                                    rollforwardDetail={item.rows}
                                    filters={filters}
                                    isModalShown={isModalShown}
                                    entity={item.label}
                                    recordDate={recordDate}
                                    hasPreviousBBC={
                                      !!rollforwardSummary?.previousFunding?.recordDate
                                    }
                                    type={type}
                                    groupBy={groupBy}
                                    reportingFlow={reportingFlow}
                                    currentDate={currentDate}
                                  />
                                </TableCell>
                              </TableRow>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </InfiniteScroll>
                  )
                )}
              </TableBody>
              <TableFooter>
                {isLoadingBBC || !rollforwardSummary?.totals ? (
                  <TableLoader columnsCount={10} rowsCount={3} />
                ) : (
                  rollforwardSummary?.totals.map((item) => (
                    <TableRow key={item.label}>
                      <TableCell colSpan={2} className={genericSs.tableTextLeft}>
                        {item.label}
                      </TableCell>
                      {listFieldsConfig.map(({ field, fieldType }) => {
                        const value = item[field]
                        const nullCondition =
                          !rollforwardSummary?.previousFunding?.recordDate || !value
                        return (
                          <FormattedTableCell
                            key={field}
                            value={value}
                            fieldType={fieldType}
                            nullCondition={nullCondition}
                          />
                        )
                      })}
                    </TableRow>
                  ))
                )}
              </TableFooter>
            </Table>

            <ActiveToolbar
              activeItems={activeItems}
              className={cn(styles.activeToolbar)}
              isFullscreen={isModalShown}
              containerRef={wrapperRef}
              resetActiveItems={resetActiveItems}
            >
              {listFieldsConfig.map(({ field, fieldType }) => {
                let value = totalRow?.[field]
                if (field === 'impliedDilution') {
                  value = totalImpliedDilution
                }
                const formattedValue = formatValue({
                  value,
                  type: fieldType,
                  nullCondition: !rollforwardSummary?.previousFunding?.recordDate,
                })
                return (
                  <div key={field} className={genericSs.tableTextRight}>
                    {formattedValue}
                  </div>
                )
              })}
            </ActiveToolbar>
          </TableContainer>
        ) : (
          <BBCARRollforwardGraph
            isLoading={arRollforwardGraph.isLoading}
            isModalShown={isModalShown}
            headers={arRollforwardGraph.data?.headers || []}
            data={arRollforwardGraph.data?.data || []}
            reportingPeriod={reportingPeriod}
          />
        )}
      </Card>
    </FullscreenModal>
  )
}

export default BBCRollforwardTable
