import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import StepConnector from '@mui/material/StepConnector'
import Radio from '@mui/material/Radio'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import { StepIconProps } from '@mui/material/StepIcon'
import { Field, Form } from 'react-final-form'
import setFieldTouched from 'final-form-set-field-touched'
import arrayMutators from 'final-form-arrays'
import { FieldArray } from 'react-final-form-arrays'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import cn from 'classnames'
import { useHistory } from 'react-router-dom'

import styles from './RequestFunding.module.scss'
import genericSs from '@styles/generic.module.scss'

import { ClientInfoStatus, IClientInfo } from '@common/interfaces/client'
import {
  FundingRequestAmountType,
  IFundingRequest,
  FundingRequestStatus,
} from '@common/interfaces/bbc'
import CurrencyField from '../../components/Common/CurrencyField'
import Button from '../../components/Common/Button'
import { ReactComponent as ActionSuccessIcon } from '../../assets/images/action-success.svg'
import { ReactComponent as ActionPendingIcon } from '../../assets/images/action-pending.svg'
import { UploadFile } from '../Common/UploadFile'
import {
  LOAN_TYPES,
  REPORTING_DOCUMENT_TYPES,
  REPORTING_DOCUMENTS_TITLES,
} from '@common/constants/client'
import Modal from '../../components/Common/Modal'
import { ReactComponent as CompletedStep } from '../../assets/images/step-complete.svg'
import { ReactComponent as ActiveStep } from '../../assets/images/step-active.svg'
import { ReactComponent as InactiveStep } from '../../assets/images/step-inactive.svg'
import RouteLeavingGuard from '../Common/RouteLeavingGuard'
import TextField from '../Common/TextField'
import InputLabel from '../Common/InputLabel'
import { IUser } from '@common/interfaces/user'
import { BoxSignStatus, IFile } from '@common/interfaces/box'

const steps = ['Request received', 'Pending', 'Sent for signature', 'Funding']

const StepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props
  if (completed) {
    return <CompletedStep className={className} />
  }
  if (active) {
    return <ActiveStep className={className} />
  }
  return <InactiveStep className={cn(className, styles.stepIconInactive)} />
}

const BoxSignSection = ({
  file,
  setBoxViewerLink,
}: {
  file: IFile
  setBoxViewerLink: (link: string) => void
}) => {
  const handleOpenSignModal = useCallback(() => {
    setBoxViewerLink(file.boxSignEmbeddedUrl)
  }, [setBoxViewerLink, file])

  const onCancel = useCallback(() => {
    setBoxViewerLink('')
  }, [setBoxViewerLink])

  useEffect(() => {
    if (file.boxSignStatus === BoxSignStatus.signed) {
      onCancel()
    }
  }, [file, onCancel])

  return (
    <Grid item xs={12} lg={12}>
      <Box mt={5} display="flex" justifyContent="space-around" alignItems="center">
        <Button color="primary" variant="contained" small={false} onClick={handleOpenSignModal}>
          Sign borrowing base
        </Button>
      </Box>
    </Grid>
  )
}

interface IModifyFundingSectionProps {
  handleCancelRequest: () => void
  handleModifyRequest: () => void
  isAlreadyStartedModalShown: boolean
  toggleIsAlreadyStartedModalShown: () => void
  isCancelModalShown: boolean
  toggleIsCancelModalShown: () => void
  handleConfirmCancelRequest: (reason: string) => void
}

const ModifyFundingSection = ({
  handleCancelRequest,
  handleModifyRequest,
  isAlreadyStartedModalShown,
  toggleIsAlreadyStartedModalShown,
  isCancelModalShown,
  toggleIsCancelModalShown,
  handleConfirmCancelRequest,
}: IModifyFundingSectionProps) => {
  const [reason, setReason] = useState('')
  const [isCancelling, setIsCancelling] = useState(false)

  const onReasonChange = useCallback(({ target: { value } }) => {
    setReason(value)
  }, [])

  const onConfirmCancelRequest = useCallback(async () => {
    setIsCancelling(true)
    await handleConfirmCancelRequest(reason)
    setIsCancelling(false)
  }, [handleConfirmCancelRequest, reason])

  return (
    <Grid item xs={12} lg={12}>
      <Box mt={4} display="flex" justifyContent="space-around" alignItems="center">
        <Button color="primary" variant="outlined" small={false} onClick={handleCancelRequest}>
          Cancel request
        </Button>

        <Button color="primary" variant="contained" small={false} onClick={handleModifyRequest}>
          Modify request
        </Button>
      </Box>

      <Modal
        open={isAlreadyStartedModalShown}
        onCancel={toggleIsAlreadyStartedModalShown}
        title="Cancel funding request"
        size="small"
        footer={[
          <Button
            key="submit"
            color="primary"
            variant="contained"
            onClick={toggleIsAlreadyStartedModalShown}
          >
            Okay
          </Button>,
        ]}
      >
        <p className={cn(genericSs.textTransformNone, genericSs.textJustify)}>
          It seems we’ve already started working on your funding request. Please reach out to your
          Relationship Manager
        </p>
      </Modal>

      <Modal
        open={isCancelModalShown}
        onCancel={toggleIsCancelModalShown}
        title="Cancel funding request"
        size="small"
        footer={[
          <Button
            key="submit"
            color="primary"
            variant="contained"
            onClick={onConfirmCancelRequest}
            isLoading={isCancelling}
            small={false}
            fullWidth
          >
            Submit
          </Button>,
        ]}
      >
        <p className={cn(genericSs.textTransformNone, genericSs.textJustify)}>
          Let us know why you’re canceling your funding request
        </p>
        <Box>
          <InputLabel className={genericSs.textLeft} htmlFor="reason">
            Reason
          </InputLabel>
          <TextField
            rows={7}
            id="reason"
            name="reason"
            placeholder="Share a brief description for the cancellation"
            multiline
            fullWidth
            useFinalForm={false}
            onChange={onReasonChange}
            value={reason}
          />
        </Box>
      </Modal>
    </Grid>
  )
}

const StepContent = ({ activeStep }: { activeStep: number }) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={
        <StepConnector
          classes={{
            alternativeLabel: styles.stepConnectorAlternativeLabel,
            active: styles.activeStepConnector,
            line: styles.stepConnectorLine,
            completed: styles.completedStepConnector,
          }}
        />
      }
    >
      {steps.map((label) => (
        <Step key={label} className={styles.step}>
          <StepLabel
            classes={{
              active: styles.stepLabelActive,
              completed: styles.stepLabelActive,
            }}
            StepIconComponent={StepIcon}
            StepIconProps={{
              classes: {
                root: styles.stepIcon,
                active: styles.stepIconActive,
                completed: styles.stepIconCompleted,
              },
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

interface IProps {
  clientInfo: IClientInfo
  fundingRequest: IFundingRequest
  getRequestFunding: () => void
  requestFunding: (data: FormData) => void
  cancelFunding: (data: object) => void
  modifyFunding: (data: FormData) => void
  user: IUser
  setBoxViewerLink: (link: string) => void
}

const RequestFunding = ({
  clientInfo,
  fundingRequest,
  getRequestFunding,
  requestFunding,
  cancelFunding,
  modifyFunding,
  user,
  setBoxViewerLink,
}: IProps) => {
  const formRef = useRef(null)
  const missingDocument = useRef(null)
  const isSubmitConfirmed = useRef(false)
  const [isConfirmModalShown, setIsConfirmModalShown] = useState(false)
  const [isAlreadyStartedModalShown, setIsAlreadyStartedModalShown] = useState(false)
  const [isCancelModalShown, setIsCancelModalShown] = useState(false)
  const [justRequested, setJustRequested] = useState(false)
  const history = useHistory()
  const [isDirty, setIsDirty] = useState(false)
  const [isModifying, setIsModifying] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    getRequestFunding()
  }, [getRequestFunding])
  const handleRequestFunding = useCallback(
    async (data) => {
      setIsLoading(true)
      const { id, amount, amountType, ...files } = data
      let fileIdsToDelete = Object.values(fundingRequest?.files || [])
        .flat()
        .map((file) => file.id)
      if (
        clientInfo.loanType === LOAN_TYPES.arAndInventory &&
        (!files?.[REPORTING_DOCUMENT_TYPES.inventoryDetail]?.length ||
          !files?.[REPORTING_DOCUMENT_TYPES.arAging]?.length) &&
        !isSubmitConfirmed.current
      ) {
        setIsConfirmModalShown(true)
        missingDocument.current = !files?.[REPORTING_DOCUMENT_TYPES.inventoryDetail]?.length
          ? REPORTING_DOCUMENT_TYPES.inventoryDetail
          : REPORTING_DOCUMENT_TYPES.arAging
        return
      }
      isSubmitConfirmed.current = false

      const formData = new FormData()

      formData.append('amount', amountType === FundingRequestAmountType.Specific ? amount : 0)
      formData.append('amountType', amountType)
      Object.keys(files).forEach((type) => {
        // @ts-ignore
        files[type].forEach((file, index) => {
          if (file.id) {
            fileIdsToDelete = fileIdsToDelete.filter((fileId) => file.id !== fileId)
          }
          file instanceof File && formData.append(`files[${type}][${index}]`, file, file.name)
        })
      })
      fileIdsToDelete.forEach((fileId, index) => {
        formData.append(`fileIdsToDelete[${index}]`, fileId)
      })

      id ? await modifyFunding(formData) : await requestFunding(formData)
      setJustRequested(!id)
      setIsDirty(false)
      setIsModifying(false)
      setIsLoading(false)
    },
    [requestFunding, modifyFunding, clientInfo, fundingRequest],
  )

  const schema = useMemo(() => {
    const files = {}
    clientInfo?.bbcDocuments.forEach(({ name, documentName }) => {
      files[name] = Yup.array().of(Yup.mixed())
      if (
        clientInfo.loanType === LOAN_TYPES.arAndInventory &&
        [REPORTING_DOCUMENT_TYPES.arAging, REPORTING_DOCUMENT_TYPES.inventoryDetail].includes(
          documentName,
        )
      ) {
        files[name] = files[name].test({
          name: 'requiredIf',
          exclusive: false,
          message: 'At least one AR Aging or Inventory Detail file is required',
          test(value: any) {
            return (
              !!value?.length ||
              !!this.parent[
                documentName === REPORTING_DOCUMENT_TYPES.arAging
                  ? REPORTING_DOCUMENT_TYPES.inventoryDetail
                  : REPORTING_DOCUMENT_TYPES.arAging
              ]?.length
            )
          },
        })
      } else {
        files[name] = files[name]
          .required('Required')
          .min(1, `At least one ${documentName} file is required`)
      }
    })

    return Yup.object().shape({
      amount: Yup.number()
        .typeError('Invalid number')
        .nullable(true)
        .when('amountType', (amountType: string, validation: any) =>
          amountType === FundingRequestAmountType.Specific && clientInfo?.maxLineAmount
            ? validation
                .required('Required')
                .max(
                  Number(clientInfo?.maxLineAmount),
                  `Max value is ${clientInfo?.maxLineAmount || 0}`,
                )
            : validation,
        ),
      ...files,
    })
  }, [clientInfo])
  const initialValues = useMemo(
    () => ({
      id: fundingRequest?.id,
      amount: fundingRequest?.amount || null,
      amountType: fundingRequest?.amountType || FundingRequestAmountType.TBD,
      [REPORTING_DOCUMENT_TYPES.apAging]:
        fundingRequest?.files?.[REPORTING_DOCUMENT_TYPES.apAging.replace(/[^\w]/g, '')] || [],
      [REPORTING_DOCUMENT_TYPES.arAging]:
        fundingRequest?.files?.[REPORTING_DOCUMENT_TYPES.arAging.replace(/[^\w]/g, '')] || [],
      [REPORTING_DOCUMENT_TYPES.inventoryDetail]:
        fundingRequest?.files?.[REPORTING_DOCUMENT_TYPES.inventoryDetail.replace(/[^\w]/g, '')] ||
        [],
    }),
    [fundingRequest],
  )

  const handleCancel = useCallback(() => {
    setIsConfirmModalShown(false)
  }, [])

  const handleCancelModify = useCallback(() => {
    setIsModifying(false)
  }, [])

  const activeStep = useMemo(() => {
    let step = 1
    if (fundingRequest?.status === FundingRequestStatus.Sent) {
      step = 4
    } else if (
      fundingRequest?.status === FundingRequestStatus.Completed &&
      fundingRequest?.bbcSignatureFile?.boxSignStatus === BoxSignStatus.signed
    ) {
      step = 3
    } else if (fundingRequest?.status === FundingRequestStatus.Completed) {
      step = 2
    }
    return step
  }, [fundingRequest])

  const bbcDocuments = useMemo(() => {
    const order = [
      REPORTING_DOCUMENT_TYPES.apAging,
      REPORTING_DOCUMENT_TYPES.arAging,
      REPORTING_DOCUMENT_TYPES.inventoryDetail,
    ]
    return clientInfo?.bbcDocuments.sort((a, b) => {
      return order.indexOf(a.documentName) - order.indexOf(b.documentName)
    })
  }, [clientInfo])

  const toggleJustRequested = useCallback(() => {
    setJustRequested((value) => !value)
  }, [])

  const toggleIsAlreadyStartedModalShown = useCallback(() => {
    setIsAlreadyStartedModalShown((value) => !value)
  }, [])

  const toggleIsCancelModalShown = useCallback(() => {
    setIsCancelModalShown((value) => !value)
  }, [])

  const handleCancelRequest = useCallback(() => {
    if (fundingRequest?.status !== FundingRequestStatus.Requested) {
      setIsAlreadyStartedModalShown(true)
      return
    }
    setIsCancelModalShown(true)
  }, [fundingRequest])

  const handleConfirmCancelRequest = useCallback(
    async (reason: string) => {
      await cancelFunding({
        reason,
      })
      setIsCancelModalShown(false)
    },
    [cancelFunding],
  )

  const handleModifyRequest = useCallback(() => {
    if (fundingRequest?.status !== FundingRequestStatus.Requested) {
      setIsAlreadyStartedModalShown(true)
      return
    }
    setIsModifying(true)
  }, [fundingRequest])

  const handleNavigate = useCallback(
    (path) => {
      history.push(path)
    },
    [history],
  )

  const [openModal, setOpenModal] = useState(null)

  const handleToggleModal = useCallback(
    (title: string) => {
      if (openModal === title) {
        setOpenModal(null)
      } else {
        setOpenModal(title)
      }
    },
    [setOpenModal, openModal],
  )

  const showBoxSignSection = useMemo(() => {
    const fundingCompleted = fundingRequest?.status === FundingRequestStatus.Completed
    const notSigned = fundingRequest?.bbcSignatureFile?.boxSignStatus !== BoxSignStatus.signed
    const isAuthorized = user?.isAuthorizedSignature
    const isBoxSignEmbeddedUrl = fundingRequest?.bbcSignatureFile?.boxSignEmbeddedUrl
    return fundingCompleted && notSigned && isAuthorized && isBoxSignEmbeddedUrl
  }, [fundingRequest, user])

  if ([ClientInfoStatus.Past, ClientInfoStatus.Archived].includes(clientInfo?.clientStatus)) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.cardInProgressWrapper}
      >
        <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
            <ActionPendingIcon className={styles.iconPending} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <h2 className={genericSs.textCenter}>Payed Off</h2>
          </Grid>
          <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
            <div className={cn(genericSs.textCenter, styles.descriptionInProgress)}>
              Requesting funding is not allowed after payoff date.
            </div>
          </Grid>
        </Grid>
      </Box>
    )
  }

  if (!isModifying && fundingRequest?.id && justRequested) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.cardInProgressWrapper}
      >
        <Grid container spacing={1} justifyContent={'center'} alignItems={'center'}>
          <Grid mt={2} item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
            <ActionSuccessIcon className={styles.iconSuccess} />
          </Grid>
          <Grid item xs={12} lg={12}>
            <h2 className={genericSs.textCenter}>Funding Request Received</h2>
          </Grid>
          <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
            <div className={cn(genericSs.textCenter, styles.descriptionInProgress)}>
              We’re prepping your funds and will get back to you shortly.
            </div>
          </Grid>
          <Grid item xs={12} lg={12} justifyContent={'center'} display={'flex'}>
            <span
              onClick={toggleJustRequested}
              className={cn(genericSs.textCenter, styles.trackFundsLink)}
            >
              Track my request
            </span>
          </Grid>
          {![FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(
            fundingRequest?.status as FundingRequestStatus,
          ) && (
            <ModifyFundingSection
              handleCancelRequest={handleCancelRequest}
              handleModifyRequest={handleModifyRequest}
              isAlreadyStartedModalShown={isAlreadyStartedModalShown}
              toggleIsAlreadyStartedModalShown={toggleIsAlreadyStartedModalShown}
              isCancelModalShown={isCancelModalShown}
              toggleIsCancelModalShown={toggleIsCancelModalShown}
              handleConfirmCancelRequest={handleConfirmCancelRequest}
            />
          )}
        </Grid>

        <br />
      </Box>
    )
  }

  if (!isModifying && fundingRequest?.id && !justRequested) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        className={styles.cardInProgressWrapper}
      >
        <Grid container spacing={5}>
          <Grid item xs={12} lg={12} mt={5.5}>
            <h2 className={genericSs.textCenter}>Funding Request Tracker</h2>
          </Grid>
          <Grid item xs={12} lg={12}>
            <StepContent activeStep={activeStep} />
          </Grid>
          {![FundingRequestStatus.Completed, FundingRequestStatus.Sent].includes(
            fundingRequest?.status as FundingRequestStatus,
          ) ? (
            <ModifyFundingSection
              handleCancelRequest={handleCancelRequest}
              handleModifyRequest={handleModifyRequest}
              isAlreadyStartedModalShown={isAlreadyStartedModalShown}
              toggleIsAlreadyStartedModalShown={toggleIsAlreadyStartedModalShown}
              isCancelModalShown={isCancelModalShown}
              toggleIsCancelModalShown={toggleIsCancelModalShown}
              handleConfirmCancelRequest={handleConfirmCancelRequest}
            />
          ) : showBoxSignSection ? (
            <BoxSignSection
              file={fundingRequest?.bbcSignatureFile}
              setBoxViewerLink={setBoxViewerLink}
            />
          ) : null}
        </Grid>
      </Box>
    )
  }

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={handleRequestFunding}
        validate={makeValidate(schema)}
        mutators={{ setFieldTouched, ...arrayMutators }}
        render={({
          values,
          invalid,
          handleSubmit,
        }: {
          values: any
          invalid: boolean
          handleSubmit: any
        }) => (
          <form onSubmit={handleSubmit} ref={formRef} className={styles.gridContainer}>
            <Grid
              container
              spacing={3}
              alignItems="flex-start"
              justifyContent={'space-between'}
              className={styles.requestFundingWrapper}
            >
              <Grid item xs={5}>
                <FormControl>
                  <span className={genericSs.header}>Funding Amount</span>
                  <Field name="amountType" type="radio" className={styles.radio}>
                    {({ input }) => (
                      <FormControlLabel
                        value={FundingRequestAmountType.Max}
                        className={styles.radio}
                        classes={{ label: styles.radioLabel }}
                        control={
                          <Radio
                            {...input}
                            size="small"
                            className={styles.radioIcon}
                            value={FundingRequestAmountType.Max}
                            checked={values.amountType === FundingRequestAmountType.Max}
                            onChange={() => input.onChange(FundingRequestAmountType.Max)}
                            disabled={isLoading}
                          />
                        }
                        label="Maximum Amount"
                      />
                    )}
                  </Field>
                  <Field name="amountType" type="radio" className={styles.radio}>
                    {({ input }) => (
                      <FormControlLabel
                        value={FundingRequestAmountType.Specific}
                        className={cn(styles.radio, {
                          [styles.specificAmount]:
                            values.amountType === FundingRequestAmountType.Specific,
                        })}
                        classes={{ label: styles.radioLabel }}
                        control={
                          <Radio
                            {...input}
                            size="small"
                            className={styles.radioIcon}
                            value={FundingRequestAmountType.Specific}
                            checked={values.amountType === FundingRequestAmountType.Specific}
                            onChange={() => input.onChange(FundingRequestAmountType.Specific)}
                            disabled={isLoading}
                          />
                        }
                        label="Specific Amount"
                      />
                    )}
                  </Field>
                  {values.amountType === FundingRequestAmountType.Specific && (
                    <Field name="amount">
                      {(props) => (
                        <CurrencyField
                          className={styles.amountField}
                          name={props.input.name}
                          value={props.input.value}
                          onChange={props.input.onChange}
                          placeholder="Amount"
                          disabled={
                            values.amountType !== FundingRequestAmountType.Specific || isLoading
                          }
                          size="large"
                          classes={{
                            root: styles.currencyRoot,
                          }}
                        />
                      )}
                    </Field>
                  )}
                  <Field name="amountType" type="radio">
                    {({ input }) => (
                      <FormControlLabel
                        value={FundingRequestAmountType.TBD}
                        className={styles.radio}
                        classes={{ label: styles.radioLabel }}
                        control={
                          <Radio
                            {...input}
                            disabled={isLoading}
                            size="small"
                            className={styles.radioIcon}
                            checked={values.amountType === FundingRequestAmountType.TBD}
                            onChange={() => input.onChange(FundingRequestAmountType.TBD)}
                          />
                        }
                        label="Decide Later"
                      />
                    )}
                  </Field>
                </FormControl>
              </Grid>
              <Grid item xs={7}>
                <span className={cn(genericSs.header, styles.header)}>Upload Documents</span>
                <div className={styles.documentItemContainer}>
                  {bbcDocuments.map((item) => (
                    <Box key={item.id} className={styles.documentItem}>
                      <FieldArray name={item.name}>
                        {({ fields }) => (
                          <div className={styles.documentItemFiles}>
                            <UploadFile
                              disabled={isLoading}
                              title={
                                REPORTING_DOCUMENTS_TITLES[item.documentName] || item.documentName
                              }
                              key={item.documentName}
                              size="lg"
                              onDropAccepted={(loadedFiles: File[]) => {
                                setIsDirty(true)
                                loadedFiles.forEach((file) => fields.push(file))
                              }}
                              acceptedFileTypes={['excel']}
                              files={values[item.name]}
                              onDelete={(index) => {
                                index.sort((a, b) => b - a).forEach((i) => fields.remove(i))
                              }}
                              isModalOpen={
                                openModal ===
                                (REPORTING_DOCUMENTS_TITLES[item.documentName] || item.documentName)
                              }
                              handleToggleModal={handleToggleModal}
                            />
                          </div>
                        )}
                      </FieldArray>
                    </Box>
                  ))}
                </div>
              </Grid>
            </Grid>
            <Grid className={styles.submitButton} item xs={2.5}>
              <Box display="flex" justifyContent="flex-end" mt={1} mb={1} gap={4}>
                <Button
                  disabled={invalid}
                  type="submit"
                  color="primary"
                  variant="contained"
                  small={false}
                  isLoading={isLoading}
                >
                  Submit
                </Button>

                {isModifying && (
                  <Button
                    type="button"
                    color="primary"
                    variant="outlined"
                    small={false}
                    onClick={handleCancelModify}
                  >
                    Cancel modify
                  </Button>
                )}
              </Box>
            </Grid>

            {isConfirmModalShown && (
              <Modal
                open={isConfirmModalShown}
                onCancel={handleCancel}
                title="Confirmation"
                footer={[
                  <Button
                    key="cancel"
                    color="primary"
                    variant="outlined"
                    onClick={handleCancel}
                    secondary
                  >
                    No
                  </Button>,
                  <Button
                    key="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      isSubmitConfirmed.current = true
                      setIsConfirmModalShown(false)
                      handleSubmit()
                    }}
                  >
                    Yes
                  </Button>,
                ]}
              >
                <p className={genericSs.textTransformNone}>
                  Are you sure you want to request funding without submitting an{' '}
                  {missingDocument.current}
                  &nbsp;Report?
                </p>
              </Modal>
            )}
            <RouteLeavingGuard
              when={isDirty}
              navigate={handleNavigate}
              shouldBlockNavigation={() => isDirty}
              alternateSubmit={handleRequestFunding}
              isAlternateSubmitInvalid={invalid}
            />
          </form>
        )}
      />
    </>
  )
}

export default RequestFunding
