import { IApi } from '../../api'
import { CommonPromiseAction } from '../types'
import { createRequestTypes } from '../../helpers/helpers'

export const prefix = 'bbc'

export const SHOW_REQUEST = `${prefix}/SHOW_REQUEST`
export const SHOW_SUCCESS = `${prefix}/SHOW_SUCCESS`
export const SHOW_FAILURE = `${prefix}/SHOW_FAILURE`

export const show: CommonPromiseAction = (id: string, params: object = {}) => ({
  type: [SHOW_REQUEST, SHOW_SUCCESS, SHOW_FAILURE],
  promise: (api: IApi) => api.bbc.show(id, params),
  params,
})

export const HIDE = `${prefix}/HIDE`

export const hide = () => ({
  type: HIDE,
})

export const UPDATE_BBC_REQUEST = `${prefix}/UPDATE_BBC_REQUEST`
export const UPDATE_BBC_SUCCESS = `${prefix}/UPDATE_BBC_SUCCESS`
export const UPDATE_BBC_FAILURE = `${prefix}/UPDATE_BBC_FAILURE`

export const updateBBC: CommonPromiseAction = (id: string, data: object) => ({
  type: [UPDATE_BBC_REQUEST, UPDATE_BBC_SUCCESS, UPDATE_BBC_FAILURE],
  promise: (api: IApi) => api.bbc.update(id, data),
})

export const GET_BBC_SUMMARY_STATS_REQUEST = `${prefix}/GET_BBC_SUMMARY_STATS_REQUEST`
export const GET_BBC_SUMMARY_STATS_SUCCESS = `${prefix}/GET_BBC_SUMMARY_STATS_SUCCESS`
export const GET_BBC_SUMMARY_STATS_FAILURE = `${prefix}/GET_BBC_SUMMARY_STATS_FAILURE`

export const getBBCSummaryStats: CommonPromiseAction = (id: string, params?: object) => ({
  type: [
    GET_BBC_SUMMARY_STATS_REQUEST,
    GET_BBC_SUMMARY_STATS_SUCCESS,
    GET_BBC_SUMMARY_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getSummaryStats(id, params),
})

export const GET_BBC_AR_SUMMARY_STATS_REQUEST = `${prefix}/GET_BBC_AR_SUMMARY_STATS_REQUEST`
export const GET_BBC_AR_SUMMARY_STATS_SUCCESS = `${prefix}/GET_BBC_AR_SUMMARY_STATS_SUCCESS`
export const GET_BBC_AR_SUMMARY_STATS_FAILURE = `${prefix}/GET_BBC_AR_SUMMARY_STATS_FAILURE`

export const getBBCARSummaryStats: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AR_SUMMARY_STATS_REQUEST,
    GET_BBC_AR_SUMMARY_STATS_SUCCESS,
    GET_BBC_AR_SUMMARY_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARSummaryStats(id, params),
  params,
})

export const GET_BBC_AR_DETAIL_STATS_REQUEST = `${prefix}/GET_BBC_AR_DETAIL_STATS_REQUEST`
export const GET_BBC_AR_DETAIL_STATS_SUCCESS = `${prefix}/GET_BBC_AR_DETAIL_STATS_SUCCESS`
export const GET_BBC_AR_DETAIL_STATS_FAILURE = `${prefix}/GET_BBC_AR_DETAIL_STATS_FAILURE`

export const getBBCARDetailStats: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AR_DETAIL_STATS_REQUEST,
    GET_BBC_AR_DETAIL_STATS_SUCCESS,
    GET_BBC_AR_DETAIL_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARDetailStats(id, params),
  params,
})

export const GET_BBC_AP_SUMMARY_STATS_REQUEST = `${prefix}/GET_BBC_AP_SUMMARY_STATS_REQUEST`
export const GET_BBC_AP_SUMMARY_STATS_SUCCESS = `${prefix}/GET_BBC_AP_SUMMARY_STATS_SUCCESS`
export const GET_BBC_AP_SUMMARY_STATS_FAILURE = `${prefix}/GET_BBC_AP_SUMMARY_STATS_FAILURE`

export const getBBCAPSummaryStats: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AP_SUMMARY_STATS_REQUEST,
    GET_BBC_AP_SUMMARY_STATS_SUCCESS,
    GET_BBC_AP_SUMMARY_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getAPSummaryStats(id, params),
  params,
})

export const GET_BBC_AP_DETAIL_STATS_REQUEST = `${prefix}/GET_BBC_AP_DETAIL_STATS_REQUEST`
export const GET_BBC_AP_DETAIL_STATS_SUCCESS = `${prefix}/GET_BBC_AP_DETAIL_STATS_SUCCESS`
export const GET_BBC_AP_DETAIL_STATS_FAILURE = `${prefix}/GET_BBC_AP_DETAIL_STATS_FAILURE`

export const getBBCAPDetailStats: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AP_DETAIL_STATS_REQUEST,
    GET_BBC_AP_DETAIL_STATS_SUCCESS,
    GET_BBC_AP_DETAIL_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getAPDetailStats(id, params),
  params,
})

export const GET_BBC_AR_AP_CHART_DATA_REQUEST = `${prefix}/GET_BBC_AR_AP_CHART_DATA_REQUEST`
export const GET_BBC_AR_AP_CHART_DATA_SUCCESS = `${prefix}/GET_BBC_AR_AP_CHART_DATA_SUCCESS`
export const GET_BBC_AR_AP_CHART_DATA_FAILURE = `${prefix}/GET_BBC_AR_AP_CHART_DATA_FAILURE`

export const getARAPChartData: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AR_AP_CHART_DATA_REQUEST,
    GET_BBC_AR_AP_CHART_DATA_SUCCESS,
    GET_BBC_AR_AP_CHART_DATA_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARAPChartData(id, params),
  params,
})

const GET_AR_AP_CHART_DROPDOWN_OPTIONS = createRequestTypes(
  prefix,
  'GET_AR_AP_CHART_DROPDOWN_OPTIONS',
)
export const [
  GET_AR_AP_CHART_DROPDOWN_OPTIONS_REQUEST,
  GET_AR_AP_CHART_DROPDOWN_OPTIONS_SUCCESS,
  GET_AR_AP_CHART_DROPDOWN_OPTIONS_FAILURE,
] = GET_AR_AP_CHART_DROPDOWN_OPTIONS

export const getARAPChartDropdownOptions: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_AR_AP_CHART_DROPDOWN_OPTIONS_REQUEST,
    GET_AR_AP_CHART_DROPDOWN_OPTIONS_SUCCESS,
    GET_AR_AP_CHART_DROPDOWN_OPTIONS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARAPChartDropdownOptions(id, params),
  params,
})

export const GET_BBC_AR_ROLLFORWARD_STATS_REQUEST = `${prefix}/GET_BBC_AR_ROLLFORWARD_STATS_REQUEST`
export const GET_BBC_AR_ROLLFORWARD_STATS_SUCCESS = `${prefix}/GET_BBC_AR_ROLLFORWARD_STATS_SUCCESS`
export const GET_BBC_AR_ROLLFORWARD_STATS_FAILURE = `${prefix}/GET_BBC_AR_ROLLFORWARD_STATS_FAILURE`

export const getBBCARRollforward: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AR_ROLLFORWARD_STATS_REQUEST,
    GET_BBC_AR_ROLLFORWARD_STATS_SUCCESS,
    GET_BBC_AR_ROLLFORWARD_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARRollforward(id, params),
  params,
})

export const GET_BBC_AR_ROLLFORWARD_DETAILS_REQUEST = `${prefix}/GET_BBC_AR_ROLLFORWARD_DETAILS_REQUEST`
export const GET_BBC_AR_ROLLFORWARD_DETAILS_SUCCESS = `${prefix}/GET_BBC_AR_ROLLFORWARD_DETAILS_SUCCESS`
export const GET_BBC_AR_ROLLFORWARD_DETAILS_FAILURE = `${prefix}/GET_BBC_AR_ROLLFORWARD_DETAILS_FAILURE`

export const getBBCARRollforwardDetails: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AR_ROLLFORWARD_DETAILS_REQUEST,
    GET_BBC_AR_ROLLFORWARD_DETAILS_SUCCESS,
    GET_BBC_AR_ROLLFORWARD_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARRollforwardDetails(id, params),
  params,
})

export const GET_BBC_AR_ROLLFORWARD_GRAPH_REQUEST = `${prefix}/GET_BBC_AR_ROLLFORWARD_GRAPH_REQUEST`
export const GET_BBC_AR_ROLLFORWARD_GRAPH_SUCCESS = `${prefix}/GET_BBC_AR_ROLLFORWARD_GRAPH_SUCCESS`
export const GET_BBC_AR_ROLLFORWARD_GRAPH_FAILURE = `${prefix}/GET_BBC_AR_ROLLFORWARD_GRAPH_FAILURE`

export const getBBCARRollforwardGraph: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AR_ROLLFORWARD_GRAPH_REQUEST,
    GET_BBC_AR_ROLLFORWARD_GRAPH_SUCCESS,
    GET_BBC_AR_ROLLFORWARD_GRAPH_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getARRollforwardGraph(id, params),
  params,
})

export const EXPORT_BBC_AR_ROLLFORWARD_REQUEST = `${prefix}/EXPORT_BBC_AR_ROLLFORWARD_REQUEST`
export const EXPORT_BBC_AR_ROLLFORWARD_SUCCESS = `${prefix}/EXPORT_BBC_AR_ROLLFORWARD_SUCCESS`
export const EXPORT_BBC_AR_ROLLFORWARD_FAILURE = `${prefix}/EXPORT_BBC_AR_ROLLFORWARD_FAILURE`

export const exportBBCARRollforward = (id: string, params: object) => ({
  type: [
    EXPORT_BBC_AR_ROLLFORWARD_REQUEST,
    EXPORT_BBC_AR_ROLLFORWARD_SUCCESS,
    EXPORT_BBC_AR_ROLLFORWARD_FAILURE,
  ],
  promise: (api: IApi) =>
    api.bbc.getARRollforward(id, {
      ...params,
      isFile: true,
    }),
  params,
})

export const GET_BBC_AP_ROLLFORWARD_STATS_REQUEST = `${prefix}/GET_BBC_AP_ROLLFORWARD_STATS_REQUEST`
export const GET_BBC_AP_ROLLFORWARD_STATS_SUCCESS = `${prefix}/GET_BBC_AP_ROLLFORWARD_STATS_SUCCESS`
export const GET_BBC_AP_ROLLFORWARD_STATS_FAILURE = `${prefix}/GET_BBC_AP_ROLLFORWARD_STATS_FAILURE`

export const getBBCAPRollforward: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AP_ROLLFORWARD_STATS_REQUEST,
    GET_BBC_AP_ROLLFORWARD_STATS_SUCCESS,
    GET_BBC_AP_ROLLFORWARD_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getAPRollforward(id, params),
  params,
})

export const GET_BBC_AP_ROLLFORWARD_DETAILS_REQUEST = `${prefix}/GET_BBC_AP_ROLLFORWARD_DETAILS_REQUEST`
export const GET_BBC_AP_ROLLFORWARD_DETAILS_SUCCESS = `${prefix}/GET_BBC_AP_ROLLFORWARD_DETAILS_SUCCESS`
export const GET_BBC_AP_ROLLFORWARD_DETAILS_FAILURE = `${prefix}/GET_BBC_AP_ROLLFORWARD_DETAILS_FAILURE`

export const getBBCAPRollforwardDetails: CommonPromiseAction = (id: string, params: object) => ({
  type: [
    GET_BBC_AP_ROLLFORWARD_DETAILS_REQUEST,
    GET_BBC_AP_ROLLFORWARD_DETAILS_SUCCESS,
    GET_BBC_AP_ROLLFORWARD_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getAPRollforwardDetails(id, params),
  params,
})

export const EXPORT_BBC_AP_ROLLFORWARD_REQUEST = `${prefix}/EXPORT_BBC_AP_ROLLFORWARD_REQUEST`
export const EXPORT_BBC_AP_ROLLFORWARD_SUCCESS = `${prefix}/EXPORT_BBC_AP_ROLLFORWARD_SUCCESS`
export const EXPORT_BBC_AP_ROLLFORWARD_FAILURE = `${prefix}/EXPORT_BBC_AP_ROLLFORWARD_FAILURE`

export const exportBBCAPRollforward = (id: string, params: object) => ({
  type: [
    EXPORT_BBC_AP_ROLLFORWARD_REQUEST,
    EXPORT_BBC_AP_ROLLFORWARD_SUCCESS,
    EXPORT_BBC_AP_ROLLFORWARD_FAILURE,
  ],
  promise: (api: IApi) =>
    api.bbc.getAPRollforward(id, {
      ...params,
      isFile: true,
    }),
  params,
})

export const GET_BBC_FILES_REQUEST = `${prefix}/GET_BBC_FILES_REQUEST`
export const GET_BBC_FILES_SUCCESS = `${prefix}/GET_BBC_FILES_SUCCESS`
export const GET_BBC_FILES_FAILURE = `${prefix}/GET_BBC_FILES_FAILURE`

export const getBBCFiles: CommonPromiseAction = (id: string) => ({
  type: [GET_BBC_FILES_REQUEST, GET_BBC_FILES_SUCCESS, GET_BBC_FILES_FAILURE],
  promise: (api: IApi) => api.bbc.getFiles(id),
})

export const UPLOAD_BBC_FILES_REQUEST = `${prefix}/UPLOAD_BBC_FILES_REQUEST`
export const UPLOAD_BBC_FILES_SUCCESS = `${prefix}/UPLOAD_BBC_FILES_SUCCESS`
export const UPLOAD_BBC_FILES_FAILURE = `${prefix}/UPLOAD_BBC_FILES_FAILURE`

export const uploadBBCFiles: CommonPromiseAction = (id: string, data: FormData) => ({
  type: [UPLOAD_BBC_FILES_REQUEST, UPLOAD_BBC_FILES_SUCCESS, UPLOAD_BBC_FILES_FAILURE],
  promise: (api: IApi) => api.bbc.uploadBBCFiles(id, data),
})

export const SUBMIT_BBC_FILES_REQUEST = `${prefix}/SUBMIT_BBC_FILES_REQUEST`
export const SUBMIT_BBC_FILES_SUCCESS = `${prefix}/SUBMIT_BBC_FILES_SUCCESS`
export const SUBMIT_BBC_FILES_FAILURE = `${prefix}/SUBMIT_BBC_FILES_FAILURE`

export const submitBBCFiles: CommonPromiseAction = (id: string, data: object) => ({
  type: [SUBMIT_BBC_FILES_REQUEST, SUBMIT_BBC_FILES_SUCCESS, SUBMIT_BBC_FILES_FAILURE],
  promise: (api: IApi) => api.bbc.submitBBCFiles(id, data),
})

export const SUBMIT_FOR_REVIEW_REQUEST = `${prefix}/SUBMIT_FOR_REVIEW_REQUEST`
export const SUBMIT_FOR_REVIEW_SUCCESS = `${prefix}/SUBMIT_FOR_REVIEW_SUCCESS`
export const SUBMIT_FOR_REVIEW_FAILURE = `${prefix}/SUBMIT_FOR_REVIEW_FAILURE`

export const submitForReview: CommonPromiseAction = (id: string) => ({
  type: [SUBMIT_FOR_REVIEW_REQUEST, SUBMIT_FOR_REVIEW_SUCCESS, SUBMIT_FOR_REVIEW_FAILURE],
  promise: (api: IApi) => api.bbc.submitForReview(id),
})

export const SUBMIT_REQUEST = `${prefix}/SUBMIT_REQUEST`
export const SUBMIT_SUCCESS = `${prefix}/SUBMIT_SUCCESS`
export const SUBMIT_FAILURE = `${prefix}/SUBMIT_FAILURE`

export const submit: CommonPromiseAction = (id: string, data: object = {}) => ({
  type: [SUBMIT_REQUEST, SUBMIT_SUCCESS, SUBMIT_FAILURE],
  promise: (api: IApi) => api.bbc.submit(id, data),
})

export const INVENTORY_INELIGIBLE_SUBMIT_REQUEST = `${prefix}/INVENTORY_INELIGIBLE_SUBMIT_REQUEST`
export const INVENTORY_INELIGIBLE_SUBMIT_SUCCESS = `${prefix}/INVENTORY_INELIGIBLE_SUBMIT_SUCCESS`
export const INVENTORY_INELIGIBLE_SUBMIT_FAILURE = `${prefix}/INVENTORY_INELIGIBLE_SUBMIT_FAILURE`

export const inventoryIneligibleSubmit: CommonPromiseAction = (id: string) => ({
  type: [
    INVENTORY_INELIGIBLE_SUBMIT_REQUEST,
    INVENTORY_INELIGIBLE_SUBMIT_SUCCESS,
    INVENTORY_INELIGIBLE_SUBMIT_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.inventoryIneligibleSubmit(id),
})

export const LIST_INVENTORY_INELIGIBLE_CATEGORIES_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_CATEGORIES_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_CATEGORIES_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_CATEGORIES_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_CATEGORIES_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_CATEGORIES_FAILURE`

export const listInventoryIneligibleCategories: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_CATEGORIES_REQUEST,
    LIST_INVENTORY_INELIGIBLE_CATEGORIES_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_CATEGORIES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listInventoryIneligibleCategories(id, data),
})

export const LIST_MASTER_INVENTORY_REQUEST = `${prefix}/LIST_MASTER_INVENTORY_REQUEST`
export const LIST_MASTER_INVENTORY_SUCCESS = `${prefix}/LIST_MASTER_INVENTORY_SUCCESS`
export const LIST_MASTER_INVENTORY_FAILURE = `${prefix}/LIST_MASTER_INVENTORY_FAILURE`

export const listMasterInventory: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    LIST_MASTER_INVENTORY_REQUEST,
    LIST_MASTER_INVENTORY_SUCCESS,
    LIST_MASTER_INVENTORY_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listMasterInventory(id, data),
  params: data,
})

export const UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_REQUEST = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_REQUEST`
export const UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_SUCCESS = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_SUCCESS`
export const UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_FAILURE = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_FAILURE`

export const updateInventoryIneligibleCategories: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [
    UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_REQUEST,
    UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_SUCCESS,
    UPDATE_INVENTORY_INELIGIBLE_CATEGORIES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.updateInventoryIneligibleCategories(id, itemId, data),
})

export const UPDATE_MASTER_INVENTORY_REQUEST = `${prefix}/UPDATE_MASTER_INVENTORY_REQUEST`
export const UPDATE_MASTER_INVENTORY_SUCCESS = `${prefix}/UPDATE_MASTER_INVENTORY_SUCCESS`
export const UPDATE_MASTER_INVENTORY_FAILURE = `${prefix}/UPDATE_MASTER_INVENTORY_FAILURE`

export const updateMasterInventory: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [
    UPDATE_MASTER_INVENTORY_REQUEST,
    UPDATE_MASTER_INVENTORY_SUCCESS,
    UPDATE_MASTER_INVENTORY_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.updateMasterInventory(id, itemId, data),
})

export const LIST_AR_INELIGIBILITY_REQUEST = `${prefix}/LIST_AR_INELIGIBILITY_REQUEST`
export const LIST_AR_INELIGIBILITY_SUCCESS = `${prefix}/LIST_AR_INELIGIBILITY_SUCCESS`
export const LIST_AR_INELIGIBILITY_FAILURE = `${prefix}/LIST_AR_INELIGIBILITY_FAILURE`

export const listArIneligibility: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    LIST_AR_INELIGIBILITY_REQUEST,
    LIST_AR_INELIGIBILITY_SUCCESS,
    LIST_AR_INELIGIBILITY_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listArIneligibility(id, data),
  params: data,
})

export const UPDATE_AR_INELIGIBILITY_REQUEST = `${prefix}/UPDATE_AR_INELIGIBILITY_REQUEST`
export const UPDATE_AR_INELIGIBILITY_SUCCESS = `${prefix}/UPDATE_AR_INELIGIBILITY_SUCCESS`
export const UPDATE_AR_INELIGIBILITY_FAILURE = `${prefix}/UPDATE_AR_INELIGIBILITY_FAILURE`

export const updateArIneligibility: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [
    UPDATE_AR_INELIGIBILITY_REQUEST,
    UPDATE_AR_INELIGIBILITY_SUCCESS,
    UPDATE_AR_INELIGIBILITY_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.updateArIneligibility(id, itemId, data),
})

export const SUBMIT_AR_INELIGIBILITY_REQUEST = `${prefix}/SUBMIT_AR_INELIGIBILITY_REQUEST`
export const SUBMIT_AR_INELIGIBILITY_SUCCESS = `${prefix}/SUBMIT_AR_INELIGIBILITY_SUCCESS`
export const SUBMIT_AR_INELIGIBILITY_FAILURE = `${prefix}/SUBMIT_AR_INELIGIBILITY_FAILURE`

export const submitArIneligibility: CommonPromiseAction = (id: string) => ({
  type: [
    SUBMIT_AR_INELIGIBILITY_REQUEST,
    SUBMIT_AR_INELIGIBILITY_SUCCESS,
    SUBMIT_AR_INELIGIBILITY_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.submitArIneligibility(id),
})

export const LIST_EXTRA_RESERVES_REQUEST = `${prefix}/LIST_EXTRA_RESERVES_REQUEST`
export const LIST_EXTRA_RESERVES_SUCCESS = `${prefix}/LIST_EXTRA_RESERVES_SUCCESS`
export const LIST_EXTRA_RESERVES_FAILURE = `${prefix}/LIST_EXTRA_RESERVES_FAILURE`

export const listExtraReserves: CommonPromiseAction = (id: string, params?: object) => ({
  type: [LIST_EXTRA_RESERVES_REQUEST, LIST_EXTRA_RESERVES_SUCCESS, LIST_EXTRA_RESERVES_FAILURE],
  promise: (api: IApi) => api.bbc.listExtraReserves(id, params),
  params,
})

export const UPDATE_EXTRA_RESERVES_REQUEST = `${prefix}/UPDATE_EXTRA_RESERVES_REQUEST`
export const UPDATE_EXTRA_RESERVES_SUCCESS = `${prefix}/UPDATE_EXTRA_RESERVES_SUCCESS`
export const UPDATE_EXTRA_RESERVES_FAILURE = `${prefix}/UPDATE_EXTRA_RESERVES_FAILURE`

export const updateExtraReserves: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_EXTRA_RESERVES_REQUEST,
    UPDATE_EXTRA_RESERVES_SUCCESS,
    UPDATE_EXTRA_RESERVES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.updateExtraReserves(id, data),
})

export const LIST_INVENTORY_INELIGIBLE_FIELDS_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_FIELDS_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_FIELDS_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_FIELDS_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_FIELDS_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_FIELDS_FAILURE`

export const listFields: CommonPromiseAction = (data: object) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
    LIST_INVENTORY_INELIGIBLE_FIELDS_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_FIELDS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listFields(data),
  params: data,
})

export const CREATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST = `${prefix}/CREATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST`
export const CREATE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS = `${prefix}/CREATE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS`
export const CREATE_INVENTORY_INELIGIBLE_FIELDS_FAILURE = `${prefix}/CREATE_INVENTORY_INELIGIBLE_FIELDS_FAILURE`

export const createFields: CommonPromiseAction = (data: object) => ({
  type: [
    CREATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
    CREATE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS,
    CREATE_INVENTORY_INELIGIBLE_FIELDS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.createFields(data),
})

export const UPDATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST`
export const UPDATE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS`
export const UPDATE_INVENTORY_INELIGIBLE_FIELDS_FAILURE = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_FIELDS_FAILURE`

export const updateFields: CommonPromiseAction = (data: object) => ({
  type: [
    UPDATE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
    UPDATE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS,
    UPDATE_INVENTORY_INELIGIBLE_FIELDS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.updateFields(data),
})

export const DELETE_INVENTORY_INELIGIBLE_FIELDS_REQUEST = `${prefix}/DELETE_INVENTORY_INELIGIBLE_FIELDS_REQUEST`
export const DELETE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS = `${prefix}/DELETE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS`
export const DELETE_INVENTORY_INELIGIBLE_FIELDS_FAILURE = `${prefix}/DELETE_INVENTORY_INELIGIBLE_FIELDS_FAILURE`

export const deleteField: CommonPromiseAction = (id: string) => ({
  type: [
    DELETE_INVENTORY_INELIGIBLE_FIELDS_REQUEST,
    DELETE_INVENTORY_INELIGIBLE_FIELDS_SUCCESS,
    DELETE_INVENTORY_INELIGIBLE_FIELDS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.deleteField(id),
})

export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE`

export const listCustomRules: CommonPromiseAction = (data: object) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listCustomRules(data),
  params: data,
})

export const CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST = `${prefix}/CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST`
export const CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS = `${prefix}/CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS`
export const CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE = `${prefix}/CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE`

export const createCustomRules: CommonPromiseAction = (data: object) => ({
  type: [
    CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
    CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
    CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.createCustomRules(data),
})

export const UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST`
export const UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS`
export const UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE = `${prefix}/UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE`

export const updateCustomRule: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
    UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
    UPDATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.updateCustomRule(id, data),
})

export const DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST = `${prefix}/DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST`
export const DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS = `${prefix}/DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS`
export const DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE = `${prefix}/DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE`

export const deleteCustomRule: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_REQUEST,
    DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_SUCCESS,
    DELETE_INVENTORY_INELIGIBLE_CUSTOM_RULES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.deleteCustomRule(id, data),
})

export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_FAILURE`

export const listCustomRulesLabels: CommonPromiseAction = () => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_REQUEST,
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listCustomRulesLabels(),
})

export const CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_REQUEST = `${prefix}/CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_REQUEST`
export const CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS = `${prefix}/CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS`
export const CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_FAILURE = `${prefix}/CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_FAILURE`

export const createCustomRulesLabel: CommonPromiseAction = (data: object) => ({
  type: [
    CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_REQUEST,
    CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_SUCCESS,
    CREATE_INVENTORY_INELIGIBLE_CUSTOM_RULES_LABELS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.createCustomRulesLabel(data),
})

export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_FAILURE`

export const listCustomRulesValues: CommonPromiseAction = (data: {
  clientName: string
  field: string
}) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_REQUEST,
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_CUSTOM_RULES_VALUES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listCustomRulesValues(data),
  field: data.field,
})

export const LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_REQUEST = `${prefix}/LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_REQUEST`
export const LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_SUCCESS = `${prefix}/LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_SUCCESS`
export const LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_FAILURE = `${prefix}/LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_FAILURE`

export const listInventoryDistributionAnalysis: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: [
    LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_REQUEST,
    LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_SUCCESS,
    LIST_INVENTORY_DISTRIBUTION_ANALYSIS_TYPE_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listInventoryDistributionAnalysis(id, data),
})

export const LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_REQUEST = `${prefix}/LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_REQUEST`
export const LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_SUCCESS = `${prefix}/LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_SUCCESS`
export const LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_FAILURE = `${prefix}/LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_FAILURE`

export const listInventoryDistributionAnalysisCategories: CommonPromiseAction = (
  id: string,
  data: object,
) => ({
  type: [
    LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_REQUEST,
    LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_SUCCESS,
    LIST_INVENTORY_DISTRIBUTION_ANALYSIS_CATEGORIES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listInventoryDistributionAnalysisCategories(id, data),
})

export const LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_SUMMARY_STATS_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_SUMMARY_STATS_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_SUMMARY_STATS_FAILURE`

export const listInventoryIneligibleStatsSummary: CommonPromiseAction = (
  id: string,
  data?: object,
) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_REQUEST,
    LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_STATS_SUMMARY_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listInventoryIneligibleStatsSummary(id, data),
  params: data,
})

export const LIST_INVENTORY_INELIGIBLE_STATS_REASON_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_STATS_REASON_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_STATS_REASON_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_STATS_REASON_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_STATS_REASON_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_STATS_REASON_FAILURE`

export const listInventoryIneligibleStatsReason: CommonPromiseAction = (
  id: string,
  data?: object,
) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_STATS_REASON_REQUEST,
    LIST_INVENTORY_INELIGIBLE_STATS_REASON_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_STATS_REASON_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listInventoryIneligibleStatsReason(id, data),
  params: data,
})

export const LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_REQUEST = `${prefix}/LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_REQUEST`
export const LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_SUCCESS = `${prefix}/LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_SUCCESS`
export const LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_FAILURE = `${prefix}/LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_FAILURE`

export const listInventoryIneligibleStatsDetail: CommonPromiseAction = (
  id: string,
  data?: object,
) => ({
  type: [
    LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_REQUEST,
    LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_SUCCESS,
    LIST_INVENTORY_INELIGIBLE_STATS_DETAIL_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listInventoryIneligibleStatsDetail(id, data),
  params: data,
})

export const LIST_AR_INELIGIBLE_STATS_REQUEST = `${prefix}/LIST_AR_INELIGIBLE_STATS_REQUEST`
export const LIST_AR_INELIGIBLE_STATS_SUCCESS = `${prefix}/LIST_AR_INELIGIBLE_STATS_SUCCESS`
export const LIST_AR_INELIGIBLE_STATS_FAILURE = `${prefix}/LIST_AR_INELIGIBLE_STATS_FAILURE`

export const listARIneligibleStats: CommonPromiseAction = (id: string, params?: object) => ({
  type: [
    LIST_AR_INELIGIBLE_STATS_REQUEST,
    LIST_AR_INELIGIBLE_STATS_SUCCESS,
    LIST_AR_INELIGIBLE_STATS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listARIneligibleStats(id, params),
  params,
})
export const LIST_AR_INELIGIBLE_STATS_DETAILS_REQUEST = `${prefix}/LIST_AR_INELIGIBLE_STATS_DETAILS_REQUEST`
export const LIST_AR_INELIGIBLE_STATS_DETAILS_SUCCESS = `${prefix}/LIST_AR_INELIGIBLE_STATS_DETAILS_SUCCESS`
export const LIST_AR_INELIGIBLE_STATS_DETAILS_FAILURE = `${prefix}/LIST_AR_INELIGIBLE_STATS_DETAILS_FAILURE`

export const listArIneligibleStatsDetails: CommonPromiseAction = (id: string, params?: object) => ({
  type: [
    LIST_AR_INELIGIBLE_STATS_DETAILS_REQUEST,
    LIST_AR_INELIGIBLE_STATS_DETAILS_SUCCESS,
    LIST_AR_INELIGIBLE_STATS_DETAILS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listArIneligibleStatsDetails(id, params),
  params,
})
export const LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_REQUEST = `${prefix}/LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_REQUEST`
export const LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_SUCCESS = `${prefix}/LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_SUCCESS`
export const LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_FAILURE = `${prefix}/LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_FAILURE`

export const listArIneligibleStatsDetailsInvoices: CommonPromiseAction = (
  id: string,
  params?: object,
) => ({
  type: [
    LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_REQUEST,
    LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_SUCCESS,
    LIST_AR_INELIGIBLE_STATS_DETAILS_INVOICES_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.listArIneligibleStatsDetailsInvoices(id, params),
  params,
})

export const GET_PREVIOUS_CLIENT_COLLATERALS_REQUEST = `${prefix}/GET_PREVIOUS_CLIENT_COLLATERALS_REQUEST`
export const GET_PREVIOUS_CLIENT_COLLATERALS_SUCCESS = `${prefix}/GET_PREVIOUS_CLIENT_COLLATERALS_SUCCESS`
export const GET_PREVIOUS_CLIENT_COLLATERALS_FAILURE = `${prefix}/GET_PREVIOUS_CLIENT_COLLATERALS_FAILURE`

export const getPreviousBBCClientCollateral: CommonPromiseAction = (
  id: string,
  params?: object,
) => ({
  type: [
    GET_PREVIOUS_CLIENT_COLLATERALS_REQUEST,
    GET_PREVIOUS_CLIENT_COLLATERALS_SUCCESS,
    GET_PREVIOUS_CLIENT_COLLATERALS_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.getPreviousBBCClientCollateral(id, params),
  params,
})

export const CALCULATE_CLIENT_COLLATERAL_REQUEST = `${prefix}/CALCULATE_CLIENT_COLLATERAL_REQUEST`
export const CALCULATE_CLIENT_COLLATERAL_SUCCESS = `${prefix}/CALCULATE_CLIENT_COLLATERAL_SUCCESS`
export const CALCULATE_CLIENT_COLLATERAL_FAILURE = `${prefix}/CALCULATE_CLIENT_COLLATERAL_FAILURE`

export const calculateClientCollateral: CommonPromiseAction = (id: string) => ({
  type: [
    CALCULATE_CLIENT_COLLATERAL_REQUEST,
    CALCULATE_CLIENT_COLLATERAL_SUCCESS,
    CALCULATE_CLIENT_COLLATERAL_FAILURE,
  ],
  promise: (api: IApi) => api.bbc.calculateClientCollateral(id),
})

export const EXPORT_BBC_REQUEST = `${prefix}/EXPORT_BBC_REQUEST`
export const EXPORT_BBC_SUCCESS = `${prefix}/EXPORT_BBC_SUCCESS`
export const EXPORT_BBC_FAILURE = `${prefix}/EXPORT_BBC_FAILURE`

export const exportBBC: CommonPromiseAction = (
  id: string,
  clientName: string,
  recordDate: string,
) => ({
  type: [EXPORT_BBC_REQUEST, EXPORT_BBC_SUCCESS, EXPORT_BBC_FAILURE],
  promise: (api: IApi) => api.bbc.export(id),
  clientName,
  recordDate,
})

export const UPDATE_BBC_PDF_REQUEST = `${prefix}/UPDATE_BBC_PDF_REQUEST`
export const UPDATE_BBC_PDF_SUCCESS = `${prefix}/UPDATE_BBC_PDF_SUCCESS`
export const UPDATE_BBC_PDF_FAILURE = `${prefix}/UPDATE_BBC_PDF_FAILURE`

export const updateBBCPdf: CommonPromiseAction = (id: string) => ({
  type: [UPDATE_BBC_PDF_REQUEST, UPDATE_BBC_PDF_SUCCESS, UPDATE_BBC_PDF_FAILURE],
  promise: (api: IApi) => api.bbc.updatePDF(id),
})

export const DOWNLOAD_TEMPLATE_REQUEST = `${prefix}/DOWNLOAD_TEMPLATE_REQUEST`
export const DOWNLOAD_TEMPLATE_SUCCESS = `${prefix}/DOWNLOAD_TEMPLATE_SUCCESS`
export const DOWNLOAD_TEMPLATE_FAILURE = `${prefix}/DOWNLOAD_TEMPLATE_FAILURE`

export const downloadTemplate: CommonPromiseAction = (fileType?: string, fileId?: string) => ({
  type: [DOWNLOAD_TEMPLATE_REQUEST, DOWNLOAD_TEMPLATE_SUCCESS, DOWNLOAD_TEMPLATE_FAILURE],
  promise: (api: IApi) => api.bbc.downloadTemplate(fileType, fileId),
})

export const LIST_BBC_RESERVE_REQUEST = `${prefix}/LIST_BBC_RESERVE_REQUEST`
export const LIST_BBC_RESERVE_SUCCESS = `${prefix}/LIST_BBC_RESERVE_SUCCESS`
export const LIST_BBC_RESERVE_FAILURE = `${prefix}/LIST_BBC_RESERVE_FAILURE`

export const listBBCReserves: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_BBC_RESERVE_REQUEST, LIST_BBC_RESERVE_SUCCESS, LIST_BBC_RESERVE_FAILURE],
  promise: (api: IApi) => api.bbc.listBBCReserves(id, data),
  params: data,
})

export const CREATE_BBC_RESERVE_REQUEST = `${prefix}/CREATE_BBC_RESERVE_REQUEST`
export const CREATE_BBC_RESERVE_SUCCESS = `${prefix}/CREATE_BBC_RESERVE_SUCCESS`
export const CREATE_BBC_RESERVE_FAILURE = `${prefix}/CREATE_BBC_RESERVE_FAILURE`

export const createBBCReserve: CommonPromiseAction = (id: string, data: object) => ({
  type: [CREATE_BBC_RESERVE_REQUEST, CREATE_BBC_RESERVE_SUCCESS, CREATE_BBC_RESERVE_FAILURE],
  promise: (api: IApi) => api.bbc.createBBCReserve(id, data),
})

export const UPDATE_BBC_RESERVE_REQUEST = `${prefix}/UPDATE_BBC_RESERVE_REQUEST`
export const UPDATE_BBC_RESERVE_SUCCESS = `${prefix}/UPDATE_BBC_RESERVE_SUCCESS`
export const UPDATE_BBC_RESERVE_FAILURE = `${prefix}/UPDATE_BBC_RESERVE_FAILURE`

export const updateBBCReserve: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [UPDATE_BBC_RESERVE_REQUEST, UPDATE_BBC_RESERVE_SUCCESS, UPDATE_BBC_RESERVE_FAILURE],
  promise: (api: IApi) => api.bbc.updateBBCReserve(id, itemId, data),
})

export const DELETE_BBC_RESERVE_REQUEST = `${prefix}/DELETE_BBC_RESERVE_REQUEST`
export const DELETE_BBC_RESERVE_SUCCESS = `${prefix}/DELETE_BBC_RESERVE_SUCCESS`
export const DELETE_BBC_RESERVE_FAILURE = `${prefix}/DELETE_BBC_RESERVE_FAILURE`

export const deleteBBCReserve: CommonPromiseAction = (id: string, itemId: string) => ({
  type: [DELETE_BBC_RESERVE_REQUEST, DELETE_BBC_RESERVE_SUCCESS, DELETE_BBC_RESERVE_FAILURE],
  promise: (api: IApi) => api.bbc.deleteBBCReserve(id, itemId),
})

export const LIST_CLIENT_EXTRA_RESERVES_REQUEST = `${prefix}/LIST_CLIENT_EXTRA_RESERVES_REQUEST`
export const LIST_CLIENT_EXTRA_RESERVES_SUCCESS = `${prefix}/LIST_CLIENT_EXTRA_RESERVES_SUCCESS`
export const LIST_CLIENT_EXTRA_RESERVES_FAILURE = `${prefix}/LIST_CLIENT_EXTRA_RESERVES_FAILURE`

export const listClientExtraReserves: CommonPromiseAction = (id: string, params?: object) => ({
  type: [
    LIST_CLIENT_EXTRA_RESERVES_REQUEST,
    LIST_CLIENT_EXTRA_RESERVES_SUCCESS,
    LIST_CLIENT_EXTRA_RESERVES_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.listExtraReserves(id, params),
  params,
})

export const UPDATE_CLIENT_EXTRA_RESERVES_REQUEST = `${prefix}/UPDATE_CLIENT_EXTRA_RESERVES_REQUEST`
export const UPDATE_CLIENT_EXTRA_RESERVES_SUCCESS = `${prefix}/UPDATE_CLIENT_EXTRA_RESERVES_SUCCESS`
export const UPDATE_CLIENT_EXTRA_RESERVES_FAILURE = `${prefix}/UPDATE_CLIENT_EXTRA_RESERVES_FAILURE`

export const updateClientExtraReserves: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    UPDATE_CLIENT_EXTRA_RESERVES_REQUEST,
    UPDATE_CLIENT_EXTRA_RESERVES_SUCCESS,
    UPDATE_CLIENT_EXTRA_RESERVES_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateExtraReserves(id, data),
})

export const LIST_CLIENT_RESERVE_REQUEST = `${prefix}/LIST_CLIENT_RESERVE_REQUEST`
export const LIST_CLIENT_RESERVE_SUCCESS = `${prefix}/LIST_CLIENT_RESERVE_SUCCESS`
export const LIST_CLIENT_RESERVE_FAILURE = `${prefix}/LIST_CLIENT_RESERVE_FAILURE`

export const listClientReserves: CommonPromiseAction = (id: string, data: object) => ({
  type: [LIST_CLIENT_RESERVE_REQUEST, LIST_CLIENT_RESERVE_SUCCESS, LIST_CLIENT_RESERVE_FAILURE],
  promise: (api: IApi) => api.clientInfo.listOtherReserves(id, data),
  params: data,
})

export const CREATE_CLIENT_RESERVE_REQUEST = `${prefix}/CREATE_CLIENT_RESERVE_REQUEST`
export const CREATE_CLIENT_RESERVE_SUCCESS = `${prefix}/CREATE_CLIENT_RESERVE_SUCCESS`
export const CREATE_CLIENT_RESERVE_FAILURE = `${prefix}/CREATE_CLIENT_RESERVE_FAILURE`

export const createClientReserve: CommonPromiseAction = (id: string, data: object) => ({
  type: [
    CREATE_CLIENT_RESERVE_REQUEST,
    CREATE_CLIENT_RESERVE_SUCCESS,
    CREATE_CLIENT_RESERVE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.createOtherReserve(id, data),
})

export const UPDATE_CLIENT_RESERVE_REQUEST = `${prefix}/UPDATE_CLIENT_RESERVE_REQUEST`
export const UPDATE_CLIENT_RESERVE_SUCCESS = `${prefix}/UPDATE_CLIENT_RESERVE_SUCCESS`
export const UPDATE_CLIENT_RESERVE_FAILURE = `${prefix}/UPDATE_CLIENT_RESERVE_FAILURE`

export const updateClientReserve: CommonPromiseAction = (
  id: string,
  itemId: string,
  data: object,
) => ({
  type: [
    UPDATE_CLIENT_RESERVE_REQUEST,
    UPDATE_CLIENT_RESERVE_SUCCESS,
    UPDATE_CLIENT_RESERVE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.updateOtherReserve(id, itemId, data),
})

export const DELETE_CLIENT_RESERVE_REQUEST = `${prefix}/DELETE_CLIENT_RESERVE_REQUEST`
export const DELETE_CLIENT_RESERVE_SUCCESS = `${prefix}/DELETE_CLIENT_RESERVE_SUCCESS`
export const DELETE_CLIENT_RESERVE_FAILURE = `${prefix}/DELETE_CLIENT_RESERVE_FAILURE`

export const deleteClientReserve: CommonPromiseAction = (id: string, itemId: string) => ({
  type: [
    DELETE_CLIENT_RESERVE_REQUEST,
    DELETE_CLIENT_RESERVE_SUCCESS,
    DELETE_CLIENT_RESERVE_FAILURE,
  ],
  promise: (api: IApi) => api.clientInfo.deleteOtherReserve(id, itemId),
})

const SEND_SIGN_REMINDER = createRequestTypes(prefix, 'SEND_SIGN_REMINDER')

export const sendSignReminder: CommonPromiseAction = (id: string) => ({
  type: SEND_SIGN_REMINDER,
  promise: (api: IApi) => api.bbc.sendSignReminder(id),
})
