import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'

import styles from './EntitySettingsHeader.module.scss'

import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { IEntityInfo } from '@common/interfaces/entityInfo'
import Breadcrumbs from '../Common/Breadcrumbs'
import EntitySettingsTabs from './EntitySettingsTabs'
import { ROUTES } from '../../constants/routes'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'

interface IProps {
  entity: IEntityInfo
  getEntityInfo: (id: string) => void
}

const EntitySettingsHeader = ({ entity, getEntityInfo }: IProps) => {
  const { id } = useParams<{ id?: string }>()

  useLoadInfo(id, entity, getEntityInfo)
  useSetPageTitle('Entity setup')

  const breadcrumbs = useMemo(() => {
    return id
      ? [
          {
            link: ROUTES.ENTITY_MANAGEMENT,
            Icon: HomeIcon,
          },
          {
            title: entity?.name,
            link: generatePath(ROUTES.ENTITY_PAGE, { id }),
          },
          {
            title: 'Entity settings',
            link: generatePath(ROUTES.ENTITY_SETTINGS, { id }),
          },
        ]
      : [
          {
            link: ROUTES.CLIENT_MANAGEMENT,
            Icon: HomeIcon,
          },
          {
            title: 'Client setup',
            link: ROUTES.CLIENT_SETUP_CREATE,
          },
        ]
  }, [entity, id])

  return (
    <Grid item xs={12}>
      <Breadcrumbs breadcrumbs={breadcrumbs} />

      <div className={styles.tabs}>
        <EntitySettingsTabs />
      </div>
    </Grid>
  )
}

export default EntitySettingsHeader
