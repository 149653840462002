import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { generatePath } from 'react-router-dom'
import { matchPath, useHistory, useLocation, useParams } from 'react-router'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Skeleton from '@mui/material/Skeleton'
import sortBy from 'lodash/orderBy'
import styles from './ProspectHeader.module.scss'
import { ReactComponent as HomeIcon } from '@assets/images/home-icon.svg'
import { ClientInfoStatus } from '@common/interfaces/client'
import Button from '../Common/Button'
import { formatDate, formatPrice } from '../../helpers/helpers'
import { stringAvatar } from '../Notes/helpers/helpers'
import { ROUTES } from '../../constants/routes'
import { useSetPageTitle } from '../../hooks/useSetPageTitle'
import { BoxLink, MenuIcon, SalesforceLink } from '../Common/Icons'
import Breadcrumbs from '../Common/Breadcrumbs'
import {
  IOPSReporting,
  OPSReportingStatus,
  PROSPECT_SALESFORCE_ARCHIVE_REASONS,
  SalesforceTypes,
} from '@common/interfaces/prospects'
import { OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS } from '@common/interfaces/dueDiligence'
import { useLoadInfo } from '../../hooks/useLoadInfo'
import WarningModal from '../WarningModal'
import Tabs from '../Common/Tabs'
import Modal from '../Common/Modal'
import SelectField from '../Common/SelectField'
import TextField from '../Common/TextField'
import ArchivedChip from '../Client/ArchivedChip'
import CodatSyncChip from '../Client/CodatSyncChip'
import { ReactComponent as EditIcon } from '@assets/images/pencil-icon.svg'
import { UploadFile } from '../Common/UploadFile'
import { usePermissions } from '../../helpers/permissionContext'
import { Form } from 'react-final-form'
import FormField from '../Common/FormField'
import { makeValidate } from 'mui-rff'
import * as Yup from 'yup'
import { CircularProgress } from '@mui/material'

enum ProspectHeaderTabNames {
  Application = 'Application',
  AnalysisQueue = 'Analysis queue',
  ManageTerms = 'Manage terms',
  Summary = 'Summary',
}

const TABS_ROUTES = {
  [ProspectHeaderTabNames.Application]: ROUTES.PROSPECT_APPLICATION_PAGE,
  [ProspectHeaderTabNames.AnalysisQueue]: ROUTES.PROSPECT_ANALYSIS_QUEUE,
  [ProspectHeaderTabNames.ManageTerms]: ROUTES.PROSPECT_MANAGE_TERMS_PAGE,
  [ProspectHeaderTabNames.Summary]: ROUTES.PROSPECT_SUMMARY_PAGE,
}

const TABS = [
  ProspectHeaderTabNames.Application,
  ProspectHeaderTabNames.AnalysisQueue,
  ProspectHeaderTabNames.ManageTerms,
  ProspectHeaderTabNames.Summary,
]

enum Modals {
  UpdateStatus = 'updateStatus',
  ConvertToDiligence = 'convertToDiligence',
  ArchiveReason = 'archiveReason',
  EditProfilePic = 'editProfilePic',
  ImageManage = 'imageManage',
  CreateNewOPS = 'createNewOPS',
}

const NewOPSOptions = [
  { value: 'NewLead', label: 'New Lead' },
  { value: 'ExistingLead', label: 'Existing Lead' },
]

interface IProps {
  reporting: IOPSReporting
  show: (id: string) => void
  hide: () => void
  update: (id: string, data: object) => Promise<any>
  updateClient: (id: string, data: FormData) => void
  createNewOps: (id: string, data: object) => Promise<void>
}

const HeaderLoader = ({ isLoading, value }: { isLoading: boolean; value: string }) => {
  return isLoading ? <Skeleton width={120} /> : <>{value || '-'}</>
}

const newOpsValidator = makeValidate(
  Yup.object().shape({
    type: Yup.string().required('Required'),
    salesforceLink: Yup.string().when('type', {
      is: 'ExistingLead',
      then: Yup.string().required('Required'),
    }),
  }),
)

const ProspectHeader = ({ reporting, show, hide, update, updateClient, createNewOps }: IProps) => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { pathname } = useLocation()

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const [modalsShown, setModalsShown] = useState([])

  const [anchorEl, setAnchorEl] = useState(null)
  const isActionsMenuOpen = useMemo(() => Boolean(anchorEl), [anchorEl])
  const [archiveReason, setArchiveReason] = useState(null)
  const [reasonLost, setReasonLost] = useState(null)
  const [iconUrl, setIconUrl] = useState<string | null>(null)
  const [loadedFile, setLoadedFile] = useState<File[]>([])
  const [savedProfilePic, setSavedProfilePic] = useState<string | null>(null)

  const { isUW, isAdmin } = usePermissions()

  const readOnly = useMemo(
    () =>
      ![ClientInfoStatus.Prospect, ClientInfoStatus.Archived].includes(
        reporting?.clientInfo?.clientStatus,
      ) || isUW,
    [reporting, isUW],
  )

  const handleClickMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }, [])
  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null)
  }, [])

  useLoadInfo(id, reporting, show, hide, setIsLoading)
  useSetPageTitle(reporting?.clientName || '')

  const breadcrumbs = useMemo(
    () => [
      {
        link: isUW ? ROUTES.DUE_DILIGENCE_HOME : ROUTES.PROSPECTS,
        Icon: HomeIcon,
      },
      {
        link: generatePath(ROUTES.PROSPECT_PAGE, { id }),
        title: reporting?.clientName,
      },
    ],
    [reporting, id, isUW],
  )

  const initials = useMemo(() => {
    const name = reporting?.clientName || ''
    return name.split(' ').length > 1 ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}` : name[0]
  }, [reporting])

  const handleUpdateProspect = useCallback(
    async (data: any) => {
      setIsSaving(true)
      const result = await update(id, data)
      if (!result.error) {
        show(id)

        setModalsShown([])
      }
      setIsSaving(false)
    },
    [update, id, show],
  )

  const toggleConvertToDiligenceModalShown = useCallback(() => {
    setModalsShown([Modals.ConvertToDiligence])
    setAnchorEl(null)
  }, [])

  const handleConvertToDiligenceConfirm = useCallback(async () => {
    await handleUpdateProspect({
      status: ClientInfoStatus.DueDiligence,
    })

    if (isUW || isAdmin) {
      history.push(generatePath(ROUTES.DUE_DILIGENCE, { id: reporting?.clientInfo?.id }))
    }
  }, [handleUpdateProspect, isUW, isAdmin, history, reporting?.clientInfo?.id])

  const handleConvertToDiligenceCancel = useCallback(() => {
    setModalsShown([])
  }, [])

  const selectedTab = useMemo(() => {
    const currentTab = TABS.find(
      (tab) =>
        !!matchPath(history.location.pathname, {
          path: TABS_ROUTES[tab],
        })?.isExact,
    )

    return currentTab || TABS[0]
  }, [history.location.pathname])

  const handleChangeTab = useCallback(
    (selectedTab: string) => {
      history.push(generatePath(TABS_ROUTES[selectedTab], { id }))
    },
    [history, id],
  )

  const toggleArchiveReasonModalShown = useCallback(() => {
    setModalsShown((modalShowns) => [...modalShowns, Modals.ArchiveReason])
    handleCloseMenu()
  }, [handleCloseMenu, setModalsShown])

  const toggleArchiveReasonModalCancel = useCallback(() => {
    setArchiveReason(null)
    setModalsShown((modalShowns) => modalShowns.filter((modal) => modal !== Modals.ArchiveReason))
  }, [setModalsShown, setArchiveReason])

  const handleChangeArchiveReason = useCallback(
    ({ target: { value } }) => {
      setArchiveReason(value)
    },
    [setArchiveReason],
  )

  const toggleUpdateStatusModalShown = useCallback(() => {
    setModalsShown([Modals.UpdateStatus])
    setAnchorEl(null)
  }, [])

  const handleUpdateStatusCancel = useCallback(() => {
    setModalsShown([])
    toggleArchiveReasonModalCancel()
  }, [toggleArchiveReasonModalCancel])

  const handleChangeReasonLostText = useCallback(
    ({ target: { value } }) => {
      setReasonLost(value)
    },
    [setReasonLost],
  )

  useEffect(() => {
    setIconUrl(savedProfilePic || reporting?.clientInfo?.iconUrl)
  }, [savedProfilePic, reporting])

  const openEditProfilePicModal = useCallback(() => {
    setModalsShown([Modals.EditProfilePic])
  }, [])

  const closeEditProfilePicModal = useCallback(() => {
    setModalsShown([])
    setLoadedFile([])
    setIconUrl(savedProfilePic || reporting?.clientInfo?.iconUrl)
  }, [savedProfilePic, reporting?.clientInfo?.iconUrl])

  const handleSetIconUrl = useCallback((image) => {
    setIconUrl(image)
  }, [])

  const handleUpdateClientInfo = useCallback(
    async (file: any) => {
      if (reporting?.clientInfo?.id) {
        const formData = new FormData()
        formData.append('files[profilePicture]', file[0], file[0].name)
        await updateClient(reporting?.clientInfo?.id, formData)
        await show(id)
      }
    },
    [reporting?.clientInfo?.id, updateClient, show, id],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      setLoadedFile(loadedFiles)
      const [image] = loadedFiles

      if (image) {
        const reader = new FileReader()
        reader.onload = () => {
          handleSetIconUrl(reader.result as string)
        }
        reader.readAsDataURL(image)
      }
    },
    [handleSetIconUrl],
  )

  const handleSaveProfilePic = useCallback(async () => {
    setIsSaving(true)
    await handleUpdateClientInfo(loadedFile)
    setSavedProfilePic(iconUrl)
    setIconUrl(iconUrl)
    setModalsShown([])
    setLoadedFile([])
    setIsSaving(false)
  }, [handleUpdateClientInfo, loadedFile, iconUrl, setLoadedFile])

  const handleDeleteFile = useCallback(async () => {
    setLoadedFile([])
    handleSetIconUrl(null)
  }, [handleSetIconUrl])

  const handleToggleImageManageModal = useCallback(() => {
    setModalsShown((modalShowns) =>
      modalShowns.includes(Modals.ImageManage)
        ? modalShowns.filter((modal) => modal !== Modals.ImageManage)
        : [...modalShowns, Modals.ImageManage],
    )
  }, [])

  const handleUpdateStatusConfirm = useCallback(async () => {
    await handleUpdateProspect({
      status:
        reporting?.status === OPSReportingStatus.Archived
          ? ClientInfoStatus.Prospect
          : ClientInfoStatus.Archived,
      archiveReason,
      reasonLost,
    })
    setReasonLost(null)
    toggleArchiveReasonModalCancel()
  }, [handleUpdateProspect, reporting, toggleArchiveReasonModalCancel, archiveReason, reasonLost])

  const opsReportingVersions = useMemo(
    () =>
      sortBy(reporting?.clientInfo?.opsReportings, 'createdAt', 'desc').map((opsReporting) => ({
        value: opsReporting.id,
        label: opsReporting.salesforceProspectInfo?.name || opsReporting.clientName,
      })),
    [reporting],
  )

  const handleLaunchClientIntake = useCallback(() => {
    window.open(
      ROUTES.CLIENT_INTAKE.replace(
        ':salesforceId',
        reporting?.salesforceProspectInfo?.salesforceId,
      ),
      '_blank',
    )
    handleCloseMenu()
  }, [reporting, handleCloseMenu])

  const goToVersion = useCallback(
    ({ target: { value } }) => {
      history.push(pathname.replace(id, value))
    },
    [pathname, history, id],
  )
  const hasActiveOPSReporting = useMemo(
    () =>
      reporting?.clientInfo?.opsReportings?.filter(
        ({ status }) => status !== OPSReportingStatus.Archived,
      )?.length > 0,
    [reporting],
  )

  const isLead = useMemo(
    () => reporting?.salesforceProspectInfo?.salesforceType === SalesforceTypes.Lead,
    [reporting],
  )

  const handleConfirmCreateNewOPS = useCallback(
    async (data?: object) => {
      setIsSaving(true)
      await createNewOps(id, data)
      setIsSaving(false)
      setModalsShown([])
    },
    [createNewOps, id],
  )

  const handleCreateNewOPS = useCallback(async () => {
    if (isLead) {
      await handleConfirmCreateNewOPS()
    } else {
      setModalsShown([Modals.CreateNewOPS])
    }
  }, [isLead, handleConfirmCreateNewOPS])

  return (
    <>
      <Grid container item xs={12} mb={2}>
        <Grid item xs={6} justifyContent="flex-start" alignItems="center">
          <Breadcrumbs breadcrumbs={breadcrumbs} isLoading={isLoading} />
        </Grid>
        <Grid item container xs={6} justifyContent="flex-end" alignItems="center" gap={1.5}>
          <ArchivedChip
            clientInfo={reporting?.clientInfo}
            isArchived={reporting?.status === OPSReportingStatus.Archived}
          />
          <CodatSyncChip clientInfo={reporting?.clientInfo} />
          {opsReportingVersions.length > 1 && (
            <SelectField
              label="Version"
              variant="outlined"
              className={styles.selectField}
              useFinalForm={false}
              name="compareDate"
              options={opsReportingVersions}
              onChange={goToVersion}
              value={reporting?.id}
              defaultValue=""
              withTopLabel
            />
          )}
          {reporting?.salesforceProspectInfo?.opsBoxLink && (
            <div>
              <BoxLink link={reporting?.salesforceProspectInfo?.opsBoxLink} />
            </div>
          )}
          {reporting?.salesforceProspectInfo?.salesforceLink && (
            <div>
              <SalesforceLink link={reporting?.salesforceProspectInfo?.salesforceLink} />
            </div>
          )}
          {!readOnly && <MenuIcon isActive={isActionsMenuOpen} onClick={handleClickMenu} />}
          <Menu open={isActionsMenuOpen} anchorEl={anchorEl} onClose={handleCloseMenu}>
            <MenuItem disabled={hasActiveOPSReporting} onClick={handleCreateNewOPS}>
              {isSaving ? <CircularProgress size={20} /> : 'Create New OPS'}
            </MenuItem>
            <MenuItem
              disabled={reporting?.status === OPSReportingStatus.Archived && hasActiveOPSReporting}
              onClick={
                reporting?.status === OPSReportingStatus.Archived
                  ? toggleUpdateStatusModalShown
                  : toggleArchiveReasonModalShown
              }
            >
              {reporting?.status === OPSReportingStatus.Archived ? 'Restore' : 'Archive'}
            </MenuItem>
            <MenuItem
              disabled={!reporting?.salesforceProspectInfo?.salesforceId}
              onClick={handleLaunchClientIntake}
            >
              Prospect View
            </MenuItem>
            {reporting?.status !== OPSReportingStatus.Archived && (
              <MenuItem
                disabled={reporting?.clientInfo?.clientStatus !== ClientInfoStatus.Prospect}
                onClick={toggleConvertToDiligenceModalShown}
              >
                Convert to diligence
              </MenuItem>
            )}
          </Menu>
        </Grid>
      </Grid>
      <div className={styles.headerContainer}>
        <div className={styles.avatarGridContainer}>
          {isLoading ? (
            <Skeleton width={140} height={140} />
          ) : (
            reporting && (
              <div className={styles.avatar} {...stringAvatar(reporting.clientName)}>
                {savedProfilePic || reporting.clientInfo?.iconUrl ? (
                  <div className={styles.avatarContainer}>
                    <img
                      className={styles.avatarImg}
                      src={savedProfilePic || reporting.clientInfo?.iconUrl}
                      alt="logo"
                    />
                  </div>
                ) : (
                  <div className={styles.avatarText}>{initials}</div>
                )}
                <EditIcon className={styles.editIcon} onClick={openEditProfilePicModal} />
              </div>
            )
          )}
        </div>
        <div className={styles.contentContainer}>
          <Grid container spacing={1}>
            <Grid container item xs={12} justifyContent="flex-start" alignItems="flex-start">
              <Grid item xs={3}>
                <div className={styles.headerTitle}>Salesforce Stage</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={reporting?.salesforceProspectInfo?.salesforceStage}
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.headerTitle}>Commitment Amount</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.salesforceProspectInfo?.commitmentAmount
                        ? `$ ${formatPrice(reporting.salesforceProspectInfo.commitmentAmount)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.headerTitle}>Last Activity</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.salesforceProspectInfo?.lastActivityDate
                        ? formatDate(reporting.salesforceProspectInfo.lastActivityDate)
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.headerTitle}>BDO</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={reporting?.salesforceProspectInfo?.user?.firstName}
                  />
                </div>
              </Grid>
              <Grid item xs={2}>
                <div className={styles.headerTitle}>Risk Rating</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.clientInfo?.riskRating
                        ? reporting.clientInfo.riskRating.toFixed(2)
                        : '-'
                    }
                  />
                </div>
              </Grid>
            </Grid>
            <div className={styles.infoDivider}></div>

            <Grid container item xs={12} justifyContent="flex-start">
              <Grid item xs={3}>
                <div className={styles.headerTitle}>AR Availability</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.clientInfo?.availableAR
                        ? ` $ ${formatPrice(reporting.clientInfo.availableAR)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={3}>
                <div className={styles.headerTitle}>Inventory Availability</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.clientInfo?.availableInventory
                        ? ` $ ${formatPrice(reporting.clientInfo.availableInventory)}`
                        : '-'
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={styles.headerTitle}>Next Steps</div>
                <div className={styles.headerValue}>
                  <HeaderLoader
                    isLoading={isLoading}
                    value={
                      reporting?.salesforceProspectInfo?.nextSteps
                        ? reporting.salesforceProspectInfo.nextSteps
                        : '-'
                    }
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
      <Grid item xs={12} mb={1.5} pl={3.75}>
        <Tabs tabs={TABS} selected={selectedTab} handleChange={handleChangeTab} />
      </Grid>

      {modalsShown.includes(Modals.UpdateStatus) && (
        <WarningModal
          onCancel={handleUpdateStatusCancel}
          warningMessage={`Are you sure you want to ${
            reporting?.status === OPSReportingStatus.Archived ? 'restore' : 'archive'
          } this client?`}
          onConfirm={handleUpdateStatusConfirm}
          isLoading={isSaving}
        />
      )}

      {modalsShown.includes(Modals.ConvertToDiligence) && (
        <WarningModal
          onCancel={handleConvertToDiligenceCancel}
          warningMessage={`Are you sure you want to convert this client to diligence?`}
          onConfirm={handleConvertToDiligenceConfirm}
          isLoading={isSaving}
        />
      )}

      {modalsShown.includes(Modals.EditProfilePic) && (
        <Modal
          open
          onCancel={closeEditProfilePicModal}
          classes={{ root: styles.modalRoot, title: styles.modalTitle, footer: styles.modalFooter }}
          title="Upload Image"
          size="small"
          footer={[
            <Button
              color="primary"
              variant="contained"
              small={false}
              onClick={handleSaveProfilePic}
              isLoading={isSaving}
              key="submit"
              className={styles.modalFooter}
              disabled={!loadedFile.length}
            >
              Update
            </Button>,
          ]}
        >
          {iconUrl && (
            <Box className={styles.avatarWrapper}>
              <img className={styles.avatarImg} src={iconUrl} alt="logo" />
            </Box>
          )}
          <Box className={styles.imageUploadWrapper}>
            <UploadFile
              title="Profile Picture"
              size="lg"
              onDropAccepted={handleUploadFile}
              files={loadedFile}
              onDelete={handleDeleteFile}
              acceptedFileTypes={['image']}
              maxFiles={1}
              isModalOpen={modalsShown.includes(Modals.ImageManage)}
              handleToggleModal={handleToggleImageManageModal}
            />
          </Box>
        </Modal>
      )}

      {modalsShown.includes(Modals.ArchiveReason) && (
        <Modal
          open
          onCancel={toggleArchiveReasonModalCancel}
          classes={{ root: styles.modalRoot, title: styles.modalTitle }}
          title="Archive prospect"
          footer={[
            <Button
              key="cancel"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleArchiveReasonModalCancel}
              secondary
            >
              Cancel
            </Button>,
            <Button
              isLoading={isSaving}
              key="submit"
              color="primary"
              variant="contained"
              small={false}
              onClick={toggleUpdateStatusModalShown}
              disabled={!archiveReason || !reasonLost}
            >
              Continue
            </Button>,
          ]}
        >
          <Box className={styles.fieldsContainer}>
            <SelectField
              name="archiveReason"
              useFinalForm={false}
              options={
                reporting?.salesforceProspectInfo?.salesforceType === SalesforceTypes.Opportunity
                  ? OPPORTUNITY_SALESFORCE_ARCHIVE_REASONS
                  : PROSPECT_SALESFORCE_ARCHIVE_REASONS
              }
              onChange={handleChangeArchiveReason}
              value={archiveReason}
              disabled={readOnly}
              selectSize="large"
              border
              placeholder="Select a reason"
            />
            <TextField
              name="reasonLost"
              className={styles.textFormField}
              useFinalForm={false}
              onChange={handleChangeReasonLostText}
              value={reasonLost}
              disabled={readOnly}
              multiline
              rows={3}
              placeholder="Please explain why you are archiving this prospect"
            />
          </Box>
        </Modal>
      )}
      {modalsShown.includes(Modals.CreateNewOPS) && (
        <Form
          onSubmit={handleConfirmCreateNewOPS}
          validate={newOpsValidator}
          initialValues={{
            type: '',
            salesforceLink: '',
          }}
          render={({ handleSubmit, values }) => (
            <Modal
              open
              onCancel={() => setModalsShown([])}
              classes={{ root: styles.modalRoot, title: styles.modalTitle }}
              title="Create new OPS"
              footer={[
                <Button
                  key="cancel"
                  color="primary"
                  variant="contained"
                  small={false}
                  onClick={() => setModalsShown([])}
                  secondary
                >
                  Cancel
                </Button>,
                <Button
                  key="submit"
                  color="primary"
                  variant="contained"
                  small={false}
                  onClick={handleSubmit}
                  isLoading={isSaving}
                >
                  Continue
                </Button>,
              ]}
            >
              <Box className={styles.fieldsContainer}>
                <FormField
                  label="Salesforce Integration"
                  name="type"
                  type="select"
                  options={NewOPSOptions}
                  placeholder="Salesforce Intergration"
                />
                {'type' in values && values.type === 'ExistingLead' && (
                  <FormField
                    label="Link"
                    name="salesforceLink"
                    type="text"
                    placeholder="Salesforce Link"
                  />
                )}
              </Box>
            </Modal>
          )}
        />
      )}
    </>
  )
}

export default ProspectHeader
