import { Action } from 'redux'
import { currentWorkFlow } from '../../helpers/helpers'
import { takeEvery, select, put } from 'redux-saga/effects'
import {
  CALCULATE_BBC_FLAGS_SUCCESS,
  CALCULATE_ENTITY_FLAGS_SUCCESS,
  CALCULATE_OPS_FLAGS_SUCCESS,
  CALCULATE_ONGOING_REPORTING_FLAGS_SUCCESS,
  getNewFlagsCount,
} from './actions'
import { IResponse } from '@common/interfaces/request'
import { pathname } from '../router/selectors'
import { matchPath } from 'react-router'
import { ROUTES } from '../../constants/routes'

export function* repullNewFlagCount() {
  yield takeEvery(
    [
      CALCULATE_BBC_FLAGS_SUCCESS,
      CALCULATE_ENTITY_FLAGS_SUCCESS,
      CALCULATE_OPS_FLAGS_SUCCESS,
      CALCULATE_ONGOING_REPORTING_FLAGS_SUCCESS,
    ],
    function* (data: IResponse & Action) {
      const path: string = yield select(pathname)
      const bbcPath = matchPath<{ id: string }>(path, { path: ROUTES.BBC })
      const entityPath = matchPath<{ id: string }>(path, { path: ROUTES.ENTITY_PAGE })
      const opsPath = matchPath<{ id: string }>(path, { path: ROUTES.PROSPECT_PAGE })
      const ongoingPath = matchPath<{ id: string }>(path, { path: ROUTES.ONGOING_REPORTING })

      const matchedPath = bbcPath || entityPath || opsPath || ongoingPath

      if (!matchedPath) {
        return
      }
      const {
        params: { id: matchedId },
      } = matchedPath

      const workflow = currentWorkFlow(path)
      const requestId = data.data.id

      if (matchedId === requestId) {
        yield put(
          getNewFlagsCount({
            id: requestId,
            workflow,
          }),
        )
      }
    },
  )
}
