import React, { useState, useCallback, useMemo, useEffect, Dispatch, SetStateAction } from 'react'
import { useParams } from 'react-router'
import Grid from '@mui/material/Grid'
import Tooltip from '@mui/material/Tooltip'

import styles from './BBCFileSelect.module.scss'

import Modal from '../../components/Common/Modal'
import { IFile } from '@common/interfaces/box'
import Button from '../../components/Common/Button'
import FileSelect, { MultipleFileSelect } from '../FileSelect'
import UploadFile from '../Common/UploadFile'
import {
  BBC_TAB_FILE_TYPE_MAPPING,
  BBC_TABS,
  IBorrowingBase,
  WorkflowPage,
} from '@common/interfaces/bbc'
import { ReactComponent as UploadFileIcon } from '../../assets/images/upload-file.svg'
import { ReactComponent as BackIcon } from '../../assets/images/back-icon.svg'
import SaveState from '../Common/SaveState'
import SelectedFiles from '../SelectedFiles'
import FileHeaderMapping from '../FileHeaderMapping'
import classNames from 'classnames'

interface IProps {
  isOpen: boolean
  isLoading: boolean
  bbc: IBorrowingBase
  getBBCFiles: (id: string) => Promise<any>
  uploadBBCFiles: (id: string, data: FormData) => Promise<any>
  submitBBCFiles: (id: string, data: object) => Promise<any>
  downloadTemplate: (fileType?: string, fileId?: string) => void
  deleteFile: (ids: string[]) => Promise<void>
  tab: BBC_TABS
  handleOpenModal?: () => void
  handleRecalculate?: (isUpdated?: boolean, closeModal?: boolean) => void
  setIsFilesSaving?: Dispatch<SetStateAction<boolean>>
}

const BBCFileSelect = ({
  isOpen,
  isLoading,
  bbc,
  getBBCFiles,
  uploadBBCFiles,
  submitBBCFiles,
  downloadTemplate,
  deleteFile,
  tab,
  handleOpenModal,
  handleRecalculate,
  setIsFilesSaving,
}: IProps) => {
  const { id } = useParams<{ id: string }>()

  const [isFileUpdated, setIsFileUpdated] = useState(false)
  const [isFilesLoading, setIsFilesLoading] = useState(false)
  const [isFilesSaved, setIsFilesSaved] = useState(false)
  const [isDownloading, setIsDownloading] = useState(false)
  const [filesForDelete, setFilesForDelete] = useState<IFile[]>([])
  const [attempt, setAttempt] = useState(0)
  const [isDeleteModalShown, setIsDeleteModalShown] = useState(false)
  const [uploadDocumentOpen, setUploadDocumentOpen] = useState(false)
  const [selectedHeaderMapping, setSelectedHeaderMapping] = useState('')
  const [fileReSelecting, setFileReSelecting] = useState({
    id: '',
    sheetName: '',
  })

  const isMultipleFiles = useMemo(() => tab !== BBC_TABS.INVENTORY, [tab])
  const fileSheets = useMemo(() => {
    if (!bbc?.fileSheets?.length) {
      return []
    }
    const fileSheets = bbc.fileSheets.filter(({ type }) => type === BBC_TAB_FILE_TYPE_MAPPING[tab])

    return isMultipleFiles ? fileSheets : fileSheets.slice(0, 1)
  }, [bbc, tab, isMultipleFiles])

  const files = useMemo(() => {
    if (!bbc?.files?.length) {
      return []
    }
    return bbc.files
      .filter(({ type }) => type === BBC_TAB_FILE_TYPE_MAPPING[tab])
      .map((file) => ({
        ...file,
        sheetName: fileSheets.find(({ fileId }) => file.id === fileId)?.sheetName || null,
      }))
  }, [bbc, tab, fileSheets])

  const modalTitle = useMemo(
    () =>
      uploadDocumentOpen ? 'Upload file' : selectedHeaderMapping ? 'Column Mapping' : 'Select file',

    [uploadDocumentOpen, selectedHeaderMapping],
  )

  const fileTypeTemplates = useMemo(
    () => ({
      [BBC_TABS.RECEIVABLES]: [
        {
          name: 'AR Detail',
          type: 'arRaw',
        },
      ],
      [BBC_TABS.PAYABLES]: [
        {
          name: 'AP Detail',
          type: 'apRaw',
        },
        {
          name: 'AP Summary',
          type: 'apRawSummary',
        },
      ],
      [BBC_TABS.INVENTORY]: bbc?.inventoryConverterTemplateId
        ? [
            {
              name: 'Inventory Converter',
              type: null,
              fileId: bbc.inventoryConverterTemplateId,
            },
          ]
        : [
            {
              name: 'Inventory Detail',
              type: 'inventory',
            },
          ],
    }),
    [bbc],
  )

  const selectedDocuments = useMemo(
    () =>
      fileSheets.map(({ fileId, sheetName, data }) => {
        const file = files?.find(({ id }) => id === fileId) || ({} as IFile)

        return {
          ...file,
          sheetName,
          error: isMultipleFiles ? data.error : file.error,
        }
      }) as IFile[],
    [fileSheets, files, isMultipleFiles],
  )

  useEffect(() => {
    if (files.length && !fileSheets.length && attempt === 0) {
      handleOpenModal && handleOpenModal()
      setAttempt((attempt) => attempt + 1)
    }
  }, [bbc, files, handleOpenModal, attempt, fileSheets])

  useEffect(() => {
    if (bbc?.files && files?.length === 0) {
      setUploadDocumentOpen(true)
    }
  }, [bbc, files])

  const handleOpenHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping(BBC_TAB_FILE_TYPE_MAPPING[tab])
  }, [tab])

  const handleCloseHeaderMapping = useCallback(() => {
    setSelectedHeaderMapping('')
  }, [])

  const handleSelectFile = useCallback(
    async (fileId: string, sheetName: string, isReselect?: boolean) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setAttempt((attempt) => attempt + 1)
      setIsFilesLoading(true)
      setIsFilesSaved(false)
      const result = await submitBBCFiles(id, {
        type: BBC_TAB_FILE_TYPE_MAPPING[tab],
        fileSheets:
          fileId && sheetName
            ? [
                {
                  fileId,
                  sheetName,
                },
              ]
            : [],
        isReselect,
      })
      !result?.error && setIsFilesSaved(true)
      setIsFilesLoading(false)
      if (result?.data?.success) {
        sheetName && handleRecalculate && handleRecalculate(true)
        setIsFileUpdated(false)
        handleCloseHeaderMapping()
      } else {
        await getBBCFiles(id)
        setIsFileUpdated(false)
        handleRecalculate && handleRecalculate(true, false)
        if (result?.data?.error?.code === 'MISSING_COLUMNS') {
          handleCloseHeaderMapping()
          handleOpenHeaderMapping()
        }
      }
      setIsFilesSaving && setIsFilesSaving(false)
      !sheetName && handleCloseHeaderMapping()
    },
    [
      submitBBCFiles,
      getBBCFiles,
      id,
      tab,
      handleRecalculate,
      setIsFilesSaving,
      handleOpenHeaderMapping,
      handleCloseHeaderMapping,
    ],
  )

  const handleSelectFiles = useCallback(
    async (fileSheets: { fileId: string; sheetName: string }[], isReselect: boolean = false) => {
      setIsFilesSaving && setIsFilesSaving(true)
      setAttempt((attempt) => attempt + 1)
      setIsFilesLoading(true)
      setIsFilesSaved(false)
      if (isReselect && fileSheets.length) {
        setFileReSelecting({ id: fileSheets[0].fileId, sheetName: fileSheets[0].sheetName })
      }
      const result = await submitBBCFiles(id, {
        type: BBC_TAB_FILE_TYPE_MAPPING[tab],
        fileSheets,
      })
      setFileReSelecting({ id: '', sheetName: '' })
      !result?.error && setIsFilesSaved(true)
      setIsFilesLoading(false)
      if (result?.data?.success) {
        fileSheets.length > 0 && handleRecalculate && handleRecalculate(true)
        setIsFileUpdated(false)
        handleCloseHeaderMapping()
      } else {
        await getBBCFiles(id)
        setIsFileUpdated(false)
        handleRecalculate && handleRecalculate(true, false)
        if (result?.data?.error?.code === 'MISSING_COLUMNS') {
          handleCloseHeaderMapping()
          handleOpenHeaderMapping()
        }
      }
      setIsFilesSaving && setIsFilesSaving(false)
      !fileSheets.length && handleCloseHeaderMapping()
    },
    [
      submitBBCFiles,
      getBBCFiles,
      id,
      tab,
      handleRecalculate,
      setIsFilesSaving,
      handleOpenHeaderMapping,
      handleCloseHeaderMapping,
    ],
  )

  const handleUploadFile = useCallback(
    async (loadedFiles: File[]) => {
      if (loadedFiles?.length) {
        const formData = new FormData()

        formData.append('type', BBC_TAB_FILE_TYPE_MAPPING[tab])
        loadedFiles.forEach((file) => {
          formData.append('files[]', file, file.name)
        })

        setIsFilesLoading(true)
        setIsFilesSaved(false)
        const result = await uploadBBCFiles(id, formData)
        !result?.error && (await getBBCFiles(id))
        !result?.error && setIsFilesSaved(true)
        setIsFilesLoading(false)
      }
    },
    [uploadBBCFiles, getBBCFiles, id, tab],
  )

  const handleDeleteFiles = useCallback(
    (indexes: number[]) => {
      setIsDeleteModalShown(true)
      setFilesForDelete(
        indexes
          .sort((a, b) => b - a)
          .map((number, index) => files[number === undefined ? index : number]),
      )
    },
    [files],
  )

  const handleDeleteCancel = useCallback(() => {
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [])

  const handleDeleteConfirm = useCallback(async () => {
    setIsFilesLoading(true)
    const filesForDeleteIds = filesForDelete.map(({ id }) => id)
    if (fileSheets.filter(({ fileId }) => filesForDeleteIds.includes(fileId))) {
      await submitBBCFiles(id, {
        type: BBC_TAB_FILE_TYPE_MAPPING[tab],
        fileSheets: fileSheets.filter(({ fileId }) => !filesForDeleteIds.includes(fileId)),
      })
      setIsFileUpdated(true)
    }
    await deleteFile(filesForDeleteIds)
    await getBBCFiles(id)
    setIsFilesLoading(false)
    setIsDeleteModalShown(false)
    setFilesForDelete([])
  }, [filesForDelete, deleteFile, getBBCFiles, id, fileSheets, submitBBCFiles, tab])

  const handleToggleSetUploadDocumentOpen = useCallback(
    () => setUploadDocumentOpen((open) => !open),
    [],
  )

  const handleCancel = useCallback(() => {
    if (!isFilesLoading) {
      handleRecalculate(isFileUpdated)
      setIsFileUpdated(false)
    }
  }, [handleRecalculate, isFileUpdated, isFilesLoading])

  const handleDownloadTemplate = useCallback(
    async (fileType?: string, fileId?: string) => {
      setIsDownloading(true)
      await downloadTemplate(fileType, fileId)
      setIsDownloading(false)
    },
    [downloadTemplate],
  )

  const handleReselectFile = useCallback(async () => {
    const currentFile = fileSheets[0]
    if (!currentFile) {
      return
    }
    setFileReSelecting({ id: currentFile.fileId, sheetName: currentFile.sheetName })
    await handleSelectFile(currentFile.fileId, currentFile.sheetName, true)
    setFileReSelecting({ id: '', sheetName: '' })
  }, [handleSelectFile, fileSheets])

  return (
    <Modal
      title={
        <Grid container justifyContent="flex-start" alignItems="center">
          <Grid item display={'flex'}>
            {uploadDocumentOpen && (
              <BackIcon className={styles.backButton} onClick={handleToggleSetUploadDocumentOpen} />
            )}
            {selectedHeaderMapping && (
              <BackIcon className={styles.backButton} onClick={handleCloseHeaderMapping} />
            )}
          </Grid>
          <Grid item>
            <h2 className={styles.modalTitle}>{modalTitle}</h2>
          </Grid>
        </Grid>
      }
      open={isOpen}
      classes={{
        root: classNames(styles.modalPaper, {
          [styles.columnMappingOpen]: selectedHeaderMapping === BBC_TAB_FILE_TYPE_MAPPING[tab],
        }),
      }}
      onCancel={handleCancel}
    >
      <Grid container spacing={1}>
        {!uploadDocumentOpen ? (
          <Grid container item xs={12}>
            <SelectedFiles
              selectedDocuments={selectedDocuments}
              handleSelectFile={handleSelectFile}
              handleSelectMultipleFiles={isMultipleFiles && handleSelectFiles}
              fileReselecting={fileReSelecting}
              isMultipleReSelecting={isMultipleFiles && !!fileReSelecting.id}
            />
            {tab === BBC_TABS.INVENTORY && (
              <Grid item xs={12}>
                {!selectedHeaderMapping && fileSheets.length > 0 && (
                  <h3 onClick={handleOpenHeaderMapping} className={styles.modalLink}>
                    Column Mapping
                  </h3>
                )}
                {selectedHeaderMapping === BBC_TAB_FILE_TYPE_MAPPING[tab] && (
                  <FileHeaderMapping
                    id={id}
                    workflow={WorkflowPage.borrowingbase}
                    fileType={selectedHeaderMapping}
                    onClose={handleCloseHeaderMapping}
                    inModal={false}
                    onSave={handleReselectFile}
                  />
                )}
              </Grid>
            )}
            {!selectedHeaderMapping && (
              <>
                <Grid item xs={12}>
                  <h3 className={styles.filesListItemTitle}>{tab}</h3>
                </Grid>
                <Grid item xs={12}>
                  <div className={styles.filesList}>
                    <div className={styles.filesListItem}>
                      {isMultipleFiles ? (
                        <MultipleFileSelect
                          updateFiles={handleSelectFiles}
                          files={files}
                          className={styles.filesListSelect}
                          selectedDocuments={fileSheets}
                        />
                      ) : (
                        <FileSelect
                          updateFile={handleSelectFile}
                          files={files}
                          className={styles.filesListSelect}
                          isAutoSelectable={false}
                        />
                      )}
                    </div>
                  </div>
                </Grid>
                <Grid container mt={2} item xs={12}>
                  <Grid container item xs={8} justifyContent="flex-start">
                    <span className={styles.uploadDocumentHelper}>
                      Want to use another document?
                    </span>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={4}
                    justifyContent="flex-end"
                    alignItems="center"
                    className={styles.uploadTextContainer}
                    onClick={handleToggleSetUploadDocumentOpen}
                  >
                    <UploadFileIcon />
                    <span className={styles.uploadText}>Upload another</span>
                  </Grid>
                </Grid>
                <Grid container item xs={12} justifyContent="flex-end">
                  <SaveState
                    isSaving={!isDeleteModalShown && isFilesLoading}
                    isSaved={isFilesSaved}
                  />
                </Grid>
              </>
            )}
          </Grid>
        ) : (
          <Grid container item xs={12} lg={12}>
            <Grid container item xs={12} lg={12} justifyContent="center">
              <UploadFile
                title=""
                key={+isLoading}
                onDelete={handleDeleteFiles}
                size="lg"
                onDropAccepted={handleUploadFile}
                acceptedFileTypes={['excel']}
                files={files}
                isInModal={false}
                isLoading={!isDeleteModalShown && isFilesLoading}
              />
            </Grid>
            <Grid container item xs={12} lg={12} justifyContent="space-between">
              <div className={styles.filesListItem}>
                <div className={styles.filesListItemDocuments}>
                  {fileTypeTemplates[tab] &&
                    fileTypeTemplates[tab].map(
                      (
                        item: { name: string; type: string | null; fileId?: string },
                        index: number,
                      ) => (
                        <React.Fragment key={item.type}>
                          <Tooltip placement="top" title="Download Template">
                            <Button
                              isLoading={isDownloading}
                              className={styles.downloadButton}
                              color="primary"
                              variant="outlined"
                              onClick={() => {
                                handleDownloadTemplate(item.type, item.fileId)
                              }}
                            >
                              {item.name}
                            </Button>
                          </Tooltip>
                          {index !== fileTypeTemplates[tab]?.length - 1 && (
                            <div className={styles.templatesDivider} />
                          )}
                        </React.Fragment>
                      ),
                    )}
                </div>
              </div>
            </Grid>
          </Grid>
        )}
        {isDeleteModalShown && filesForDelete.length > 0 && (
          <Modal
            open={isDeleteModalShown}
            onCancel={handleDeleteCancel}
            title="Delete file?"
            footer={[
              <Button
                key="cancel"
                color="primary"
                variant="outlined"
                onClick={handleDeleteCancel}
                disabled={isFilesLoading}
              >
                Cancel
              </Button>,
              <Button
                isLoading={isFilesLoading}
                key="submit"
                color="primary"
                variant="contained"
                onClick={handleDeleteConfirm}
              >
                Delete
              </Button>,
            ]}
          >
            All related records will be deleted
          </Modal>
        )}
      </Grid>
    </Modal>
  )
}

export default BBCFileSelect
